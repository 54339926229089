import { CallApi } from '../api'
import { LoginRequestEntity } from '../../interfaces/login'
import { setToken, setCurrentUser } from '../../utils/helper/common'

export const signIn = async (dataLogin: LoginRequestEntity, callback: (isSucces: boolean, message?: string) => void): Promise<void> => {
  try {
    const { data } = await CallApi({ endpoint: 'auth/signin', method: 'POST', body: dataLogin })
    if (data.currentUserData.email === process.env.REACT_APP_ACCOUNT_ADMIN) {
      setToken(data.accessToken)
      setCurrentUser(data.currentUserData)
      callback(true)
    } else {
      callback(false, "Invalid account. Try again!")
    }
  } catch (err) {
    callback(false, err.message)
  }
}

export const signOut = async (): Promise<void> => {
  try {
    CallApi({ endpoint: 'user/logout' })
  } catch (err) {
    console.log(err.message)
  }
}