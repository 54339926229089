// @ts-nocheck
import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react'
import { Grid, Button, Dialog } from '@material-ui/core'
import { CustomTextField } from '../../elements/CustomTextField'
import { CustomLoading } from '../../elements/CustomLoading'
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { uploadImage } from '../../../services/rest/template.services'
import 'antd/dist/antd.css';
import '../UpdateTemplateModal/index.scss'
import { toast, ToastContainer } from 'react-toastify'
import { Review } from '../../../interfaces/review'
import { v4 as uuid } from 'uuid';
import './index.scss'
import { UserData } from '../../../interfaces/user'
import axios from 'axios'
import { languages } from 'countries-list'
import { SearchableSelect } from '../../elements/SearchableSelect';

interface UpdateReviewModalProps {
  handleCloseModal: () => void
  isOpen: boolean
  isLoading: boolean
  isCreated: boolean
  reviewDetail: Review
  handleSave: (data) => void
  listUsers: UserData[]
}

export const UpdateReviewModal: React.FC<UpdateReviewModalProps> = (props: UpdateReviewModalProps): ReactElement => {
  const { isOpen, isLoading, handleCloseModal, isCreated, handleSave, reviewDetail, listUsers } = props
  const [state, setState] = useState({
    loading: false,
    isOpenPreview: false,
    previewImage: '',
    random: 0
  })
  const [reviewData, setReviewData] = useState({
    username: '',
    note: '',
    language: ''
  })
  const [fileAvatar, setFileAvatar] = useState([])
  const [fileImage, setFileImage] = useState([])
  const [languageOptions, setLanguageOptions] = useState([])

  const resetData = () => {
    setState({
      loading: false,
      isOpenPreview: false,
      previewImage: '',
      random: 0
    })
    setReviewData({
      username: '',
      note: '',
      language: ''
    })
    setFileAvatar([])
    setFileImage([])
  }

  useEffect(() => {
    setLanguageOptions(Object.keys(languages).map(item => {
      return {
        value: item.toLocaleUpperCase(),
        content: `${item.toLocaleUpperCase()} - ${languages[item].name}`
      }
    }))
  }, [])

  useEffect(() => {
    if (!isCreated && Object.keys(reviewDetail).length > 0) {
      const user = listUsers.filter(user => user.id === reviewDetail.userId)[0]
      if (reviewDetail.avatar) {
        const fileImage = {
          uid: uuid(),
          url: reviewDetail.avatar || user.avatar
        }
        setFileAvatar([fileImage])
      }
      if (reviewDetail.image) {
        const fileImage = {
          uid: uuid(),
          url: reviewDetail.image
        }
        setFileImage([fileImage])
      }

      setReviewData({
        username: reviewDetail.username || user.username,
        note: reviewDetail.note,
        language: reviewDetail.language || '',
      })
    } else {
      resetData()
      loadDataUser()
    }
  }, [isCreated, reviewDetail])

  const loadDataUser = async () => {
    handleChangeState('loading', true);
    let urlImage = ''
    const items = ['https://randomuser.me/api', 'https://dog.ceo/api/breeds/image/random', 'https://api.thecatapi.com/v1/images/search']
    const index = Math.floor(Math.random() * items.length)
    var url = items[index];
    const fakeUser = axios.get('https://fakerapi.it/api/v1/users?_quantity=1');
    const imageApi = axios.get(url);
    const rs = await Promise.all([fakeUser, imageApi])
    const user = rs[0].data.data[0]
    const imageData = rs[1].data
    switch (index) {
      case 0:
        urlImage = imageData.results[0].picture.medium
        break;
      case 1:
        urlImage = imageData.message
        break;
      case 2:
        urlImage = imageData[0].url
        break;
      default:
        break;
    }
    setFileAvatar([
      {
        uid: uuid(),
        url: urlImage
      }
    ])
    handleChangeState('loading', false);
    handleChangeReviewData('username', `${user.lastname} ${user.firstname}`)
  }

  const handleChangeListProducts = (file) => {
    setFileImage(file.fileList)
  };

  const handlePreview = async file => {
    setState(prevData => ({
      ...prevData,
      isOpenPreview: true,
      previewImage: file.url
    }))
  };

  const callbackChangeAvatar = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setFileAvatar([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const callbackChangeImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setFileImage([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const handleChangeReviewData = (key: string, value: string): void => {
    setReviewData(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const handleSaveModal = (): void => {
    let data = {
      ...reviewData,
      avatar: fileAvatar[0]?.url || '',
      image: fileImage[0]?.url || '',
    }
    if (!isCreated) {
      if (reviewDetail.userId) {
        data = {
          ...reviewDetail,
          ...reviewData,
        }
      } else {
        data = {
          ...reviewDetail,
          ...reviewData,
          avatar: fileAvatar[0]?.url || '',
          image: fileImage[0]?.url || '',
        }
      }
    }

    handleSave(data)
    resetData()
    handleCloseModal()
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderContent = (): ReactElement => {
    return (
      <div className='header-tabs' style={{ padding: 30 }}>
        <Grid container item lg={12} spacing={0} direction="column">
          <Grid item lg={12} className="header mb-50">
            <h4 className="title" style={{ textAlign: 'center' }}>Review</h4>
          </Grid>
          <Button
            variant="contained"
            className="button mb-10"
            style={{
              margin: 'auto'
            }}
            color="primary"
            onClick={() => loadDataUser()}
          >
            <p className="font-roboto text-white">
              Change user
          </p>
          </Button>
          <div className="content">
            <Grid container className="row" spacing={4}>
              <Grid item lg={6} md={6} sm={6} xs={6} className="row">
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  <CustomTextField
                    label="User name"
                    value={reviewData.username}
                    onChanged={handleChangeReviewData}
                    fieldName="username"
                    className="text-field mb-50 "
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  <CustomTextField
                    label="Review"
                    value={reviewData.note}
                    onChanged={handleChangeReviewData}
                    fieldName="note"
                    helperText="Invalid email!"
                    className="text-field mb-50 "
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  <SearchableSelect
                    className="filter-select"
                    disableClearable={!reviewData.language}
                    label={"Language"}
                    fieldName={'language'}
                    value={reviewData.language || ''}
                    options={languageOptions}
                    onChange={handleChangeReviewData}
                  />
                </Grid>
              </Grid>
              <Grid container item lg={6} md={6} sm={6} xs={6} className="row">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <h4 className="Original image">Avatar</h4>
                  <Upload
                    key="original"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileAvatar}
                    beforeUpload={(data) => {
                      handleChangeState('loading', true)
                      uploadImage(data, callbackChangeAvatar)
                    }}
                    onPreview={handlePreview}
                    onChange={(file) => setFileAvatar(file.fileList)}
                  >
                    {uploadButton}
                  </Upload>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <h4 className="Original image">Image</h4>
                  <Upload
                    key="productions"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileImage}
                    beforeUpload={(data) => {
                      handleChangeState('loading', true)
                      uploadImage(data, callbackChangeImage)
                    }}
                    onPreview={handlePreview}
                    onChange={handleChangeListProducts}
                  >
                    {uploadButton}
                  </Upload>
                  <CustomTextField
                    label="Image"
                    value={fileImage[0]?.url || ''}
                    onChanged={(key, value) => setFileImage([{
                      uid: uuid(),
                      url: value
                    }])}
                    fieldName="fileImage"
                    helperText="Invalid email!"
                    className="text-field mt-30 "
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2} className="footer">
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Button variant="outlined" className="button" color="secondary" onClick={() => {
                resetData()
                handleCloseModal()
              }}>
                <p className="text-pink text-transform-normal">
                  Back
              </p>
              </Button>
            </Grid>
            <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  className="button"
                  color="primary"
                  onClick={() => handleSaveModal()}
                >
                  <p className="text-white text-transform-normal">
                    Save
                </p>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleCloseModal}
      className="update-review-modal"
    >
      {state.loading && <CustomLoading className="loading-modal" />}
      {renderContent()}
      <Modal
        visible={state.isOpenPreview}
        footer={null}
        onCancel={() => handleChangeState('isOpenPreview', false)}
      >
        <img alt="example" style={{ width: '100%' }} src={state.previewImage} />
      </Modal>
      <ToastContainer />
    </Dialog>
  )
}
