import { createSlice } from '@reduxjs/toolkit'
import { Template } from '../../interfaces/template'

export interface TemplateInitialState {
  loading: boolean
  templates: Template[]
  totalPages: number,
  page: number,
  error: Error
}

const initialState: TemplateInitialState = {
  loading: false,
  templates: [] as Template[],
  totalPages: 0,
  page: 0,
  error: {} as Error,
}

const TemplateSlice = createSlice({
  name: 'triggerTemplate',
  initialState,
  reducers: {
    startGetTemplates: (state) => {
      state.loading = true
      state.templates = []
      state.totalPages = 0
      state.page = 0
      state.error = {} as Error
    },
    successGetTemplates: (state, { payload }) => {
      state.loading = false
      if (payload.data.templates) {
        state.templates = payload.data.templates
        state.page = payload.data.page
        state.totalPages = payload.data.totalPages
      } else {
        state.templates = payload.data
      }
    },
    failRequestApiFromTemplateSlice: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clearErrorTemplate: (state) => {
      state.error = {} as Error
    }
  },
})

const { actions, reducer } = TemplateSlice
export const { startGetTemplates, successGetTemplates, failRequestApiFromTemplateSlice, clearErrorTemplate } = actions

export default reducer
