import React, { ReactElement, useState } from 'react'
import { ToolbarLandingPage } from '../../components/resources/ToolbarLandingPage'
import { DrawerLandingPage } from '../../components/resources/DrawerLandingPage'
import { useStyles } from '../../components/resources/ToolbarLandingPage/styles'
import { Images } from '../../constants/images'
import { Button, Grid, InputAdornment } from '@material-ui/core'
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx'
import { CustomMUITextField } from '../../components/elements/CustomMUI/CustomMUITextField'
import './index.scss'

export const LandingPage: React.FC = (): ReactElement => {
  const { t } = useTranslation('translations')
  const classes = useStyles()
  const [state, setState] = useState({
    goToSlide: 0,
    isOpenDrawer: false,
  })
  const slides = [
    {
      key: 1,
      content: <img src={Images.Slide1} alt="1" style={{ transform: state.goToSlide !== 0 ? 'scale(1.3)' : 'scale(1)' }} />
    },
    {
      key: 2,
      content: <img src={Images.Slide2} alt="2" style={{ transform: state.goToSlide !== 1 ? 'scale(1.3)' : 'scale(1)' }} />
    },
    {
      key: 3,
      content: <img src={Images.Slide1} alt="3" style={{ transform: state.goToSlide !== 2 ? 'scale(1.3)' : 'scale(1)' }} />
    }
  ].map((slide, index) => {
    return { ...slide, onClick: () => handleChangeState('goToSlide', index) };
  });

  const handleChangeState = (key: string, value: unknown) => {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }))
  }
  return (
    <div className='landing' >
      {/* <ToolbarLandingPage toggleDrawer={() => handleChangeState('isOpenDrawer', !state.isOpenDrawer)} /> */}
      {/* <DrawerLandingPage isOpen={state.isOpenDrawer} onclose={() => handleChangeState('isOpenDrawer', false)} /> */}
      {/* <div className='first-content'>
        <div className='left'>
          <img src={Images.ThemeScreen} className='image' />
          <Button color="primary" className={clsx(classes.sectionMobile, 'button')}>
            <img src={Images.IconApple} className='image' />
            <div className='texts'>
              <p className="text fs-12">{t('downloadOnThe')}</p>
              <p className="text fs-18 lh-18">App Store</p>
            </div>
          </Button>
        </div>
        <div className='right'>
          <div className='content'>
            <img src={Images.AppIcon} className={clsx(classes.sectionDesktop,)} />
            <h1 className='title'>
              <img src={Images.AppIcon} className={clsx(classes.sectionMobile, 'image')} />
              PetTrait
              </h1>
            <h2 className='describe'>{t('turnYourPet')}</h2>
            <h2 className='describe'>{t('aRenaissancePersonality')}</h2>
            <Button color="primary" className={clsx(classes.sectionDesktop, 'button')}>
              <img src={Images.IconApple} className='image' />
              <div className='texts'>
                <p className="text fs-12">{t('downloadOnThe')}</p>
                <p className="text fs-18 lh-18">App Store</p>
              </div>
            </Button>
          </div>
          <div className='footer'>
            <img src={Images.BallWithDots} className='image' />
          </div>
        </div>
        <img src={Images.Squares} className='image' />
      </div> */}
      {/* <div className='second-content'>
        <div className='icon-green'>
          <img src={Images.IconGreen} className='image' />
        </div>
        <div className='icon-yellow'>
          <img src={Images.IconYellow} className='image' />
        </div>
        <div className='texts'>
          <p className='describe'>{t('justUploadPhoto')}</p>
          <h5 className='title'>{t('letOutArtistDoTheRest')}</h5>
        </div>
        <div className='main'>
          <div className='left'>
            <div className='content'>
              <img src={Images.IconChooseCustume} className='image' />
              <div className='texts'>
                <h5 className='title'>Choose your costume</h5>
                <p className='describe'>Pick a costume from over150 unique options or Special Request Custom Pet</p>
              </div>
            </div>
            <div className='three-dots'>
              <span className={clsx('dot', { active: state.goToSlide === 0 })} onClick={() => handleChangeState('goToSlide', 0)} />
              <span className={clsx('dot', { active: state.goToSlide === 1 })} onClick={() => handleChangeState('goToSlide', 1)} />
              <span className={clsx('dot', { active: state.goToSlide === 2 })} onClick={() => handleChangeState('goToSlide', 2)} />
            </div>
          </div>
          <div className='right'>
            <div className='slide' style={{ height: "500px", margin: "0 auto" }}>
              <Carousel
                slides={slides}
                goToSlide={state.goToSlide}
                offsetRadius={3}
                showNavigation={false}
                animationConfig={config.slow}
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className='third-content'>
        <div className='left'>
          <img src={Images.StartScreen} className='image' />
        </div>
        <div className='right'>
          <div className='content'>
            <img src={Images.GoldenStar} />
            <h1 className='title'>{t('goPremium')}</h1>
            <h2 className='describe'>{t('noWatermark')}</h2>
            <h2 className='describe mb-50'>{t('fastDelivery')}</h2>
            <h2 className='describe' style={{
              WebkitTextStrokeWidth: 1,
              WebkitTextStrokeColor: '#000000',
            }}>{t('TheMostUnique')}</h2>
            <h2 className='describe' style={{
              WebkitTextStrokeWidth: 1,
              WebkitTextStrokeColor: '#000000',
            }}>{t('necessaryGift')}</h2>
            <Button color="primary" className="button" style={{
              border: '1px solid #ffffff',
              background: '#000000'
            }}>
              <img src={Images.IconApple} className='image' />
              <div className='texts'>
                <p className="text fs-12">{t('downloadOnThe')}</p>
                <p className="text fs-18 lh-18">App Store</p>
              </div>
            </Button>
          </div>
        </div>
        <img src={Images.CreamBall} className='cream-ball' />
        <img src={Images.YellowBall} className='yellow-ball' />
        <img src={Images.PinkBall} className='pink-ball' />
        <img src={Images.BlueBall} className='blue-ball' />
        <img src={Images.LightPinkBall} className='lightPink-ball-left' />
        <img src={Images.LightPinkBall} className='lightPink-ball-right' />
      </div> */}
      {/* <Grid container className='footer'>
        <Grid item container xs={12} className='top'>
          <Grid item lg={3} md={3} sm={12} xs={12} className='group'>
            <h1 className='title'>Discover</h1>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className='group'>
            <h1 className='title'>Discover</h1>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className='group'>
            <h1 className='title'>Discover</h1>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
            <p className='headline'>Discover</p>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className='group'>
            <h1 className='title'>Discover</h1>
            <CustomMUITextField
              className='input-ios'
              placeholder='Enter your email address here'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={Images.EndAdorment} className='endAdorment' />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} className='bottom'>
          <Grid item lg={5} md={5} sm={12} xs={12} className='text'>
            <h1 className='title'>PetTrait</h1>
            <p className='coppy-right'>&#169; PetTrait 2021</p>
          </Grid>
          <Grid item xs={5}>
          </Grid>
        </Grid>
      </Grid> */}
    </div>
  )
} 
