import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnItem, SelectedFilterEntity } from '../../interfaces/common/table'
import { CustomSearchComponent } from '../../components/elements/CustomSearch'
import { CustomTableContainer } from '../../components/resources/CustomTableContainer'
import { TemplateInitialState, clearErrorTemplate } from './slice'
import { TemplateRow, Template } from '../../interfaces/template'
import { getListTemplates, createTemplate, updateTemplate, deleteTemplate } from '../../services/rest/template.services'
import { getListCategories } from '../../services/rest/category.services'
import './index.scss'
import { Button } from '@material-ui/core'
import { CustomMenu } from '../../components/resources/CustomMenu'
import history from '../../utils/history'
import { removeCurrentUser, removeToken } from '../../utils/helper/common'
import { ConfirmFormModal } from '../../components/resources/ConfirmFormModal'
import { toast, ToastContainer } from 'react-toastify'
import { UpdateTemplateModal } from '../../components/resources/UpdateTemplateModal'
import { OptionSelect } from '../../interfaces/common/menu'
import { CategoryInitialState } from '../Category/slice'

const columns: ColumnItem[] = [
  { id: 'id', align: 'left', isBlack: true, label: 'Id' },
  { id: 'title', align: 'left', isBlack: true, label: 'Title' },
  { id: 'coins', align: 'left', isBlack: true, label: 'Coins' },
  { id: 'type', align: 'left', isBlack: false, label: 'Type' },
  { id: 'priority', align: 'left', isBlack: false, label: 'Priority' },
  { id: 'faces', align: 'left', isBlack: false, label: 'Faces' },
  { id: 'isLandscape', align: 'left', isBlack: false, label: 'Landscape' },
  { id: 'createDate', align: 'left', isBlack: false, label: 'Created at' },
  { id: 'updateDate', align: 'left', isBlack: false, label: 'Updated at' },
]

interface TemplateContainerState {
  listTemplatesTable: TemplateRow[]
  isOpenedUpdateTemplateModal: boolean
  isOpenReasonForRejectionModal: boolean
  isCreated: boolean
  orderBy: string
  typeOrder: string
  loading: boolean
  categoryFilter: string
  templateDetail: Template
  searchKeyword: string
  isOpenFormConfirm: boolean
  listOptionsCategories: OptionSelect[]
  listDataSelectedFilters: SelectedFilterEntity[]
  id: string
  totalPages: number
  page: number
}

export const TemplateContainer = () => {
  const dispatch = useDispatch()
  const templateManagement: TemplateInitialState = useSelector((state: any) => state.templateManagement)
  const categoryManagement: CategoryInitialState = useSelector((state: any) => state.categoryManagement)
  const { templates, totalPages, page } = templateManagement
  const { categories } = categoryManagement
  const [state, setState] = useState({
    listTemplatesTable: [] as TemplateRow[],
    isOpenedUpdateTemplateModal: false,
    isOpenReasonForRejectionModal: false,
    isCreated: false,
    orderBy: 'createDate',
    typeOrder: 'DESC',
    categoryFilter: '',
    listDataSelectedFilters: [] as SelectedFilterEntity[],
    loading: true,
    searchKeyword: '',
    templateDetail: {} as Template,
    isOpenFormConfirm: false,
    listOptionsCategories: [] as OptionSelect[],
    id: '',
    totalPages: 0,
    page: 1,
  })
  const [anchorElementCreateTemplate, setAnchorElementCreateTemplate] = useState<null | HTMLElement>(null)

  useEffect(() => {
    dispatch(getListTemplates({ page: 0 }))
    dispatch(getListCategories())
  }, [])

  useEffect(() => {
    if (categories.length > 0) {
      const categoriesFilter: OptionSelect[] = []
      categories.forEach((item: any) => {
        const optionCategoryEntity: OptionSelect = {
          value: item.id,
          content: item.title,
        }
        categoriesFilter.push(optionCategoryEntity)
      })
      const listOptionsCategories = categories.map(item => {
        return {
          content: item.title,
          value: item.id
        }
      })
      const listDataSelectedFilters: SelectedFilterEntity[] = [
        {
          label: 'Category',
          data: categoriesFilter,
          fieldName: 'categoryFilter',
        },
      ]
      setState((prevState) => ({
        ...prevState,
        listDataSelectedFilters,
        listOptionsCategories,
      }))
    }
  }, [categories])

  useEffect(() => {
    dispatch(getListTemplates({
      page: 0,
      searchKeyword: state.searchKeyword,
      filter: state.categoryFilter,
      orderBy: state.orderBy,
      typeOrder: state.typeOrder.toUpperCase()
    }))
  }, [state.searchKeyword, state.categoryFilter, state.orderBy, state.typeOrder])

  useEffect(() => {
    if (Object.keys(templateManagement.error).length > 0) {
      handleCheckErrorMessage(templateManagement.error?.message as string)
    }
  }, [templateManagement.error])

  useEffect(() => {
    const listTemplatesTable = [] as TemplateRow[]
    templates.forEach((item: Template) => {
      const { id, title, categories, createDate, updateDate, coins, priority, faces, isLandscape } = item
      let type: string = ''
      if (categories.length > 0) {
        categories.forEach(category => type = type !== '' ? type.concat('\n' + category.title) : type.concat(category.title))
      }
      const templateData: TemplateRow = {
        id,
        title,
        coins,
        type,
        priority,
        faces,
        isLandscape: (isLandscape == undefined || isLandscape == null) ? 'false' : isLandscape.toString(),
        createDate,
        updateDate,
      }
      listTemplatesTable.push(templateData)
    })
    setState((prevState: TemplateContainerState) => ({
      ...prevState,
      listTemplatesTable,
      page: page + 1,
      totalPages,
    }))
  }, [templates, page, totalPages])

  const handleCheckErrorMessage = (message: string): void => {
    if (message?.includes("401")) {
      removeCurrentUser()
      removeToken()
      dispatch(clearErrorTemplate())
      toast.error('Expried token!')
      history.push('/login')
    }
  }

  const findLastElementOfString = (value: string, typeSplit = '.'): string => {
    const arrElements: string[] = value.split(typeSplit)
    const index: number = arrElements.length - 1
    return arrElements[index]
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: TemplateContainerState) => ({
      ...prevState,
      [key]: value as Pick<TemplateContainerState, keyof TemplateContainerState>,
    }))
  }

  const handleOpenModalFromUserRow = (id: string) => {
    const templateDetail = templates.filter(item => { return item.id === id })[0]
    setState((prevState: TemplateContainerState) => ({
      ...prevState,
      isCreated: false,
      isOpenedUpdateTemplateModal: true,
      loading: true,
      templateDetail
    }))
  }

  const handleOrderListTemplates = (orderBy: string, typeOrder: string) => {
    setState((prevState: TemplateContainerState) => ({
      ...prevState,
      orderBy,
      typeOrder,
    }))
  }

  const handleCheckColorText = (value: string): boolean => {
    if (["false"].includes(findLastElementOfString(value ? value.toString() : ''))) return true
    return false
  }

  const handleSearch = (value: string) => {
    handleChangeState('page', 1)
    handleChangeState('searchKeyword', value)
  }

  const callbackDeleteTemplate = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      toast.success('Success')
      dispatch(getListTemplates({
        page: state.page - 1,
        searchKeyword: state.searchKeyword,
        filter: state.categoryFilter,
        orderBy: state.orderBy,
        typeOrder: state.typeOrder.toUpperCase()
      }))
    } else {
      handleCheckErrorMessage(message as string)
      toast.error('Have an issue when delete template. Try again!')
    }
  }

  const handleDelete = (): void => {
    handleChangeState('isOpenFormConfirm', false)
    deleteTemplate(state.id, callbackDeleteTemplate)
  }

  const callback = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      dispatch(getListTemplates({
        page: state.page - 1,
        searchKeyword: state.searchKeyword,
        filter: state.categoryFilter,
        orderBy: state.orderBy,
        typeOrder: state.typeOrder.toUpperCase()
      }))
      toast.success("Success!")
    } else {
      handleCheckErrorMessage(message as string)
      toast.error(message)
    }
  }

  const handleUpdateTemplate = (data: unknown) => {
    handleChangeState('isOpenedUpdateTemplateModal', false)
    if (state.isCreated) {
      createTemplate(data, callback)
    } else {
      updateTemplate(data, callback)
    }
  }

  const onChangedPage = (value: number): void => {
    dispatch(
      getListTemplates(
        {
          page: value - 1,
          searchKeyword: state.searchKeyword,
          filter: state.categoryFilter,
          orderBy: state.orderBy,
          typeOrder: state.typeOrder.toUpperCase()
        },
      ),
    )
  }

  const showLoading: boolean = templateManagement.loading

  return (
    <div className="user-management">
      <CustomSearchComponent handleSearch={handleSearch} name='Search with title and id' />
      <div className="header">
        <h2 className="title">
          Templates management
        </h2>
        <div className='group-button'>
          <Button
            variant="contained"
            className="button justify-content-space-around mr-10"
            color="primary"
            onClick={() => dispatch(getListTemplates({
              page,
              searchKeyword: state.searchKeyword,
              filter: state.categoryFilter,
              orderBy: state.orderBy,
              typeOrder: state.typeOrder.toUpperCase()
            }))}
          >
            <p className="font-roboto text-white">
              Refresh data
          </p>
          </Button>
          <Button
            variant="contained"
            className="button justify-content-space-around"
            color="primary"
            onClick={() => {
              setState((prevState: TemplateContainerState) => ({
                ...prevState,
                isOpenedUpdateTemplateModal: true,
                isCreated: true
              }))
            }}
          >
            <p className="font-roboto text-white">
              Create template
          </p>
          </Button>
        </div>
      </div>
      <CustomTableContainer
        showLoading={showLoading}
        onClickTableRow={handleOpenModalFromUserRow}
        columns={columns}
        dataTable={state.listTemplatesTable}
        handleCheckColorText={handleCheckColorText}
        isHaveAvatar={false}
        isHaveFilter
        isShowPagination
        page={state.page}
        totalPages={state.totalPages}
        onChangedFilter={handleChangeState}
        handleOrderTable={handleOrderListTemplates}
        handleShowMoreActions={(anchorElement: HTMLElement, id: string) => {
          setAnchorElementCreateTemplate(anchorElement)
          handleChangeState('id', id)
        }}
        isShowMoreActions={true}
        onChangedPage={onChangedPage}
        valueFilters={[state.categoryFilter]}
        listDataSelectedFilters={state.listDataSelectedFilters}
      />
      <UpdateTemplateModal
        isOpen={state.isOpenedUpdateTemplateModal}
        isCreated={state.isCreated}
        handleCloseModal={() => {
          handleChangeState('isOpenedUpdateTemplateModal', false)
          handleChangeState('templateDetail', {})
        }}
        isLoading={showLoading}
        listOptionsCategories={state.listOptionsCategories}
        handleSave={handleUpdateTemplate}
        templateDetail={state.templateDetail}
      />
      <CustomMenu
        anchorElement={anchorElementCreateTemplate}
        vertical={'center'}
        horizontal={'left'}
        listMenuItems={[{
          name: 'Delete',
        },]}
        handleCloseMenu={() => setAnchorElementCreateTemplate(null)}
        handleSubmit={() => {
          handleChangeState('isOpenFormConfirm', true)
          setAnchorElementCreateTemplate(null)
        }}
      />
      <ToastContainer />
      <ConfirmFormModal
        onCancel={() => handleChangeState('isOpenFormConfirm', false)}
        onYes={handleDelete}
        name={templates.filter(item => item.id === state.id)[0]?.title || ''}
        isOpen={state.isOpenFormConfirm}
      />
    </div>
  )
}