import { CallApi, uploadImageApi } from '../api'
import {
  startGetRequests,
  successGetRequests,
  failRequestApiFromRequestsSlice,
  successGetSettingInfo,
  startGetSettingInfo,
} from '../../containers/Request/slice'
import { checkValue } from '../../utils/helper/common'

export const getListRequests = (input?: {
  page: number,
  userIdFilter?: string,
  templateIdFilter?: string,
  searchKeyword?: string,
  orderBy?: string,
  typeOrder?: string,
}): ((dispatch: any) => Promise<void>) => {
  return async (dispatch) => {
    let params: Record<string, unknown> = {}
    if (input) {
      const { page, userIdFilter, templateIdFilter, orderBy, typeOrder, searchKeyword } = input
      const data: Record<string, unknown> = {
        page,
        userIdFilter,
        templateIdFilter,
        searchKeyword,
        orderBy,
        typeOrder,
      }
      params = checkValue(params, data)
    }
    dispatch(startGetRequests())
    try {
      const res = await CallApi({
        endpoint: 'request',
        method: 'GET',
        params
      })
      dispatch(successGetRequests(res))
    } catch (err) {
      dispatch(failRequestApiFromRequestsSlice(err))
    }
  }
}

export const deleteRequest = async (id: string, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `request/${id}`,
      method: 'DELETE',
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const updateRequest = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    await CallApi({
      endpoint: `request/${(data as Record<string, unknown>).id}`,
      method: 'PUT',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const sendEmail = async (data: any): Promise<string | undefined> => {
  try {
    await uploadImageApi('upload/add-file', data.complatedImage)
    await CallApi({
      endpoint: `request/email`,
      method: 'POST',
      body: { requestData: data.requestData, completedImage: data.complatedImage.name }
    })
  } catch (err) {
    return err.message
  }
}

export const updateCustomizeCoins = async (coins: unknown): Promise<string | undefined> => {
  try {
    await CallApi({
      endpoint: 'request/update-customize-coins',
      method: 'POST',
      body: { coins }
    })
  } catch (err) {
    return err.message
  }
}

export const updateExpriedDate = async (days: unknown): Promise<string | undefined> => {
  try {
    await CallApi({
      endpoint: 'request/update-expried-date',
      method: 'POST',
      body: { days }
    })
  } catch (err) {
    return err.message
  }
}

export const updateCompletedImages = async (complatedImages: string[]): Promise<string | undefined> => {
  try {
    await CallApi({
      endpoint: 'request/update-complated-images',
      method: 'POST',
      body: { complatedImages }
    })
  } catch (err) {
    return err.message
  }
}

export const getSettingInfo = (): ((dispatch: any) => Promise<void>) => {
  return async (dispatch) => {
    dispatch(startGetSettingInfo())
    try {
      const rs = await CallApi({
        endpoint: 'request/get-setting-infomation',
        method: 'POST',
      })
      dispatch(successGetSettingInfo(rs))
    } catch (err) {
      dispatch(failRequestApiFromRequestsSlice(err))
    }
  }
}