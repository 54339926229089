import { createSlice } from '@reduxjs/toolkit'
import { Review } from '../../../../interfaces/review'

export interface ReviewInitialState {
  loading: boolean
  reviews: Review[]
  error: Error
}

const initialState: ReviewInitialState = {
  loading: false,
  reviews: [] as Review[],
  error: {} as Error,
}

const ReviewSlice = createSlice({
  name: 'triggerReview',
  initialState,
  reducers: {
    startGetListReviews: (state) => {
      state.loading = true
      state.reviews = []
      state.error = {} as Error
    },
    successGetListReviews: (state, { payload }) => {
      state.loading = false
      state.reviews = payload.data
    },
    startDeleteReview: (state) => {
      state.loading = true
      state.reviews = []
      state.error = {} as Error
    },
    successDeleteReview: (state, { payload }) => {
      state.loading = false
      state.reviews = payload.data
    },
    failRequestApiFromReviewSlice: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clearErrorReview: (state) => {
      state.error = {} as Error
    }
  },
})

const { actions, reducer } = ReviewSlice
export const {
  startGetListReviews,
  successGetListReviews,
  startDeleteReview,
  successDeleteReview,
  clearErrorReview,
  failRequestApiFromReviewSlice,
} = actions

export default reducer
