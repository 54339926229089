import React, { ChangeEvent, ReactElement, ReactNode } from 'react'
import { InputLabel, OutlinedInput } from '@material-ui/core'
import { CustomMUIFormControl } from '../CustomMUI/CustomMUIFormControl'

interface CustomOutlinedInputProps {
  fieldName: string
  label: string
  value: string
  onChanged: (key: string, value: string) => void
  labelWidth: number
  className?: string
  error?: boolean
  endAdornment?: ReactNode
  isShowPassword?: boolean
  disabled?: boolean
}

export const CustomOutlinedInput = (props: CustomOutlinedInputProps): ReactElement => {
  const {
    onChanged,
    labelWidth,
    value,
    className,
    label,
    fieldName,
    error,
    endAdornment,
    isShowPassword,
    disabled,
  } = props

  return (
    <CustomMUIFormControl className={className} variant="outlined">
      <InputLabel htmlFor={`outlined-adornment-${fieldName}`}>{label}</InputLabel>
      <OutlinedInput
        id={`outlined-adornment-${fieldName}`}
        type={isShowPassword ? 'text' : 'password'}
        error={error}
        value={value}
        disabled={disabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChanged(fieldName, event.target.value)}
        endAdornment={endAdornment}
        labelWidth={labelWidth}
      />
    </CustomMUIFormControl>
  )
}
