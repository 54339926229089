import React from 'react';
import { BrowserRouter, Switch, Route, Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { LoginContainer } from '../app/containers/Login'
import createMyTheme from '../assets/styles/Material-ui/styles';
import { AppLayout } from './containers/AppLayout'
import history from './utils/history'
import { LandingPage } from './containers/Landing';
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import './config/i18n'

const theme = createMyTheme({
  colors: {
    MainPink: '#D75F8D',
    PastelPink: '#E28BAE',
    Peach: '#FFB7B7',
    Beige: '#EFD4C1',
    Coral: '#E1ABA8',
    CoolBlue: '#9DB6DF',
    LightBlue: '#A2CBE3',
    LightGreen: '#C4E1C9',
    MainGrey: '#747474',
    DarkGrey: '#262626',
    HalfGrey: '#B1B1B1',
    LightGrey1: '#DBDBDB',
    LightGrey2: '#E5E5E5',
    VeryLightGrey: '#F7F7F7',
    Red: '#EB5757',
    White: '#FFFFFF',
  },
})

function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        {/* <Provider template={AlertTemplate} {...optionsAlert}> */}
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Switch>
              <Route path="/login" component={LoginContainer} />
              <Route path="/admin" component={AppLayout} />
              <Route component={LandingPage} />
            </Switch>
          </Router>
        </ThemeProvider>
        {/* </Provider> */}
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
