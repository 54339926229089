import React, { ReactElement } from 'react'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import './index.scss'

export const EmptyResult: React.FC = (): ReactElement => {
  return (
    <div className="empty-result">
      <ErrorOutlineIcon className="error-icon" />
      <p className="text">No data</p>
    </div>
  )
}
