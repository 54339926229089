import { createSlice } from '@reduxjs/toolkit'
import { Request } from '../../interfaces/request'

export interface RequestInitialState {
  loading: boolean
  requests: Request[]
  totalPages: number,
  page: number,
  settingInfo: any
  error: any
}

const initialState: RequestInitialState = {
  loading: true,
  requests: [] as Request[],
  totalPages: 0,
  page: 0,
  settingInfo: {},
  error: {} as any,
}

const requestSlice = createSlice({
  name: 'triggerRequest',
  initialState,
  reducers: {
    startGetRequests: (state) => {
      state.loading = true
      state.totalPages = 0
      state.page = 0
      state.error = {}
    },
    successGetRequests: (state, { payload }) => {
      state.loading = false
      if (payload.data.requests) {
        state.requests = payload.data.requests
        state.page = payload.data.page
        state.totalPages = payload.data.totalPages
      } else {
        state.requests = payload.data
      }
    },
    startGetSettingInfo: (state) => {
      state.loading = true
      state.error = {}
      state.settingInfo = {}
    },
    successGetSettingInfo: (state, { payload }) => {
      state.loading = false
      state.settingInfo = payload.data
    },
    failRequestApiFromRequestsSlice: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clearErrorRequest: (state) => {
      state.error = {}
    }
  },
})

const { actions, reducer } = requestSlice
export const { startGetRequests, successGetRequests, startGetSettingInfo, successGetSettingInfo, failRequestApiFromRequestsSlice, clearErrorRequest } = actions

export default reducer
