export const links = {
  home: (): string => '/admin/',
  templates: (): string => '/admin/templates',
  users: (): string => '/admin/users',
  categories: (): string => '/admin/categories',
  requests: (): string => '/admin/requests',
  notifications: (): string => '/admin/notifications',
  setting: (): string => '/admin/setting',
  landing: (): string => '#'
}
