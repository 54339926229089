import { getToken } from './../../utils/helper/common/index';
import axios, { AxiosPromise } from 'axios'

interface CallApiParams {
  endpoint: string
  method?: 'GET' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH'
  body?: unknown
  params?: unknown
}

export const CallApi = async (input: CallApiParams): Promise<AxiosPromise> => {
  const { endpoint, method, body } = input
  const token = getToken()
  let { params } = input
  let headers: Record<string, string> = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `bearer ${token}`,
  }
  return axios({
    method: method ? method : 'GET',
    url: `${process.env.REACT_APP_BASE_URL_API}/${endpoint}`,
    data: body ? body : '',
    params: !!params ? params : '',
    headers,
  })
}

export const uploadImageApi = async (endpoint: string, formData: any): Promise<AxiosPromise> => {
  let headers: Record<string, string> = {
    'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7mdVNxDACSxXgWCS',
  }
  var bodyFormData = new FormData();
  bodyFormData.append('file', formData as string | Blob);
  return axios.post(`${process.env.REACT_APP_BASE_URL_API}/${endpoint}`, bodyFormData, {
    headers,
    maxContentLength: 10486760,
    maxBodyLength: 10585760
  })
}