import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { CustomTableContainer } from '../../CustomTableContainer'
import { CustomMenu } from '../../CustomMenu'
import { ConfirmFormModal } from '../../ConfirmFormModal'
import { toast, ToastContainer } from 'react-toastify'
import '../../../../containers/Users/index.scss'
import { Product, ProductRow } from '../../../../interfaces/product'
import { ColumnItem } from '../../../../interfaces/common/table'
import { ProductInitialState, clearErrorProduct } from './slice'
import { getProducts, createProduct, deleteProduct, updateProduct } from '../../../../services/rest/product.services'
import { getListUsers } from '../../../../services/rest/user.services'
import { removeCurrentUser, removeToken } from '../../../../utils/helper/common'
import { UpdateProductModal } from '../../UpdateProductModal'
import history from '../../../../utils/history'

const columns: ColumnItem[] = [
  { id: 'id', align: 'left', isBlack: true, label: 'Id' },
  { id: 'title', align: 'left', isBlack: true, label: 'Title' },
  { id: 'price', align: 'left', isBlack: true, label: 'Price' },
]

interface ProductionsProps {
  requestId: string
}

interface ProductionsState {
  isOpenedUpdateProductModal: boolean
  listProductsTable: ProductRow[]
  isCreated: boolean
  orderBy: string
  typeOrder: string
  loading: boolean
  searchKeyword: string
  productDetail: Product
  isOpenFormConfirm: boolean
  id: string
}

export const Productions = (props: ProductionsProps) => {
  const dispatch = useDispatch()
  const productManagement: ProductInitialState = useSelector((state: any) => state.productManagement)
  const { products } = productManagement
  const { requestId } = props
  const [state, setState] = useState({
    isOpenedUpdateProductModal: false,
    listProductsTable: [] as ProductRow[],
    isCreated: false,
    orderBy: 'id',
    typeOrder: 'ASC',
    loading: true,
    searchKeyword: '',
    productDetail: {} as Product,
    isOpenFormConfirm: false,
    id: ''
  })

  const [anchorElementCreateProduct, setAnchorElementCreateProduct] = useState<null | HTMLElement>(null)

  useEffect(() => {
    dispatch(getProducts(requestId))
  }, [])

  useEffect(() => {
    if (products) {
      const listProductsTable = products.map(product => {
        return {
          id: product?.id,
          title: product?.title,
          price: product?.price,
        }
      })
      handleChangeState('listProductsTable', listProductsTable)
    }
  }, [products])

  useEffect(() => {
    if (productManagement.error) {
      handleCheckErrorMessage(productManagement.error?.message)
    }
  }, [productManagement.error])

  const handleCheckErrorMessage = (message: string): void => {
    if (message?.includes("401")) {
      removeCurrentUser()
      removeToken()
      dispatch(clearErrorProduct())
      toast.error('Expried token!')
      history.push('/login')
    }
  }

  const findLastElementOfString = (value: string, typeSplit = '.'): string => {
    const arrElements: string[] = value.split(typeSplit)
    const index: number = arrElements.length - 1
    return arrElements[index]
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: ProductionsState) => ({
      ...prevState,
      [key]: value as Pick<ProductionsState, keyof ProductionsState>,
    }))
  }

  const handleOpenModalFromUserRow = (id: string) => {
    const productDetail = products.filter(item => item.id === id)[0]
    setState((prevState: ProductionsState) => ({
      ...prevState,
      isCreated: false,
      isOpenedUpdateProductModal: true,
      loading: true,
      productDetail
    }))
  }

  const handleOrderListUsers = (orderBy: string, typeOrder: string) => {
    setState((prevState: ProductionsState) => ({
      ...prevState,
      orderBy,
      typeOrder,
    }))
  }

  const handleCheckColorText = (value: string): boolean => {
    if (["false"].includes(findLastElementOfString(value ? value.toString() : ''))) return true
    return false
  }

  const callbackDeleteProduct = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      toast.success('Success')
      dispatch(getProducts(requestId))
    } else {
      handleCheckErrorMessage(message as string)
      toast.error('Have an issue when delete product. Try again!')
    }
  }

  const handleDelete = (): void => {
    handleChangeState('isOpenFormConfirm', false)
    deleteProduct(state.id, callbackDeleteProduct)
  }

  const callback = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      dispatch(getProducts(requestId))
      toast.success("Success!")
    } else {
      handleCheckErrorMessage(message as string)
      toast.error(message)
    }
  }

  const handleUpdateProduct = (data: unknown) => {
    handleChangeState('isOpenedUpdateProductModal', false)
    if (state.isCreated) {
      createProduct({ ...(data as unknown as Record<string, unknown>), requestId }, callback)
    } else {
      updateProduct(data, callback)
    }
  }

  const showLoading: boolean = productManagement.loading

  return (
    <div className="user-management">
      <div className='group-button'>
        <Button
          variant="contained"
          className="button justify-content-space-around mr-10"
          color="primary"
          onClick={() => dispatch(getProducts(requestId))}
        >
          <p className="font-roboto text-white">
            Refresh data
          </p>
        </Button>
        <Button
          variant="contained"
          className="button justify-content-space-around"
          color="primary"
          onClick={() => {
            setState((prevState: ProductionsState) => ({
              ...prevState,
              isOpenedUpdateProductModal: true,
              isCreated: true
            }))
          }}
        >
          <p className="font-roboto text-white">
            Create product
          </p>
        </Button>
      </div>
      <CustomTableContainer
        showLoading={showLoading}
        onClickTableRow={handleOpenModalFromUserRow}
        columns={columns}
        dataTable={state.listProductsTable}
        handleCheckColorText={handleCheckColorText}
        isHaveAvatar={false}
        onChangedFilter={handleChangeState}
        handleOrderTable={handleOrderListUsers}
        handleShowMoreActions={(anchorElement: HTMLElement, id: string) => {
          setAnchorElementCreateProduct(anchorElement)
          handleChangeState('id', id)
        }}
        isShowMoreActions={true}
      />
      <UpdateProductModal
        isOpen={state.isOpenedUpdateProductModal}
        isCreated={state.isCreated}
        handleCloseModal={() => {
          handleChangeState('isOpenedUpdateProductModal', false)
          handleChangeState('productDetail', {})
        }}
        isLoading={showLoading}
        handleSave={handleUpdateProduct}
        productDetail={state.productDetail}
      />
      <CustomMenu
        anchorElement={anchorElementCreateProduct}
        vertical={'center'}
        horizontal={'left'}
        listMenuItems={[{
          name: 'Delete',
        },]}
        handleCloseMenu={() => setAnchorElementCreateProduct(null)}
        handleSubmit={() => {
          handleChangeState('isOpenFormConfirm', true)
          setAnchorElementCreateProduct(null)
        }}
      />
      <ConfirmFormModal
        onCancel={() => handleChangeState('isOpenFormConfirm', false)}
        onYes={handleDelete}
        name={products.filter(item => item.id === state.id)[0]?.title || ''}
        isOpen={state.isOpenFormConfirm}
      />
      <ToastContainer />
    </div>
  )
}