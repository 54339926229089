// @ts-nocheck
import React, { Fragment, ReactElement, useState, useEffect, ChangeEvent } from 'react'
import { Grid, Button, Dialog, AppBar, Tabs, Tab } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { CustomTextField } from '../../elements/CustomTextField'
import { CustomMUITextField } from '../../elements/CustomMUI/CustomMUITextField'
import { CustomLoading } from '../../elements/CustomLoading'
import { OptionSelect } from '../../../interfaces/common/menu'
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { uploadImage } from '../../../services/rest/template.services'
import 'antd/dist/antd.css';
import '../UpdateTemplateModal/index.scss'
import { toast, ToastContainer } from 'react-toastify'
import { Notification } from '../../../interfaces/notification'
import { v4 as uuid } from 'uuid';
import { TabPanel, a11yProps } from '../../elements/TablePanel'
import { UserInitialState } from '../../../containers/Users/slice'
import { UserData } from '../../../interfaces/user'

interface UpdateNotificationModalProps {
  handleCloseModal: () => void
  isOpen: boolean
  isLoading: boolean
  listOptionsCategories: OptionSelect[]
  isCreated: boolean
  notificationDetail: Notification
  handleSave: (data) => void
}

const listOptionsActions = [
  {
    value: 'request',
    content: 'Request'
  },
  {
    value: 'welcome',
    content: 'Welcome'
  },
  {
    value: 'notify',
    content: 'Notify'
  },
]

export const UpdateNotificationModal: React.FC<UpdateNotificationModalProps> = (props: UpdateNotificationModalProps): ReactElement => {
  const { isOpen, isLoading, handleCloseModal, listOptionsCategories, isCreated, handleSave, notificationDetail } = props
  const userManagement: UserInitialState = useSelector((state: any) => state.userManagement)
  const { listUsers } = userManagement
  const [state, setState] = useState({
    loading: false,
    listOptionsSelected: [] as OptionSelect[],
    isOpenPreview: false,
    previewImage: '',
    image: '',
    thumbnail: ''
  })
  const [notificationData, setNotificationData] = useState({
    title: '',
    body: '',
    deeplink: '',
    action: '',
    userId: ''
  })
  const [image, setImage] = useState([])
  const [thumbnail, setThumbnail] = useState([])
  const [value, setValue] = React.useState(0);

  const resetData = () => {
    setState({
      loading: false,
      isOpenPreview: false,
      listOptionsSelected: [],
      previewImage: '',
      image: '',
      thumbnail: '',
    })
    setNotificationData({
      title: '',
      coins: 0,
      faces: 0,
      priority: 0,
    })
    setImage([])
    setThumbnail([])
  }

  useEffect(() => {
    if (!isCreated && Object.keys(notificationDetail).length > 0) {
      if (!!notificationDetail.image) {
        const fileListProducts = {
          uid: uuid(),
          url: notificationDetail.image
        }
        setImage([fileListProducts])
      }

      if (notificationDetail.thumbnail) {
        const fileListProducts = {
          uid: uuid(),
          url: notificationDetail.thumbnail
        }
        setThumbnail([fileListProducts])
      }

      setNotificationData({
        title: notificationDetail.title,
        body: notificationDetail.body || '',
        action: notificationDetail.action || '',
        deeplink: notificationDetail.deeplink,
        userId: notificationDetail.userId,
      })
    } else {
      resetData()
    }
  }, [isCreated, notificationDetail])

  const handleChangeListProducts = (file) => {
    setThumbnail(file.fileList)
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handlePreview = async file => {
    setState(prevData => ({
      ...prevData,
      isOpenPreview: true,
      previewImage: file.url
    }))
  };

  const callbackChangeOriginalImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setImage([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const callbackChangeProductsImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setThumbnail([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const handleChangeNotificationData = (key: string, value: string): void => {
    setNotificationData(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const handleChangeActionType = (listOptionsSelected: OptionSelect): void => {
    handleChangeNotificationData('action', listOptionsSelected.value)
    handleChangeState('listOptionsSelected', listOptionsSelected)
  }

  const handleSaveModal = (): void => {
    let data = {
      ...notificationData,
      image: state.image !== '' ? state.image : (image[0]?.url || ''),
      thumbnail: state.thumbnail !== '' ? state.thumbnail : (thumbnail[0]?.url || ''),
    }
    if (!isCreated) {
      data = {
        ...notificationDetail,
        ...data,
      }
    }
    handleSave(data)
    resetData()
    handleCloseModal()
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderContent = (): ReactElement => {
    return (
      <div className='header-tabs'>
        <AppBar position="static" className='appBar'>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className='tabs'>
            <Tab label="Notification" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={0} direction="column">
            <div className="content">
              <Grid container className="row" spacing={4}>
                <Grid item lg={4} className="row">
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <h4 className="title mb-10">Notification information</h4>
                    <CustomTextField
                      label="Title"
                      value={notificationData.title}
                      onChanged={handleChangeNotificationData}
                      fieldName="title"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Body"
                      value={notificationData.body}
                      onChanged={handleChangeNotificationData}
                      fieldName="body"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Deeplink"
                      value={notificationData.deeplink}
                      onChanged={handleChangeNotificationData}
                      fieldName="deeplink"
                      className="text-field mb-50 "
                    />
                  </Grid>
                </Grid>
                <Grid container item lg={8} className="row">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h4 className="Original image">Image</h4>
                    <Upload
                      key="original"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={image}
                      beforeUpload={(data) => {
                        handleChangeState('loading', true)
                        uploadImage(data, callbackChangeOriginalImage)
                      }}
                      onPreview={handlePreview}
                      onChange={(file) => setImage(file.fileList)}
                    >
                      {uploadButton}
                    </Upload>
                    <CustomTextField
                      label="Image"
                      value={state.image}
                      onChanged={handleChangeState}
                      fieldName="image"
                      className="text-field mb-50 "
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const listImage = [{
                            uid: uuid(),
                            url: state.thumbnail
                          }] as any
                          setImage(listImage)
                          handleChangeState('image', '')
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h4 className="Original image">Thumbnail</h4>
                    <Upload
                      key="productions"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={thumbnail}
                      beforeUpload={(data) => {
                        handleChangeState('loading', true)
                        uploadImage(data, callbackChangeProductsImage)
                      }}
                      onPreview={handlePreview}
                      onChange={handleChangeListProducts}
                    >
                      {uploadButton}
                    </Upload>
                    <CustomTextField
                      label="Thumbnail"
                      value={state.thumbnail}
                      onChanged={handleChangeState}
                      fieldName="thumbnail"
                      className="text-field mb-50 "
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const listImage = [{
                            uid: uuid(),
                            url: state.thumbnail
                          }] as any
                          setThumbnail(listImage)
                          handleChangeState('thumbnail', '')
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2} className="footer">
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Button variant="outlined" className="button" color="secondary" onClick={() => {
                  resetData()
                  handleCloseModal()
                }}>
                  <p className="text-pink text-transform-normal">
                    Back
              </p>
                </Button>
              </Grid>
              <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className="button"
                    color="primary"
                    onClick={() => handleSaveModal()}
                  >
                    <p className="text-white text-transform-normal">
                      Save
                </p>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    )
  }

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleCloseModal}
      className="update-notification-modal"
    >
      {state.loading && <CustomLoading className="loading-modal" />}
      {renderContent()}
      <Modal
        visible={state.isOpenPreview}
        footer={null}
        onCancel={() => handleChangeState('isOpenPreview', false)}
      >
        <img alt="example" style={{ width: '100%' }} src={state.previewImage} />
      </Modal>
      <ToastContainer />
    </Dialog>
  )
}
