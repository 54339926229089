import React, { ReactElement, useState, useEffect, ChangeEvent, MouseEvent } from 'react'
import { Grid, Button, Dialog } from '@material-ui/core'
import { CustomTextField } from '../../elements/CustomTextField'
import { Images } from '../../../constants/images'
import { OptionSelect } from '../../../interfaces/common/menu'
import 'antd/dist/antd.css';
import '../UpdateProductModal//index.scss'
import { toast, ToastContainer } from 'react-toastify'
import { UserData } from '../../../interfaces/user'

interface UpdateUserModalProps {
  handleCloseModal: () => void
  isOpen: boolean
  isLoading: boolean
  isCreated: boolean
  userDetail: UserData
  handleSave: (data: unknown) => void
}

export const UpdateUserModal: React.FC<UpdateUserModalProps> = (props: UpdateUserModalProps): ReactElement => {
  const { isOpen, isLoading, handleCloseModal, isCreated, handleSave, userDetail } = props

  const [userData, setUserData] = useState({
    email: '',
  })

  useEffect(() => {
    if (isCreated) {
      resetData()
    } else {
      handleChangeUserData('email', userDetail?.email || '')
      setUserData({
        email: userDetail?.email || '',
      })
    }
  }, [isCreated, userDetail])

  const resetData = () => {
    setUserData({
      email: '',
    })
  }

  const handleChangeUserData = (key: string, value: string): void => {
    if (key === 'priority') {
      setUserData(prevData => ({
        ...prevData,
        priority: parseInt(value.match(/\d+/g) as unknown as string)
      }))
    } else {
      setUserData(prevData => ({
        ...prevData,
        [key]: value
      }))
    }
  }

  const handleSaveModal = (): void => {
    let data = {
      ...userData,
      id: userDetail.id
    }
    handleSave(data)
    resetData()
    handleCloseModal()
  }

  const renderContent = (): ReactElement => {
    return (
      <div className='header-tabs' style={{ padding: 30 }}>
        <Grid container spacing={0} direction="column">
          <Grid item lg={12} className="header mb-70">
            <h4 className="title" style={{ textAlign: 'center' }}>User</h4>
          </Grid>
          <div className="content">
            <Grid container className="row" spacing={4}>
              <Grid item lg={12} className="row">
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  <h4 className="title mb-10">User information</h4>
                  <CustomTextField
                    label="Email"
                    value={userData.email}
                    onChanged={handleChangeUserData}
                    fieldName="email"
                    className="text-field mb-50 "
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2} className="footer">
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Button variant="outlined" className="button" color="secondary" onClick={() => {
                resetData()
                handleCloseModal()
              }}>
                <p className="text-pink text-transform-normal">
                  Back
                </p>
              </Button>
            </Grid>
            <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  className="button"
                  color="primary"
                  onClick={() => handleSaveModal()}
                >
                  <p className="text-white text-transform-normal">
                    Save
                  </p>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
      onClose={handleCloseModal}
      className="update-product-modal"
    >
      {renderContent()}
      <ToastContainer />
    </Dialog>
  )
}
