import React, { useState, ReactElement } from 'react'
import { InputAdornment } from '@material-ui/core'
import { CustomMUITextField } from '../CustomMUI/CustomMUITextField'
import { Images } from '../../../constants/images'
import './index.scss'

const { IconSearch } = Images

interface SearchProps {
  handleSearch: (data: string) => void
  name: string
}

export const CustomSearchComponent: React.FC<SearchProps> = (props: SearchProps): ReactElement => {

  const [value, setValue] = useState('')

  const { handleSearch, name } = props

  const search = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSearch(value)
    }
  }

  return (
    <div className="custom-search">
      <CustomMUITextField
        id="outlined-basic"
        variant="outlined"
        className="text-field"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={IconSearch} className="icon-search" alt="icon search" />
            </InputAdornment>
          ),
        }}
        placeholder={name}
        onKeyPress={search}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  )
}
