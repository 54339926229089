import React, { useState, useEffect, ReactElement, Fragment } from 'react'
import history from '../../utils/history'
import { Button, Grid, InputAdornment, IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { CustomOutlinedInput } from '../../components/elements/CustomOutlinedInput'
import { CustomTextField } from '../../components/elements/CustomTextField'
import { Images } from '../../constants/images'
import { SkeletonRegistration } from '../../components/elements/Skeleton/Registration'
import { signIn } from '../../services/rest/login.services'
import './index.scss'
import { ToastContainer, toast } from 'react-toastify';
import { getToken, getCurrentUser } from '../../utils/helper/common'

interface LoginContainerState {
  email: string
  password: string
  passwordConfirmation: string
  isAcceptTermsAndConditions: boolean
  isShowPassword: boolean
  disabled: boolean
  showLoading: boolean
}

export const LoginContainer: React.FC = (): ReactElement => {
  const [state, setState] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
    isShowPassword: false,
    isAcceptTermsAndConditions: false,
    disabled: true,
    showLoading: false,
  })
  const { email, password, passwordConfirmation, isShowPassword, isAcceptTermsAndConditions, disabled } = state

  useEffect(() => {
    if (!!getToken() && !!getCurrentUser()) {
      const currentUser = JSON.parse((getCurrentUser() as string))
      if (currentUser.email === process.env.REACT_APP_ACCOUNT_ADMIN) {
        return history.push('/admin')
      }
    }
  }, [])

  useEffect(() => {
    handleChangeState('disabled', email === '' || password === '')
  }, [email, password, passwordConfirmation, isAcceptTermsAndConditions])

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: LoginContainerState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
  }

  const _callbackAction = (isSuccess: boolean, message?: string) => {
    if (isSuccess) {
      handleChangeState('password', '')
      history.push('/admin')
    } else {
      toast.error("Invalid account!")
    }
    handleChangeState('showLoading', false)
  }

  const handleLogin = (pressKey?: string): void => {
    if (!pressKey || (!state.disabled && pressKey === 'Enter')) {
      handleChangeState('showLoading', true)
      const dataLogin = { email: email, password: password, deviceToken: '' }
      signIn(dataLogin, _callbackAction)
    }
  }

  const renderContent = (): ReactElement => {
    return (
      <div onKeyPress={(event) => handleLogin(event.key)}>
        <h4 className="title-welcome">Login</h4>
        <p className="description">Please, input your account!</p>
        <div className="mb-10 description">
        </div>
        <CustomTextField
          key="email"
          value={email}
          fieldName="email"
          label="Email"
          className="text-field"
          onChanged={handleChangeState}
        />
        <CustomOutlinedInput
          key="password"
          value={password}
          fieldName="password"
          label="Password"
          labelWidth={80}
          onChanged={handleChangeState}
          className="text-field mb-10"
          isShowPassword={isShowPassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleChangeState('isShowPassword', !isShowPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          disabled={disabled}
          variant="contained"
          className="button w-80p"
          color="primary"
          onClick={() => handleLogin()}
        >
          <p className="text-white text-transform-normal font-size-16 font-weight-500">
            Login
          </p>
        </Button>
      </div>
    )
  }

  const renderMain = (): ReactElement => {
    if (state.showLoading) {
      return <SkeletonRegistration className="skeleton" />
    } else {
      return (
        <Fragment>
          <div className="main">{renderContent()}</div>
        </Fragment>
      )
    }
  }

  return (
    <div className="login">
      <Grid container className="login-container">
        <Grid item lg={6}>
          <div className="image" />
        </Grid>
        <Grid item lg={6} md={12} xs={12} sm={12}>
          <div className="content-login">
            <div className="header">
              <img src={Images.LogoMe} className="logo" alt="Logo" />
            </div>
            {renderMain()}
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  )
}
