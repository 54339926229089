import { combineReducers } from 'redux'
import LoginReducer from '../containers/Login/slice'
import UserManagementReducer from '../containers/Users/slice'
import TemplateManagementReducer from '../containers/Template/slice'
import NotificationManagementReducer from '../containers/Notification/slice'
import CategoryManagementReducer from '../containers/Category/slice'
import RequestManagementReducer from '../containers/Request/slice'
import ReviewsManagementReducer from '../components/resources/UpdateTemplateModal/FakeReviews/slice'
import ProductsManagementReducer from '../components/resources/UpdateRequestModal/Products/slice'

export default combineReducers({
  login: LoginReducer,
  userManagement: UserManagementReducer,
  templateManagement: TemplateManagementReducer,
  categoryManagement: CategoryManagementReducer,
  requestManagement: RequestManagementReducer,
  reviewManagement: ReviewsManagementReducer,
  productManagement: ProductsManagementReducer,
  notificationManagement: NotificationManagementReducer,
})
