import { CallApi, uploadImageApi } from '../api'
import {
  startGetNotifications,
  successGetNotifications,
  failRequestApiFromNotificationSlice,
} from '../../containers/Notification/slice'

export const getListNotifications = (): ((dispatch: any) => Promise<void>) => {
  return async (dispatch) => {
    dispatch(startGetNotifications())
    try {
      const res = await CallApi({
        endpoint: 'notification/get-notifications',
        method: 'POST',
      })
      dispatch(successGetNotifications(res))
    } catch (err) {
      dispatch(failRequestApiFromNotificationSlice(err))
    }
  }
}

export const createNotification = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: 'notification',
      method: 'POST',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const createInbox = async (userId: string, data: unknown): Promise<string | undefined> => {
  try {
    await CallApi({
      endpoint: `notification/inbox/${userId}`,
      method: 'POST',
      body: data
    })
  } catch (err) {
    return err.message
  }
}

export const updateNotification = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `notification/${(data as Record<string, unknown>).id}`,
      method: 'PUT',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const deleteNotification = async (id: string, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `notification/${id}`,
      method: 'DELETE',
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}