import { createMuiTheme, ThemeOptions, Theme } from '@material-ui/core/styles'

export default function createMyTheme(options: ThemeOptions): Theme {
  return createMuiTheme({
    colors: {
      MainPink: '#D75F8D',
      PastelPink: '#E28BAE',
      Peach: '#FFB7B7',
      Beige: '#EFD4C1',
      Coral: '#E1ABA8',
      CoolBlue: '#9DB6DF',
      LightBlue: '#92C6DD',
      LightGreen: '#C4E1C9',
      MainGrey: '#747474',
      DarkGrey: '#262626',
      HalfGrey: '#B1B1B1',
      LightGrey1: '#DBDBDB',
      LightGrey2: '#E5E5E5',
      VeryLightGrey: '#F7F7F7',
      White: '#FFFFFF',
      Red: '#EB5757',
      ...options,
    },
    palette: {
      primary: {
        main: '#D75F8D',
      },
      secondary: {
        main: '#FFFFFF',
      },
    },
  })
}
