import React, { ReactElement } from 'react'
import { MenuItem } from '@material-ui/core'
import './index.scss'

interface CustomMenuItemProps {
  name: string
  icon?: string
  className?: string
  handleSubmit: () => void
}

export const CustomMenuItem: React.FC<CustomMenuItemProps> = (props: CustomMenuItemProps): ReactElement => {
  const { name, icon, className, handleSubmit } = props

  return (
    <MenuItem className="custom-menu-item" onClick={() => handleSubmit()}>
      {!!icon && <img src={icon} className="img-plus" alt="icon plus" />}
      <p className={`name-modal ${className}`}>{name}</p>
    </MenuItem>
  )
}
