import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnItem } from '../../interfaces/common/table'
import { CustomSearchComponent } from '../../components/elements/CustomSearch'
import { CustomTableContainer } from '../../components/resources/CustomTableContainer'
import { getListCategories, createCategory, updateCategory, deleteCategory, updateVersion } from '../../services/rest/category.services'
import { Button } from '@material-ui/core'
import { CustomMenu } from '../../components/resources/CustomMenu'
import history from '../../utils/history'
import { removeCurrentUser, removeToken } from '../../utils/helper/common'
import { ConfirmFormModal } from '../../components/resources/ConfirmFormModal'
import { toast, ToastContainer } from 'react-toastify'
import { OptionSelect } from '../../interfaces/common/menu'
import { CategoryInitialState, clearErrorCategory } from './slice'
import { Category, CategoryRow } from '../../interfaces/category'
import '../Template/index.scss';
import { UpdateCategoryModal } from '../../components/resources/UpdateCategoryModal'

const columns: ColumnItem[] = [
  { id: 'id', align: 'left', isBlack: true, label: 'Id' },
  { id: 'title', align: 'left', isBlack: true, label: 'Title' },
  { id: 'priority', align: 'left', isBlack: true, label: 'Priority' },
]

interface CategoryContainerState {
  listCategoriesTable: CategoryRow[]
  isOpenedUpdateCategoryModal: boolean
  isCreated: boolean
  orderBy: string
  typeOrder: string
  loading: boolean
  categoryDetail: Category
  searchKeyword: string
  isOpenFormConfirm: boolean
  listOptionsCategories: OptionSelect[]
  id: string
}

export const CategoryContainer = () => {
  const dispatch = useDispatch()
  const categoryManagement: CategoryInitialState = useSelector((state: any) => state.categoryManagement)
  const { categories, error } = categoryManagement
  const [state, setState] = useState({
    isOpenedUpdateCategoryModal: false,
    listCategoriesTable: [] as CategoryRow[],
    isCreated: false,
    orderBy: 'id',
    typeOrder: 'ASC',
    loading: true,
    searchKeyword: '',
    categoryDetail: {} as Category,
    isOpenFormConfirm: false,
    listOptionsCategories: [] as OptionSelect[],
    id: ''
  })
  const [anchorElementCreateUser, setAnchorElementCreateUser] = useState<null | HTMLElement>(null)

  useEffect(() => {
    dispatch(getListCategories())
  }, [])

  useEffect(() => {
    if (categories) {
      const listCategoriesTable = categories.map(item => {
        return {
          id: item.id,
          title: item.title,
          priority: item.priority
        }
      })
      handleChangeState('listCategoriesTable', listCategoriesTable)
    }
  }, [categories])

  useEffect(() => {
    if (error) {
      handleCheckErrorMessage(error?.message)
    }
  }, [error])

  const handleCheckErrorMessage = (message: string): void => {
    if (message?.includes("401")) {
      removeCurrentUser()
      removeToken()
      dispatch(clearErrorCategory())
      toast.error('Expried token!')
      history.push('/login')
    }
  }

  const findLastElementOfString = (value: string, typeSplit = '.'): string => {
    const arrElements: string[] = value.split(typeSplit)
    const index: number = arrElements.length - 1
    return arrElements[index]
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: CategoryContainerState) => ({
      ...prevState,
      [key]: value as Pick<CategoryContainerState, keyof CategoryContainerState>,
    }))
  }

  const handleOpenModalFromUserRow = (id: string) => {
    const categoryDetail = categories.filter(item => item.id === id)[0]
    setState((prevState: CategoryContainerState) => ({
      ...prevState,
      isCreated: false,
      isOpenedUpdateCategoryModal: true,
      loading: true,
      categoryDetail
    }))
  }

  const handleOrderListUsers = (orderBy: string, typeOrder: string) => {
    setState((prevState: CategoryContainerState) => ({
      ...prevState,
      orderBy,
      typeOrder,
    }))
  }

  const handleCheckColorText = (value: string): boolean => {
    if (["false"].includes(findLastElementOfString(value ? value.toString() : ''))) return true
    return false
  }

  const handleSearch = (value: string) => {
    // if (value.length > 2 || validateNumber.test(value)) {
    //   handleChangeState('searchKeyword', value)
    // }
    // if (value.length === 0 && !!state.searchKeyword) {
    //   handleChangeState('searchKeyword', value)
    // }
  }

  const callbackDeleteTemplate = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      toast.success('Success')
      dispatch(getListCategories())
    } else {
      handleCheckErrorMessage(message as string)
      toast.error('Have an issue when delete template. Try again!')
    }
  }

  const handleDelete = (): void => {
    handleChangeState('isOpenFormConfirm', false)
    deleteCategory(state.id, callbackDeleteTemplate)
  }

  const callback = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      dispatch(getListCategories())
      toast.success("Success!")
    } else {
      handleCheckErrorMessage(message as string)
      toast.error(message)
    }
  }

  const handleUpdateCategory = (data: unknown) => {
    handleChangeState('isOpenedUpdateCategoryModal', false)
    if (state.isCreated) {
      createCategory(data, callback)
    } else {
      updateCategory(data, callback)
    }
  }

  const showLoading: boolean = categoryManagement.loading

  return (
    <div className="user-management">
      <CustomSearchComponent handleSearch={handleSearch} name='Search with email and user name' />
      <div className="header">
        <h2 className="title">
          Categories management
        </h2>
        <div className='group-button'>
          <Button
            variant="contained"
            className="button justify-content-space-around mr-10"
            color="primary"
            onClick={() => dispatch(getListCategories())}
          >
            <p className="font-roboto text-white">
              Refresh data
          </p>
          </Button>
          <Button
            variant="contained"
            className="button justify-content-space-around"
            color="primary"
            onClick={() => {
              setState((prevState: CategoryContainerState) => ({
                ...prevState,
                isOpenedUpdateCategoryModal: true,
                isCreated: true
              }))
            }}
          >
            <p className="font-roboto text-white">
              Create category
          </p>
          </Button>
        </div>
      </div>
      <CustomTableContainer
        showLoading={showLoading}
        onClickTableRow={handleOpenModalFromUserRow}
        columns={columns}
        dataTable={state.listCategoriesTable}
        handleCheckColorText={handleCheckColorText}
        isHaveAvatar={false}
        onChangedFilter={handleChangeState}
        handleOrderTable={handleOrderListUsers}
        handleShowMoreActions={(anchorElement: HTMLElement, id: string) => {
          setAnchorElementCreateUser(anchorElement)
          handleChangeState('id', id)
        }}
        isShowMoreActions={true}
      />
      <UpdateCategoryModal
        isOpen={state.isOpenedUpdateCategoryModal}
        isCreated={state.isCreated}
        handleCloseModal={() => {
          handleChangeState('categoryDetail', {})
          handleChangeState('isOpenedUpdateCategoryModal', false)
        }}
        isLoading={showLoading}
        listOptionsCategories={state.listOptionsCategories}
        handleSave={handleUpdateCategory}
        categoryDetail={state.categoryDetail}
      />
      <CustomMenu
        anchorElement={anchorElementCreateUser}
        vertical={'center'}
        horizontal={'left'}
        listMenuItems={[{
          name: 'Delete',
        },]}
        handleCloseMenu={() => setAnchorElementCreateUser(null)}
        handleSubmit={() => {
          handleChangeState('isOpenFormConfirm', true)
          setAnchorElementCreateUser(null)
        }}
      />
      <ToastContainer />
      <ConfirmFormModal
        onCancel={() => handleChangeState('isOpenFormConfirm', false)}
        onYes={handleDelete}
        name={categories.filter(item => item.id === state.id)[0]?.title || ''}
        isOpen={state.isOpenFormConfirm}
      />
    </div>
  )
}