import React, { ReactElement } from 'react'
import './index.scss'
import { Dialog, Button, DialogContent } from '@material-ui/core'

interface ConfirmModalProps {
  onCancel: () => void
  isOpen: boolean
  onYes: () => void
  name: string
}

export const ConfirmFormModal: React.FC<ConfirmModalProps> = (props: ConfirmModalProps): ReactElement => {
  const { isOpen, onCancel, onYes, name } = props

  const handleCancel = () => {
    onCancel()
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="xs"
      className="confirm-modal"
    >
      <DialogContent dividers>
        <h3>Will you delete "{name}"</h3>
        <div className="action-modal">
          <Button onClick={handleCancel} color="primary" className="button">
            <p className="text">No</p>
          </Button>
          <Button
            variant="contained"
            className="button justify-content-space-around"
            onClick={onYes}
            color="primary"
          >
            <p className="text">Yes</p>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
