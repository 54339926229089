//@ts-nocheck
import React, { useState, useEffect, ReactElement, Fragment } from 'react'
import { ColumnItem, RowItem } from '../../../interfaces/common/table'
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Table,
  TableBody,
  Avatar,
  Button,
} from '@material-ui/core'
import { Images } from '../../../constants/images'
import { SkeletonTableRow } from '../Skeleton/TableRow'
import { EmptyResult } from '../EmptyResult'
import { Pagination } from '@material-ui/lab'

const { ThreeDots } = Images

const order: Order = {
  desc: 'desc',
  asc: 'asc',
}

interface CustomTableProps {
  columns: ColumnItem[]
  dataTable: RowItem[]
  showLoading?: boolean
  isShowMoreActions: boolean
  isHaveAvatar: boolean
  isShowPagination?: boolean
  page?: number
  totalPages?: number
  handleCheckColorText?: (value: string) => void
  onClickTableRow?: (id: string) => void
  handleOrderTable?: (orderBy: string, typeOrder: string) => void
  handleShowMoreActions?: (anchorElement: HTMLElement, id: string) => void
  onChangedPage?: (value: number) => void
}

interface CustomTableState {
  blackTexts: string[]
  alignLeftTexts: string[]
  descItems: string[]
  dataTable: RowItem[]
  listColumnNames: string[]
  keyUrl: string
  showLoading: boolean
}

interface Order {
  desc: 'desc'
  asc: 'asc'
}

export const CustomTable: React.FC<CustomTableProps> = (props: CustomTableProps): ReactElement => {
  const {
    columns,
    dataTable,
    showLoading,
    isShowMoreActions,
    isHaveAvatar,
    page,
    totalPages,
    isShowPagination,
    handleCheckColorText,
    onClickTableRow,
    handleOrderTable,
    handleShowMoreActions,
    onChangedPage,
  } = props

  const [state, setState] = useState({
    blackTexts: [] as string[],
    alignLeftTexts: [] as string[],
    descItems: [] as string[],
    dataTable: [] as RowItem[],
    listColumnNames: [] as string[],
    keyUrl: '',
    order: "asc",
    orderBy: "",
    showLoading: true,
  })

  const loading: boolean = showLoading || state.showLoading

  useEffect(() => {
    return () => {
      handleChangeState('dataTable', [])
    }
  }, [])

  useEffect(() => {
    if (columns) {
      const blackTexts: string[] = [],
        alignLeftTexts: string[] = [],
        listColumnNames: string[] = []

      props.columns.map((item: ColumnItem) => {
        listColumnNames.push(item.id)
        if (item.isBlack === true) blackTexts.push(item.id)
        if (item.align === 'left') alignLeftTexts.push(item.id)
      })

      setState((prevState: CustomTableState) => ({
        ...prevState,
        alignLeftTexts,
        blackTexts,
        dataTable,
        listColumnNames,
      }))
    }
  }, [columns])

  useEffect(() => {
    setState((prevState: CustomTableState) => ({
      ...prevState,
      showLoading: true,
      dataTable: undefined,
    }))
    if (dataTable && dataTable.length > 0) {
      let keyUrl = ''

      Object.keys(props.dataTable[0]).map((key: keyof RowItem) => {
        keyUrl = isUrlImage(props.dataTable[0][key]) ? key : keyUrl
      })

      setState((prevState: CustomTableState) => ({
        ...prevState,
        keyUrl,
        dataTable,
      }))
    } else {
      handleChangeState('dataTable', dataTable)
    }
  }, [JSON.stringify(dataTable)])

  useEffect(() => {
    handleChangeState('showLoading', false)
  }, [JSON.stringify(state.dataTable)])

  const _descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = (orderType: string, orderBy: string) => {
    return orderType === order.desc
      ? (a, b) => _descendingComparator(a, b, orderBy)
      : (a, b) => -_descendingComparator(a, b, orderBy);
  }

  const stableSort = (array: any[], comparator: (order: string, orderBy: string) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: CustomTableState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleCheckAlign = (key: string) => {
    if (state.alignLeftTexts.includes(key)) return 'left'
    return 'right'
  }

  const handleCheckColor = (item: RowItem, key: keyof RowItem) => {
    if (handleCheckColorText && handleCheckColorText(item[key])) return 'text-red'
    if (state.blackTexts.includes(key)) return 'text-dark-grey'
    return 'text-grey'
  }

  const handleSort = (value: string) => {
    let typeOrder
    const descItems = state.descItems
    const indexItem = descItems.indexOf(value)
    if (indexItem !== -1) {
      descItems.splice(indexItem, 1)
      typeOrder = order.asc
    } else {
      descItems.push(value)
      typeOrder = order.desc
    }
    handleOrderTable && handleOrderTable(value, typeOrder)
    setState((prevState: CustomTableState) => ({
      ...prevState,
      descItems,
      order: typeOrder,
      orderBy: value
    }))
  }
  const isUrlImage = (value: any): boolean => {
    if (value && value.toString().indexOf('//') !== -1) return true
    return false
  }

  const renderTableHead = (): ReactElement => {
    return (
      <TableHead>
        {loading ? (
          <SkeletonTableRow columns={columns} isHaveAvatar={isHaveAvatar} isShowMoreActions={isShowMoreActions} />
        ) : (
            renderDataHead()
          )}
      </TableHead>
    )
  }

  const renderDataHead = (): ReactElement => {
    return (
      <TableRow>
        {isHaveAvatar && (
          <TableCell component="th" width="50">
            <Avatar alt="avatar-column" className="hidden" />
          </TableCell>
        )}
        {columns &&
          columns.map((headCell: ColumnItem, index: number) => (
            <TableCell
              key={index}
              align={headCell.align}
              onClick={() => handleSort(headCell.id)}
              sortDirection={state.descItems.includes(headCell.id) ? order.desc : order.asc}
            >
              <TableSortLabel
                active={true}
                className="cell font-roboto"
                direction={state.descItems.includes(headCell.id) ? order.desc : order.asc}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        {isShowMoreActions && <TableCell padding="checkbox"></TableCell>}
      </TableRow>
    )
  }

  const renderTableBody = (): ReactElement => {

    return (
      <TableBody>
        {loading ? (
          <Fragment>
            <SkeletonTableRow columns={columns} isHaveAvatar={isHaveAvatar} isShowMoreActions={isShowMoreActions} />
            <SkeletonTableRow columns={columns} isHaveAvatar={isHaveAvatar} isShowMoreActions={isShowMoreActions} />
            <SkeletonTableRow columns={columns} isHaveAvatar={isHaveAvatar} isShowMoreActions={isShowMoreActions} />
            <SkeletonTableRow columns={columns} isHaveAvatar={isHaveAvatar} isShowMoreActions={isShowMoreActions} />
            <SkeletonTableRow columns={columns} isHaveAvatar={isHaveAvatar} isShowMoreActions={isShowMoreActions} />
          </Fragment>
        ) : (
            renderDataTable()
          )}
      </TableBody>
    )
  }

  const checkExistFieldNameInRowItem = (item: RowItem, fieldName: keyof RowItem): string => {
    return fieldName in item ? item[fieldName] : ''
  }

  const renderDataTable = (): ReactElement[] => {
    return (
      state.dataTable && stableSort(state.dataTable, getComparator(state.order, state.orderBy))
        .map((item: RowItem, index: number) => {
          return (
            <TableRow key={index} hover tabIndex={index}>
              {isHaveAvatar && (
                <TableCell width="50" component="th" onClick={() => onClickTableRow && onClickTableRow(item.id)}>
                  <Avatar alt={checkExistFieldNameInRowItem(item, 'firstName')} src={item.avatar} />
                </TableCell>
              )}
              {Object.keys(item).map((key: keyof RowItem, i: number): undefined | ReactElement => {
                if (key !== 'avatar' && state.listColumnNames.includes(key))
                  return (
                    <TableCell
                      key={i}
                      align={handleCheckAlign(key)}
                      className={handleCheckColor(item, key)}
                      onClick={() => onClickTableRow && onClickTableRow(item && item.id)}
                    >
                      {item[key]}
                    </TableCell>
                  )
              })}
              {isShowMoreActions && (
                <TableCell align="center" onClick={(event: React.MouseEvent<HTMLElement>) => handleShowMoreActions && handleShowMoreActions(event.currentTarget, item.id)}>
                  <Button>
                    <img src={ThreeDots} alt="three dots" />
                  </Button>
                </TableCell>
              )}
            </TableRow>
          )
        })
    )
  }

  const renderEmptyResult = (): ReactElement | undefined => {
    if (state.dataTable?.length === 0 && !loading) {
      return <EmptyResult />
    } else {
      return <Fragment />
    }
  }

  return (
    <Fragment>
      <TableContainer className="table-container">
        <Table className="content" aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
      {renderEmptyResult()}
      {isShowPagination && !loading && (
        <Pagination
          className="pagination-container"
          count={totalPages}
          page={page}
          onChange={(e: ChangeEvent<unknown>, value: number) => onChangedPage && onChangedPage(value)}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      )}
    </Fragment>
  )
}
