import { TextField, withStyles, Theme } from '@material-ui/core'

export const CustomMUITextField = withStyles((theme: Theme) => ({
  root: {
    '& label.Mui-focused': {
      color: `${theme.colors.MainGrey} !important`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${theme.colors.LightGrey1} !important`,
    },
    '& .Mui-error': {
      '& fieldset': {
        borderColor: `${theme.colors.Red} !important`,
        color: `${theme.colors.Red} !important`,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `${theme.colors.LightGrey1} !important`,
        border: '2px solid !important',
        borderRadius: '5px !important',
      },
      '&:hover fieldset': {
        borderColor: `${theme.colors.LightGrey1} !important`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${theme.colors.MainPink} !important`,
      },
    },
  },
}))(TextField)
