import { Images } from '../../../constants/images'
import { SideBarItem } from '../../../interfaces/common/menu'

export const DefaultMenus: SideBarItem[] = [
  {
    path: '/admin/users',
    name: 'Users management',
    activePath: 'users',
    icon: {
      active: Images.IconUserActive,
      inActive: Images.IconUser,
    },
  },
  {
    path: '/admin/templates',
    name: 'Templates management',
    activePath: 'templates',
    icon: {
      active: Images.IconTemplatesActive,
      inActive: Images.IconTemplates,
    },
  },
  {
    path: '/admin/categories',
    name: 'Categories management',
    activePath: 'categories',
    icon: {
      active: Images.IconTopicManagementActive,
      inActive: Images.IconTopicManagement,
    },
  },
  {
    path: '/admin/requests',
    name: 'Requests management',
    activePath: 'request',
    icon: {
      active: Images.IconEventActive,
      inActive: Images.IconEvent,

    },
  },
  {
    path: '/admin/notifications',
    name: 'Notifications management',
    activePath: 'notification',
    icon: {
      active: Images.NotificationActive,
      inActive: Images.Notification

    },
  },
  {
    path: '/admin/setting',
    name: 'Setting',
    activePath: 'setting',
    icon: {
      active: Images.IconSettingActive,
      inActive: Images.IconSetting,
    },
  },
]
