import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnItem } from '../../interfaces/common/table'
import { CustomSearchComponent } from '../../components/elements/CustomSearch'
import { CustomTableContainer } from '../../components/resources/CustomTableContainer'
import { NotificationInitialState, clearErrorNotification } from './slice'
import { NotificationRow, Notification } from '../../interfaces/notification'
import { getListNotifications, createNotification, updateNotification, deleteNotification } from '../../services/rest/notification.services'
import '../Template/index.scss'
import { Button } from '@material-ui/core'
import { CustomMenu } from '../../components/resources/CustomMenu'
import history from '../../utils/history'
import { removeCurrentUser, removeToken } from '../../utils/helper/common'
import { ConfirmFormModal } from '../../components/resources/ConfirmFormModal'
import { toast, ToastContainer } from 'react-toastify'
import { UpdateNotificationModal } from '../../components/resources/UpdateNotificationModal'
import { OptionSelect } from '../../interfaces/common/menu'
import { getListUsers } from '../../services/rest/user.services'
import { UserInitialState } from '../Users/slice'

const columns: ColumnItem[] = [
  { id: 'id', align: 'left', isBlack: true, label: 'Id' },
  { id: 'title', align: 'left', isBlack: true, label: 'Title' },
  { id: 'body', align: 'left', isBlack: false, label: 'Body' },
  { id: 'deeplink', align: 'left', isBlack: false, label: 'Deeplink' },
  { id: 'userName', align: 'left', isBlack: false, label: 'Username' },
  { id: 'createdDate', align: 'left', isBlack: false, label: 'Created at' },
]

interface NotificationContainerState {
  listNotificationsTable: NotificationRow[]
  isOpenedUpdateNotificationModal: boolean
  isOpenReasonForRejectionModal: boolean
  isCreated: boolean
  orderBy: string
  typeOrder: string
  loading: boolean
  notificationDetail: Notification
  searchKeyword: string
  isOpenFormConfirm: boolean
  listOptionsCategories: OptionSelect[]
  id: string
}

export const NotificationContainer = () => {
  const dispatch = useDispatch()
  const userManagement: UserInitialState = useSelector((state: any) => state.userManagement)
  const notificationManagement: NotificationInitialState = useSelector((state: any) => state.notificationManagement)
  const { notifications } = notificationManagement
  const { listUsers } = userManagement
  const [state, setState] = useState({
    listNotificationsTable: [] as NotificationRow[],
    isOpenedUpdateNotificationModal: false,
    isOpenReasonForRejectionModal: false,
    isCreated: false,
    orderBy: 'createdDate',
    typeOrder: 'ASC',
    loading: true,
    searchKeyword: '',
    notificationDetail: {} as Notification,
    isOpenFormConfirm: false,
    listOptionsCategories: [] as OptionSelect[],
    id: ''
  })
  const [anchorElementCreateUser, setAnchorElementCreateUser] = useState<null | HTMLElement>(null)

  useEffect(() => {
    dispatch(getListNotifications())
    dispatch(getListUsers())
  }, [])

  useEffect(() => {
    if (Object.keys(notificationManagement.error).length > 0) {
      handleCheckErrorMessage(notificationManagement.error?.message as string)
    }
  }, [notificationManagement.error])

  useEffect(() => {
    const listNotificationsTable = [] as NotificationRow[]
    notifications.forEach((item: Notification) => {
      const { id, title, body, deeplink, createdDate } = item
      const userDetail = listUsers.filter(user => user.id === item.userId)[0]
      const notify: NotificationRow = {
        id,
        title,
        body,
        deeplink,
        userName: item.typeNotification === 'inbox' ? userDetail?.username : '',
        createdDate,
      }
      listNotificationsTable.push(notify)
    })
    handleChangeState('listNotificationsTable', listNotificationsTable)
  }, [notifications, listUsers])

  const handleCheckErrorMessage = (message: string): void => {
    if (message?.includes("401")) {
      removeCurrentUser()
      removeToken()
      dispatch(clearErrorNotification())
      toast.error('Expried token!')
      history.push('/login')
    }
  }

  const findLastElementOfString = (value: string, typeSplit = '.'): string => {
    const arrElements: string[] = value.split(typeSplit)
    const index: number = arrElements.length - 1
    return arrElements[index]
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: NotificationContainerState) => ({
      ...prevState,
      [key]: value as Pick<NotificationContainerState, keyof NotificationContainerState>,
    }))
  }

  const handleOpenModalFromUserRow = (id: string) => {
    const notificationDetail = notifications.filter(item => { return item.id === id })[0]
    setState((prevState: NotificationContainerState) => ({
      ...prevState,
      isCreated: false,
      isOpenedUpdateNotificationModal: true,
      loading: true,
      notificationDetail
    }))
  }

  const handleOrderListUsers = (orderBy: string, typeOrder: string) => {
    setState((prevState: NotificationContainerState) => ({
      ...prevState,
      orderBy,
      typeOrder,
    }))
  }

  const handleCheckColorText = (value: string): boolean => {
    if (["false"].includes(findLastElementOfString(value ? value.toString() : ''))) return true
    return false
  }

  const handleSearch = (value: string) => {
    // if (value.length > 2 || validateNumber.test(value)) {
    //   handleChangeState('searchKeyword', value)
    // }
    // if (value.length === 0 && !!state.searchKeyword) {
    //   handleChangeState('searchKeyword', value)
    // }
  }

  const callbackDeleteNotification = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      toast.success('Success')
      dispatch(getListNotifications())
    } else {
      handleCheckErrorMessage(message as string)
      toast.error('Have an issue when delete notification. Try again!')
    }
  }

  const handleDelete = (): void => {
    handleChangeState('isOpenFormConfirm', false)
    deleteNotification(state.id, callbackDeleteNotification)
  }

  const callback = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      dispatch(getListNotifications())
      toast.success("Success!")
    } else {
      handleCheckErrorMessage(message as string)
      toast.error(message)
    }
  }

  const handleUpdateNotification = (data: unknown) => {
    handleChangeState('isOpenedUpdateNotificationModal', false)
    if (state.isCreated) {
      createNotification(data, callback)
    } else {
      updateNotification(data, callback)
    }
  }

  const showLoading: boolean = notificationManagement.loading

  return (
    <div className="user-management">
      <CustomSearchComponent handleSearch={handleSearch} name='Search with email and user name' />
      <div className="header">
        <h2 className="title">
          Notifications management
        </h2>
        <div className='group-button'>
          <Button
            variant="contained"
            className="button justify-content-space-around mr-10"
            color="primary"
            onClick={() => dispatch(getListNotifications())}
          >
            <p className="font-roboto text-white">
              Refresh data
          </p>
          </Button>
          <Button
            variant="contained"
            className="button justify-content-space-around"
            color="primary"
            onClick={() => {
              setState((prevState: NotificationContainerState) => ({
                ...prevState,
                isOpenedUpdateNotificationModal: true,
                isCreated: true
              }))
            }}
          >
            <p className="font-roboto text-white">
              Create notification
          </p>
          </Button>
        </div>
      </div>
      <CustomTableContainer
        showLoading={showLoading}
        onClickTableRow={handleOpenModalFromUserRow}
        columns={columns}
        dataTable={state.listNotificationsTable}
        handleCheckColorText={handleCheckColorText}
        isHaveAvatar={false}
        onChangedFilter={handleChangeState}
        handleOrderTable={handleOrderListUsers}
        handleShowMoreActions={(anchorElement: HTMLElement, id: string) => {
          setAnchorElementCreateUser(anchorElement)
          handleChangeState('id', id)
        }}
        isShowMoreActions={true}
      />
      <UpdateNotificationModal
        isOpen={state.isOpenedUpdateNotificationModal}
        isCreated={state.isCreated}
        handleCloseModal={() => {
          handleChangeState('isOpenedUpdateNotificationModal', false)
          handleChangeState('notificationDetail', {})
        }}
        isLoading={showLoading}
        listOptionsCategories={state.listOptionsCategories}
        handleSave={handleUpdateNotification}
        notificationDetail={state.notificationDetail}
      />
      <CustomMenu
        anchorElement={anchorElementCreateUser}
        vertical={'center'}
        horizontal={'left'}
        listMenuItems={[{
          name: 'Delete',
        },]}
        handleCloseMenu={() => setAnchorElementCreateUser(null)}
        handleSubmit={() => {
          handleChangeState('isOpenFormConfirm', true)
          setAnchorElementCreateUser(null)
        }}
      />
      <ToastContainer />
      <ConfirmFormModal
        onCancel={() => handleChangeState('isOpenFormConfirm', false)}
        onYes={handleDelete}
        name={notifications.length > 0 ? notifications.filter(item => item.id === state.id)[0]?.title : ''}
        isOpen={state.isOpenFormConfirm}
      />
    </div>
  )
}