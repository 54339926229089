import { CallApi, uploadImageApi } from '../api'
import {
  startGetCategories,
  successGetCategories,
  failRequestApiFromCategoriesSlice,
} from '../../containers/Category/slice'

export const getListCategories = (): ((dispatch: any) => Promise<void>) => {
  return async (dispatch) => {
    dispatch(startGetCategories())
    try {
      const res = await CallApi({
        endpoint: 'category',
        method: 'GET',
      })
      dispatch(successGetCategories(res))
    } catch (err) {
      dispatch(failRequestApiFromCategoriesSlice(err))
    }
  }
}

export const createCategory = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: 'category',
      method: 'POST',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const updateCategory = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `category/${(data as Record<string, unknown>).id}`,
      method: 'PUT',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const deleteCategory = async (id: string, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `category/${id}`,
      method: 'DELETE',
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const updateVersion = async (): Promise<string | undefined> => {
  try {
    await CallApi({
      endpoint: 'category/update-version',
      method: 'POST',
    })
  } catch (err) {
    return err.message
  }
}