import { accessToken, currentUser } from '../../../constants/variables'
import { LoginResponseEntity } from '../../../interfaces/login'

export const setToken = (token: string): void => {
  localStorage.setItem(accessToken, token)
}

export const setCurrentUser = (data: LoginResponseEntity): void => {
  localStorage.setItem(currentUser, JSON.stringify(data))
}

export const getToken = (): string | null => {
  return localStorage.getItem(accessToken)
}

export const getCurrentUser = (): string | null => {
  return localStorage.getItem(currentUser)
}

export const removeToken = (): void => {
  localStorage.removeItem(accessToken)
}

export const removeCurrentUser = (): void => {
  localStorage.removeItem(currentUser)
}

export const checkValue = (params: Record<string, unknown>, data: Record<string, unknown>) => {
  let result: Record<string, unknown> = params
  Object.keys(data).map((item: string) => {
    if (data[item] !== '') {
      result = {
        ...result,
        [item]: data[item],
      }
    }
  })
  return result
}