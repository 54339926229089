import { createSlice } from '@reduxjs/toolkit'
import { Product } from '../../../../interfaces/product'

export interface ProductInitialState {
  loading: boolean
  products: Product[]
  error: Error
}

const initialState: ProductInitialState = {
  loading: false,
  products: [] as Product[],
  error: {} as Error,
}

const ProductSlice = createSlice({
  name: 'triggerProduct',
  initialState,
  reducers: {
    startGetListProducts: (state) => {
      state.loading = true
      state.products = []
      state.error = {} as Error
    },
    successGetListProducts: (state, { payload }) => {
      state.loading = false
      state.products = payload.data
    },
    startDeleteProduct: (state) => {
      state.loading = true
      state.products = []
      state.error = {} as Error
    },
    successDeleteProduct: (state, { payload }) => {
      state.loading = false
      state.products = payload.data
    },
    failRequestApiFromProductSlice: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clearErrorProduct: (state) => {
      state.error = {} as Error
    }
  },
})

const { actions, reducer } = ProductSlice
export const {
  startGetListProducts,
  successGetListProducts,
  startDeleteProduct,
  successDeleteProduct,
  clearErrorProduct,
  failRequestApiFromProductSlice,
} = actions

export default reducer
