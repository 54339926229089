import React, { ReactElement } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import './index.scss'

interface CustomLoadingProps {
  className?: string
}

export const CustomLoading: React.FC<CustomLoadingProps> = (props: CustomLoadingProps): ReactElement => {
  const { className } = props

  return (
    <Grid className={className || 'loading'}>
      <CircularProgress color="primary" />
    </Grid>
  )
}
