import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from '../reducers'

const middleware = [
  ...getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
  }),
]

export const store = configureStore({
  reducer: rootReducer,
  middleware,
})