import { createSlice } from '@reduxjs/toolkit'
import { UserData } from '../../interfaces/user'

export interface UserInitialState {
  loading: boolean
  listUsers: UserData[]
  totalPages: number,
  page: number,
  error: Error
}

const initialState: UserInitialState = {
  loading: false,
  listUsers: [] as UserData[],
  totalPages: 0,
  page: 0,
  error: {} as Error,
}

const UserSlice = createSlice({
  name: 'triggerUser',
  initialState,
  reducers: {
    startGetListUsers: (state) => {
      state.loading = true
      state.listUsers = []
      state.totalPages = 0
      state.page = 0
      state.error = {} as Error
    },
    successGetListUsers: (state, { payload }) => {
      state.loading = false
      if (payload.data.users) {
        state.listUsers = payload.data.users
        state.page = payload.data.page
        state.totalPages = payload.data.totalPages
      } else {
        state.listUsers = payload.data
      }
    },
    startDeleteUser: (state) => {
      state.loading = true
      state.listUsers = []
      state.error = {} as Error
    },
    successDeleteUser: (state, { payload }) => {
      state.loading = false
      state.listUsers = payload.data
    },
    failRequestApiFromUserSlice: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clearErrorUser: (state) => {
      state.error = {} as Error
    }
  },
})

const { actions, reducer } = UserSlice
export const {
  startGetListUsers,
  successGetListUsers,
  startDeleteUser,
  successDeleteUser,
  clearErrorUser,
  failRequestApiFromUserSlice,
} = actions

export default reducer
