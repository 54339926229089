import React, { ReactElement, useState, useEffect, ChangeEvent, MouseEvent } from 'react'
import { Grid, Button, Dialog } from '@material-ui/core'
import { CustomTextField } from '../../elements/CustomTextField'
import { Images } from '../../../constants/images'
import { OptionSelect } from '../../../interfaces/common/menu'
import 'antd/dist/antd.css';
import '../UpdateTemplateModal/index.scss'
import { toast, ToastContainer } from 'react-toastify'
import { Category } from '../../../interfaces/category'

interface UpdateCategoryModalProps {
  handleCloseModal: () => void
  isOpen: boolean
  isLoading: boolean
  listOptionsCategories: OptionSelect[]
  isCreated: boolean
  categoryDetail: Category
  handleSave: (data: unknown) => void
}

export const UpdateCategoryModal: React.FC<UpdateCategoryModalProps> = (props: UpdateCategoryModalProps): ReactElement => {
  const { isOpen, isLoading, handleCloseModal, listOptionsCategories, isCreated, handleSave, categoryDetail } = props

  const [categoryData, setCategoryData] = useState({
    title: '',
    priority: 0
  })

  useEffect(() => {
    if (isCreated) {
      resetData()
    } else {
      handleChangeCategoryData('title', categoryDetail?.title || '')
      setCategoryData({
        title: categoryDetail?.title || '',
        priority: categoryDetail?.priority || 0
      })
    }
  }, [isCreated, categoryDetail])

  const resetData = () => {
    setCategoryData({
      title: '',
      priority: 0
    })
  }

  const handleChangeCategoryData = (key: string, value: string): void => {
    if (key === 'priority') {
      setCategoryData(prevData => ({
        ...prevData,
        priority: parseInt(value.match(/\d+/g) as unknown as string)
      }))
    } else {
      setCategoryData(prevData => ({
        ...prevData,
        [key]: value
      }))
    }
  }

  const handleSaveModal = (): void => {
    let data = {
      ...categoryData,
    }
    if (!isCreated) {
      data = {
        ...categoryDetail,
        ...categoryData
      }
    }
    handleSave(data)
    resetData()
    handleCloseModal()
  }

  const renderContent = (): ReactElement => {
    return (
      <Grid container spacing={0} direction="column">
        <Grid item lg={12} className="header mb-70">
          <h4 className="title" style={{ textAlign: 'center' }}>Category</h4>
        </Grid>
        <div className="content">
          <Grid container className="row" spacing={4}>
            <Grid item lg={12} className="row">
              <Grid item lg={12} md={12} sm={6} xs={12}>
                <h4 className="title mb-10">Category information</h4>
                <CustomTextField
                  label="Title"
                  value={categoryData.title}
                  onChanged={handleChangeCategoryData}
                  fieldName="title"
                  className="text-field mb-50 "
                />
                <CustomTextField
                  label="Priority"
                  value={categoryData.priority.toString()}
                  onChanged={handleChangeCategoryData}
                  fieldName="priority"
                  className="text-field mb-50 "
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} className="footer">
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Button variant="outlined" className="button" color="secondary" onClick={() => {
              resetData()
              handleCloseModal()
            }}>
              <p className="text-pink text-transform-normal">
                Back
              </p>
            </Button>
          </Grid>
          <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
            <Grid item xs={6}>
              <Button
                variant="contained"
                className="button"
                color="primary"
                onClick={() => handleSaveModal()}
              >
                <p className="text-white text-transform-normal">
                  Save
                </p>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Button variant="contained" className="button-close" onClick={() => {
          resetData()
          handleCloseModal()
        }}>
          <img src={Images.IconClose} alt="Icon close" />
        </Button>
      </Grid>
    )
  }

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
      onClose={handleCloseModal}
      className="update-user-modal"
    >
      {renderContent()}
      <ToastContainer />
    </Dialog>
  )
}
