import { RouteProps } from 'react-router'
import { links } from './links'
import { UsersContainer } from '../containers/Users'
import { TemplateContainer } from '../containers/Template'
import { CategoryContainer } from '../containers/Category'
import { RequestContainer } from '../containers/Request'
import { SettingContainer } from '../containers/Setting'
import { NotificationContainer } from '../containers/Notification'
import { LandingPage } from '../containers/Landing'

export const routes: RouteProps[] = [
  { path: links.users(), component: UsersContainer },
  { path: links.templates(), component: TemplateContainer },
  { path: links.categories(), component: CategoryContainer },
  { path: links.requests(), component: RequestContainer },
  { path: links.setting(), component: SettingContainer },
  { path: links.notifications(), component: NotificationContainer },
  { path: links.landing(), component: LandingPage },
]
