import React, { ReactElement } from 'react'
import { Menu } from '@material-ui/core'
import { CustomMenuItem } from '../../elements/CustomMenuItem'
import { OptionMenuItem } from '../../../interfaces/common/menu'

interface CustomMenuProps {
  anchorElement: null | HTMLElement
  listMenuItems?: OptionMenuItem[]
  vertical?: 'top' | 'center'
  horizontal?: 'left' | 'center'
  handleCloseMenu: () => void
  handleSubmit: (name: string) => void
}

export const CustomMenu: React.FC<CustomMenuProps> = (props: CustomMenuProps): ReactElement => {
  const { anchorElement, listMenuItems, handleCloseMenu, handleSubmit, vertical, horizontal } = props

  return (
    <Menu
      id="customized-menu"
      variant="selectedMenu"
      keepMounted
      open={Boolean(anchorElement)}
      getContentAnchorEl={null}
      elevation={0}
      anchorEl={anchorElement}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: vertical || 'bottom',
        horizontal: horizontal || 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {listMenuItems &&
        listMenuItems.map((item: OptionMenuItem, index: number) => {
          return (
            <CustomMenuItem
              key={index}
              handleSubmit={() => handleSubmit(item.name)}
              className={item.className}
              icon={item.icon}
              name={item.name}
            />
          )
        })}
    </Menu>
  )
}
