import React, { useEffect, useState, Fragment, ChangeEvent } from 'react'
import { Grid, Dialog, Button, } from '@material-ui/core'
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import { toast, ToastContainer } from 'react-toastify'
import '../UpdateTemplateModal/index.scss'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Moment from "moment";
import { extendMoment } from "moment-range";
import { CallApi } from '../../../services/api';
import { CustomMUITextField } from '../../elements/CustomMUI/CustomMUITextField'
import { Request } from '../../../interfaces/request'
import { UserInitialState } from '../../../containers/Users/slice'
import { useSelector } from 'react-redux'
import { TemplateInitialState } from '../../../containers/Template/slice'
import { CSVLink } from "react-csv";
import './index.scss'

const moment = extendMoment(Moment as any);
const statusOptions = ['ACCEPTED', 'PENDING', 'INPROCESS', 'COMPLETED']

export const ExportModal = ({ isOpen, handleCloseModal }:
  { isOpen: boolean, handleCloseModal: () => void }) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.toDateString();
  const userManagement: UserInitialState = useSelector((state: any) => state.userManagement)
  const templateManagement: TemplateInitialState = useSelector((state: any) => state.templateManagement)
  const { listUsers } = userManagement
  const { templates } = templateManagement
  const [selectedDateFrom, setSelectedDateFrom] = useState(yesterday);
  const [selectedDateTo, setSelectedDateTo] = useState(yesterday);
  const [status, setStatus] = useState("ACCEPTED")
  const [requests, setRequests] = useState([] as Request[])
  const [dataExcel, setDataExcel] = useState<string[][]>([])

  const resetData = () => {
    setSelectedDateFrom(yesterday)
    setSelectedDateTo(yesterday)
    setStatus("ACCEPTED")
    setDataExcel([])
  }

  useEffect(() => {
    const getRequests = async () => {
      const rs = await CallApi({
        endpoint: `request`,
        method: 'GET',
      })
      setRequests(rs.data)
    }
    if (isOpen) {
      resetData()
      getRequests()
    }
  }, [isOpen])

  useEffect(() => {
    const listRequestsTable = requests.
      filter((item: Request) => moment().range(selectedDateFrom, selectedDateTo).contains(moment(item.createDate).utc()) && item.status === status)
      .map(item => {
        const userDetail = listUsers.filter((user) => user.id === item.userId)[0]
        const templateDetail = templates.filter(template => template.id === item.templateId)[0]
        return {
          id: item.id,
          createDate: moment(item.createDate).format('DD/MM/YYYY').toString(),
          username: userDetail?.username || '',
          email: userDetail?.email || '',
          vip: userDetail?.vip as unknown as string || '',
          language: userDetail?.language || '',
          custume: item.isCustume ? "Customize" : templateDetail?.title,
          custumeImage: templateDetail?.custumeImage || '',
          customize: item?.custumeImages?.join('\n') || '',
          userImages: item?.images.join('\n') || '',
          note: item?.note || '',
          petname: item?.petname || '',
          status: item.status,
        }
      })
    const excelData = [
      [
        'ID',
        'Create at',
        'User name',
        'User email',
        'VIP',
        'Language',
        'Title',
        'Custume image',
        'Customize images',
        'User image',
        'Note',
        'Pet name',
        'Status'
      ]
    ].concat(
      listRequestsTable.map(item => { return Object.values(item) }))
    setDataExcel(excelData)
  }, [selectedDateTo, selectedDateFrom, status])
  const handleDateFrom = (date: any) => {
    if (moment(date).isAfter(selectedDateTo)) {
      setSelectedDateTo(date)
    }
    setSelectedDateFrom(date);
  };

  const handleDateTo = (date: any) => {
    if (moment(date).isBefore(selectedDateFrom)) {
      setSelectedDateFrom(date)
    }
    setSelectedDateTo(date);
  };



  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleCloseModal}
      className="update-user-modal"
    >
      <Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className='export-body'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date from"
                format="dd/MM/yyyy"
                value={selectedDateFrom}
                onChange={handleDateFrom}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date to"
                format="dd/MM/yyyy"
                value={selectedDateTo}
                onChange={handleDateTo}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6} className='status-field' style={{ margin: '10px auto' }}>
          <Autocomplete
            id="status"
            className="text-field mb-50"
            options={statusOptions}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option || ''}
            value={status}
            disableClearable
            onChange={(event: ChangeEvent<Record<string, unknown>>, newValue) => setStatus(newValue)}
            renderOption={(option) => <Fragment>{option}</Fragment>}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <CustomMUITextField
                {...params}
                label="Status"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="footer" style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Button variant="outlined" className="button" color="secondary" onClick={() => {
            resetData()
            handleCloseModal()
          }}>
            <p className="text-pink text-transform-normal">
              Back
              </p>
          </Button>
        </Grid>
        <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
          <Grid item xs={6}>
            <CSVLink data={dataExcel as string[][]} filename='Requests'>
              <Button
                variant="contained"
                className="button"
                color="primary"
                onClick={() => {
                  handleCloseModal()
                }}
              >
                <p className="text-white text-transform-normal">
                  Save
                </p>
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
      </Grid>
      <ToastContainer />
    </Dialog>
  )

}