import React, { ReactElement, useState, useEffect } from 'react'
import { Grid, Button, Dialog, AppBar, Tabs, Tab } from '@material-ui/core'
import { CustomLoading } from '../../elements/CustomLoading'
import 'antd/dist/antd.css';
import '../UpdateTemplateModal/index.scss'
import { toast, ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'
import { UserData } from '../../../interfaces/user'
import { TabPanel, a11yProps } from '../../elements/TablePanel'
import { CustomTableContainer } from '../CustomTableContainer';
import { ColumnItem, RowItem } from '../../../interfaces/common/table';
import { RequestInitialState } from '../../../containers/Request/slice';
import { Request } from '../../../interfaces/request';
import { CustomTextField } from '../../elements/CustomTextField';

interface UpdateCoinModalProps {
  handleCloseModal: () => void
  handleAddCoin: (id: string, value: number) => void
  handleSubCoin: (id: string, value: number) => void
  isOpen: boolean
  isLoading: boolean
  userDetail: UserData
}

const columns: ColumnItem[] = [
  { id: 'id', align: 'left', isBlack: true, label: 'ID' },
  { id: 'createDate', align: 'left', isBlack: false, label: 'Created' },
]

export const UpdateCoinModal: React.FC<UpdateCoinModalProps> = (props: UpdateCoinModalProps): ReactElement => {
  const {
    isOpen,
    handleAddCoin,
    handleSubCoin,
    handleCloseModal,
    userDetail,
  } = props

  const requestManagement: RequestInitialState = useSelector((state: any) => state.requestManagement)

  const [state, setState] = useState({
    loading: false,
    usersInfo: {} as UserData,
    page: 1,
    listRequests: [] as Request[],
  })
  const [totalPages, setTotalPages] = useState(0)
  const [value, setValue] = useState(0)
  const [addCoin, setAddCoin] = useState(0)
  const [subCoin, setSubCoin] = useState(0)
  
  useEffect(() => {
    if (isOpen && userDetail.id) {
      const userRequest = requestManagement.requests.filter(item => item.userId === userDetail.id)
      const requestsLength = userRequest.length
      const listRequests = userRequest.slice(10 * (state.page - 1), 10 * state.page)
      setState(prevData => ({
        ...prevData,
        listRequests,
      }))
      setTotalPages(~~(requestsLength / 10) + ((requestsLength % 10) > 0 ? 1 : 0))
    } else {
      setAddCoin(0)
      setSubCoin(0)
    }
  }, [isOpen, JSON.stringify(userDetail), JSON.stringify(requestManagement.requests), state.page])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeState = (key: string, value: unknown): void => {
    setState(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const resetData = () => {
    setValue(0)
  }

  const renderContent = (): ReactElement => {
    return (
      <div className='header-tabs'>
        <AppBar position="static" className='appBar'>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className='tabs'>
            <Tab label="Requests" {...a11yProps(0)} />
            <Tab label="Change coins" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <h4 className="title mb-10">List request per user</h4>
              <CustomTableContainer
                showLoading={false}
                columns={columns}
                dataTable={state.listRequests as unknown as RowItem[]}
                isShowPagination
                page={state.page}
                totalPages={totalPages}
                onChangedPage={(value) => handleChangeState('page', value)}
                isShowMoreActions={false}
                isHaveAvatar={false}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container item lg={6} md={6} sm={6}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <h4 className="title mb-10">Change Coins</h4>
              <Grid container spacing={1}>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <CustomTextField
                    label="Sub"
                    value={subCoin.toString()}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onChanged={(_: any, value: string) => setSubCoin(parseInt(value) || 0)}
                    fieldName="title"
                    className="text-field mb-50 "
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Button
                    onClick={() => handleSubCoin(userDetail.id, subCoin)}
                    color="secondary"
                    className="button"
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #D75F8D",
                    }}
                  >
                    <p className="text-pink">Sub</p>
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <CustomTextField
                    label="Add"
                    value={addCoin.toString()}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onChanged={(_: any, value: string) => setAddCoin(parseInt(value) || 0)}
                    fieldName="title"
                    className="text-field mb-50 "
                  />                  
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Button
                    onClick={() => handleAddCoin(userDetail.id, addCoin)}
                    color="primary"
                    className="button"
                    style={{
                      background: "#D75F8D",
                      border: "1px solid #D75F8D"
                    }}
                  >
                    <p className="text-white">Add</p>
                  </Button>
                </Grid> 
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <h4 className="title mb-10">Change Coins</h4>
              {userDetail.logCoins?.map((item, index) => <p key={index}>{item}</p>)}
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    )
  }

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleCloseModal}
      className="update-user-modal"
    >
      {state.loading && <CustomLoading className="loading-modal" />}
      {renderContent()}
      <Grid container spacing={2} className="footer">
        <Grid item lg={3} md={3} sm={3} xs={3} style={{ paddingLeft: 30, marginBottom: 20 }}>
          <Button variant="outlined" className="button" color="secondary" onClick={() => {
            resetData()
            handleCloseModal()
          }}>
            <p className="text-pink text-transform-normal">
              Back
              </p>
          </Button>
        </Grid>
      </Grid>
      <ToastContainer />
    </Dialog>
  )
}
