import { CallApi } from '../api'
import {
  startGetListReviews,
  successGetListReviews,
  startDeleteReview,
  successDeleteReview,
  failRequestApiFromReviewSlice,
} from '../../components/resources/UpdateTemplateModal/FakeReviews/slice'

export const getReviews = (templateId: string): ((dispatch: any) => Promise<void>) => {
  return async (dispatch) => {
    dispatch(startGetListReviews())
    try {
      const res = await CallApi({
        endpoint: `review/${templateId}`,
        method: 'GET',
      })
      dispatch(successGetListReviews(res))
    } catch (err) {
      dispatch(failRequestApiFromReviewSlice(err))
    }
  }
}

export const deleteReview = async (id: string, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    await CallApi({
      endpoint: `review/${id}`,
      method: 'DELETE',
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const createReview = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    await CallApi({
      endpoint: 'review/create',
      method: 'POST',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const updateReview = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    await CallApi({
      endpoint: `review/${(data as Record<string, unknown>).id}`,
      method: 'PUT',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}