import React, { ReactElement } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import './index.scss'

interface SkeletonRegistrationProps {
  className?: string
}

export const SkeletonRegistration: React.FC<SkeletonRegistrationProps> = (
  props: SkeletonRegistrationProps,
): ReactElement => {
  return (
    <div className={`${props.className} skeleton-registration`}>
      <Skeleton variant="text" className="title-registration" />
      <Skeleton variant="text" className="title-step" />
      <Skeleton variant="text" className="text" />
      <Skeleton variant="text" className="footer" />
    </div>
  )
}
