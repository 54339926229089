import React, { useState } from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import { CustomMUIFormControl } from '../CustomMUI/CustomMUIFormControl'
import { OptionSelect } from '../../../interfaces/common/menu'
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab'
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete'
import './index.scss'
import { Images } from '../../../constants/images'

export type SearchableSelectProps = {
  value: string | number
  options: OptionSelect[]
  onChange: (fieldName: string, value: string | number) => void
  onInputChange?: (value: string, reason: AutocompleteInputChangeReason) => void
  fieldName?: string
  disabled?: boolean
  required?: boolean
  freeSolo?: boolean
  freeSoloApplyWithoutEnter?: boolean
  autoSelect?: boolean
  className?: string
  label?: string
  loading?: boolean
  id?: string
  textFieldVariant?: 'filled' | 'outlined' | 'standard'
  disableClearable?: boolean
  getTranslatedOption?: (option: OptionSelect) => string
  showSearchIcon?: boolean
  selectOnFocus?: boolean
  error?: boolean
  getOptionSelected?: (option: any, value: any) => boolean
  inputValue?: string
}

export const SearchableSelect = (props: SearchableSelectProps): JSX.Element => {
  let { options } = props
  const {
    value,
    onChange,
    fieldName = '',
    disabled = false,
    required = false,
    freeSolo = false,
    freeSoloApplyWithoutEnter = false,
    autoSelect = false,
    className = '',
    label = '',
    loading = false,
    id = undefined,
    textFieldVariant = 'outlined',
    disableClearable = freeSolo,
    showSearchIcon = false,
    selectOnFocus = false,
    getTranslatedOption = (option) => option.content,
    error = false,
    onInputChange,
    getOptionSelected,
    inputValue,
  } = props
  const [freeSoloValue, setFreeSoloValue] = useState(value)

  // this is when the underlying value is actually changing
  const onAutocompleteChange = createAutocompleteChangeHandler({ fieldName, setFreeSoloValue, onChange })

  // called whenever someone types into the input field, typing doesn't necessary mean that the underlying
  // value is changing immediately, sometimes you have to press enter first
  const onTextFieldChange =
    onInputChange ??
    createTextFieldChangeHandler({
      freeSoloApplyWithoutEnter,
      freeSolo,
      setFreeSoloValue,
      onAutocompleteChange,
    })

  const getOptionLabel = createOptionLabelTranslator(getTranslatedOption)

  // add a custom option for free text, if there is a value
  if (freeSolo && freeSoloValue) {
    options = [{ value: freeSoloValue, content: freeSoloValue.toString() }, ...options]
  }

  const selectedOption = options.find((option) => option.value === value) || null

  return (
    <div className="searchable-select">
      <CustomMUIFormControl variant="outlined" required={required} className={className}>
        <Autocomplete
          id={id}
          value={selectedOption || ''}
          filterOptions={getFilteredOptions}
          getOptionSelected={getOptionSelected}
          options={USE_FAKE_OPTIONS ? FAKE_OPTIONS : options}
          loading={loading}
          disabled={disabled || loading}
          getOptionLabel={getOptionLabel}
          onChange={(_, option, reason) => onAutocompleteChange(option as OptionSelect, reason)}
          onInputChange={(_, value, reason) => onTextFieldChange(value, reason)}
          inputValue={inputValue}
          disableClearable={disableClearable}
          freeSolo={freeSolo}
          fullWidth
          clearOnBlur
          clearOnEscape
          selectOnFocus={selectOnFocus}
          autoSelect={autoSelect}
          renderOption={(option: OptionSelect, { inputValue }) => {
            const parts = parseAndHighlightText(option.content, inputValue)

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={fieldName}
              name={fieldName}
              label={!showSearchIcon ? label || null : undefined}
              InputLabelProps={{ required: required }}
              variant={textFieldVariant}
              error={error}
              InputProps={
                showSearchIcon
                  ? {
                    ...params.InputProps,
                    placeholder: label || undefined,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Images.IconSearch} className="icon-search" />
                      </InputAdornment>
                    ),
                  }
                  : params.InputProps
              }
            />
          )}
        />
      </CustomMUIFormControl>
    </div>
  )
}

const getFilteredOptions = (options: OptionSelect[], state: FilterOptionsState<OptionSelect>): OptionSelect[] => {
  // no need to filter anything, since the search query is empty
  if (!state.inputValue) {
    return options.slice(0, VISIBLE_OPTIONS_IN_DROPDOWN_LIMIT)
  }

  const searchQuery = state.inputValue.toLowerCase()
  const filteredOptions: OptionSelect[] = []

  // find first N options that match the search query
  for (let i = 0; i < options.length; i++) {
    const option = options[i]
    const optionLabel = state.getOptionLabel(option).toLowerCase()

    // very simple comparison to match the search query
    if (optionLabel.includes(searchQuery)) {
      filteredOptions.push(option)
    }

    // make sure you do not filter more than necessary, this is important for responsiveness
    if (filteredOptions.length >= VISIBLE_OPTIONS_IN_DROPDOWN_LIMIT) {
      break
    }
  }

  return filteredOptions
}

const createOptionLabelTranslator = (translator: (option: OptionSelect) => string) => (
  option?: OptionSelect | string,
) => {
  if (typeof option === 'string') {
    return option
  }

  if (option) {
    return translator(option)
  }

  return ''
}

const createAutocompleteChangeHandler = ({
  fieldName,
  setFreeSoloValue,
  onChange,
}: {
  fieldName: string
  setFreeSoloValue: (value: string | number) => void
  onChange: (field: string, value: string | number) => void
}) => (option: OptionSelect | string, reason: AutocompleteChangeReason) => {
  if (reason === 'clear') {
    setFreeSoloValue('')
    onChange(fieldName, '')
  } else {
    if (typeof option === 'string') {
      setFreeSoloValue(option)
      onChange(fieldName, option)
    } else {
      setFreeSoloValue(option.value)
      onChange(fieldName, option.value)
    }
  }
}

const createTextFieldChangeHandler = ({
  freeSoloApplyWithoutEnter,
  freeSolo,
  setFreeSoloValue,
  onAutocompleteChange,
}: {
  freeSoloApplyWithoutEnter: boolean
  freeSolo: boolean
  setFreeSoloValue: (value: string | number) => void
  onAutocompleteChange: (option: OptionSelect | string, reason: AutocompleteChangeReason) => void
}) => (newValue: string, reason: AutocompleteInputChangeReason) => {
  if (reason === 'input' && freeSolo) {
    if (!freeSoloApplyWithoutEnter) {
      setFreeSoloValue(newValue)
    } else {
      onAutocompleteChange(newValue, 'select-option')
    }
  }
}

type TextToken = {
  text: string
  highlight: boolean
}

const parseAndHighlightText = (text: string, search: string): TextToken[] => {
  if (!search.length || !text.length) {
    return [
      {
        highlight: false,
        text,
      },
    ]
  }

  const regex = new RegExp(search, 'gi')
  const matches: number[][] = []

  while (regex.exec(text)) {
    matches.push([regex.lastIndex - search.length, regex.lastIndex])
  }

  // simply return the text if there were no matches
  if (matches.length === 0) {
    return [
      {
        highlight: false,
        text,
      },
    ]
  }

  const tokens: TextToken[] = []

  matches.forEach((match, index) => {
    if (index === 0) {
      const firstToken = text.substring(0, match[0])

      if (firstToken.length > 0) {
        tokens.push({
          highlight: false,
          text: firstToken,
        })
      }
    }

    const currentToken = text.substring(match[0], match[1])

    tokens.push({
      highlight: true,
      text: currentToken,
    })

    const nextMatch = matches[index + 1] || [text.length, -1]
    const nextToken = text.substring(match[1], nextMatch[0])

    if (nextToken.length > 0) {
      tokens.push({
        highlight: false,
        text: nextToken,
      })
    }
  })

  return tokens
}

const GENERATE_FAKE_DATA = (): OptionSelect[] => {
  const labels = ['Yolo', 'Swag', 'Foo', 'Bar', 'TypeScript']
  const numberOfItems = 10 ** 4 // 10k

  return new Array(numberOfItems).fill(null).map((_, i) => {
    const label = labels[Math.floor(Math.random() * labels.length)]

    return { value: i, content: `${label} ${i}` }
  })
}

// enable fake data for performance testing
const USE_FAKE_OPTIONS = false
// reasonable amount of items that are visible in the dropdown, lower is better for performance
const VISIBLE_OPTIONS_IN_DROPDOWN_LIMIT = 250
const FAKE_OPTIONS = USE_FAKE_OPTIONS ? GENERATE_FAKE_DATA() : []
