import React, { ReactElement, Fragment, ChangeEvent } from 'react'
import { CustomTable } from '../../elements/CustomTable'
import { OptionSelect } from '../../../interfaces/common/menu'
import { CustomMUITextField } from '../../elements/CustomMUI/CustomMUITextField'
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import { SelectedFilterEntity, ColumnItem, RowItem } from '../../../interfaces/common/table'
import { SearchableSelect } from '../../elements/SearchableSelect'
import './index.scss'

interface CustomTableContainerProps {
  columns: ColumnItem[]
  dataTable: RowItem[]
  valueFilters?: string[]
  listDataSelectedFilters?: SelectedFilterEntity[]
  isHaveFilter?: boolean
  showLoading?: boolean
  isShowMoreActions: boolean
  isHaveAvatar: boolean
  isShowPagination?: boolean
  page?: number
  totalPages?: number
  onChangedPage?: (value: number) => void
  handleCheckColorText?: (value: string) => void
  onClickTableRow?: (id: string) => void
  onChangedFilter?: (key: string, value: string | number) => void
  handleOrderTable?: (ordeBy: string, typeOrder: string) => void
  handleShowMoreActions?: (anchorElement: HTMLElement, id: string) => void
}

export const CustomTableContainer: React.FC<CustomTableContainerProps> = (
  props: CustomTableContainerProps,
): ReactElement => {
  const {
    columns,
    dataTable,
    isHaveAvatar,
    isHaveFilter,
    showLoading,
    isShowMoreActions,
    page,
    valueFilters,
    totalPages,
    isShowPagination,
    listDataSelectedFilters,
    handleCheckColorText,
    onClickTableRow,
    handleShowMoreActions,
    handleOrderTable,
    onChangedPage,
    onChangedFilter,
  } = props

  const filterDataSelected = (data: OptionSelect[], value: string): OptionSelect => {
    return data.filter((item: OptionSelect) => item.value === value)[0]
  }

  return (
    <div className="custom-table">
      {isHaveFilter && (
        <div className="filter">
          <span className="filter-title">
            Filter by:
          </span>
          {listDataSelectedFilters?.map((item: SelectedFilterEntity, index: number) => {
            const value: string = (valueFilters && valueFilters[index]) || ''
            const dataSelected: OptionSelect = filterDataSelected(item.data, value)

            const onChange = (fieldName: string, value: string | number) => {
              onChangedFilter && onChangedFilter(fieldName, value || '')
            }

            const getTranslatedOption = (option: OptionSelect) => {
              const nameSpace: string = item.nameSpace ? item.nameSpace + '.' : ''

              return `${nameSpace}${option.content}`
            }

            return (
              <SearchableSelect
                key={index}
                id={`filter-${index}`}
                className="filter-select"
                disableClearable={!value}
                label={item.label}
                getTranslatedOption={getTranslatedOption}
                fieldName={item.fieldName}
                value={dataSelected ? dataSelected.value : ''}
                options={item.data}
                onChange={onChange}
              />
            )
          })}
        </div>
      )}
      <CustomTable
        onClickTableRow={onClickTableRow}
        columns={columns}
        dataTable={dataTable}
        showLoading={showLoading}
        isHaveAvatar={isHaveAvatar}
        isShowMoreActions={isShowMoreActions}
        page={page}
        totalPages={totalPages}
        isShowPagination={isShowPagination}
        handleCheckColorText={handleCheckColorText}
        handleOrderTable={handleOrderTable}
        handleShowMoreActions={handleShowMoreActions}
        onChangedPage={onChangedPage}
      />
    </div>
  )
}
