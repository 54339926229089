// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnItem } from '../../interfaces/common/table'
import { updateCustomizeCoins, updateExpriedDate, getSettingInfo, updateCompletedImages } from '../../services/rest/request.services'
import { Button } from '@material-ui/core'
import history from '../../utils/history'
import { removeCurrentUser, removeToken } from '../../utils/helper/common'
import { toast, ToastContainer } from 'react-toastify'
import { RequestInitialState, clearErrorRequest } from '../Request/slice'
import '../Template/index.scss';
import { CustomTextField } from '../../components/elements/CustomTextField'
import { updateVersion } from '../../services/rest/category.services'
import { CustomLoading } from '../../components/elements/CustomLoading'
import { uploadImage } from '../../services/rest/template.services'
import { Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal'
import { v4 as uuid } from 'uuid';

const columns: ColumnItem[] = [
  { id: 'id', align: 'left', isBlack: true, label: 'Id' },
  { id: 'createDate', align: 'left', isBlack: true, label: 'Create at' },
  { id: 'username', align: 'left', isBlack: true, label: 'User name' },
  { id: 'custume', align: 'left', isBlack: true, label: 'Template' },
  { id: 'status', align: 'left', isBlack: true, label: 'Status' },
]

interface RequestContainerState {
  loading: boolean
  customizeCoins: string
  expriedDate: string
  version: string
  isOpenPreview: boolean
  previewImage: string
  urlComplatedImages: string
}

export const SettingContainer = () => {
  const dispatch = useDispatch()
  const requestManagement: RequestInitialState = useSelector((state: any) => state.requestManagement)
  const { settingInfo, error } = requestManagement
  const [state, setState] = useState({
    loading: true,
    customizeCoins: '',
    expriedDate: '',
    version: '',
    isOpenPreview: false,
    previewImage: '',
    urlComplatedImages: ''
  })
  const [complatedImages, setComplatedImages] = useState([])

  useEffect(() => {
    dispatch(getSettingInfo())
  }, [])

  useEffect(() => {
    setState({
      loading: false,
      customizeCoins: settingInfo.coinsCustomize,
      expriedDate: settingInfo.expriedDate,
      version: settingInfo.version,
      isOpenPreview: false,
      previewImage: '',
      urlComplatedImages: ''
    })
    if (settingInfo.complatedImages) {
      const fileListOrthers = settingInfo.complatedImages.map((item, index) => {
        return {
          uid: index,
          url: item
        }
      })
      setComplatedImages(fileListOrthers)
    }
  }, [settingInfo])

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      handleCheckErrorMessage(error?.message)
    }
  }, [error])

  const handleCheckErrorMessage = (message: string): void => {
    if (message?.includes("401")) {
      removeCurrentUser()
      removeToken()
      dispatch(clearErrorRequest())
      toast.error('Expried token!')
      history.push('/login')
    }
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: RequestContainerState) => ({
      ...prevState,
      [key]: value as Pick<RequestContainerState, keyof RequestContainerState>,
    }))
  }

  const handleUpdateVersion = async () => {
    const rs = await updateVersion()
    if (!!rs) {
      toast.error(rs)
    } else {
      dispatch(getSettingInfo())
      toast.success('Success')
    }
  }

  const handleUpdateCustomizeCoins = async () => {
    const rs = await updateCustomizeCoins(state.customizeCoins)
    if (!!rs) {
      toast.error(rs)
    } else {
      dispatch(getSettingInfo())
      toast.success('Success')
    }
  }

  const handleUpdateExpriedDate = async () => {
    const rs = await updateExpriedDate(state.expriedDate)
    if (!!rs) {
      toast.error(rs)
    } else {
      dispatch(getSettingInfo())
      toast.success('Success')
    }
  }

  const updateListImages = async (value) => {
    handleChangeState('loading', true)
    const images = value.map(item => {
      if (item === null) return ''
      return item.url
    })
    const rs = await updateCompletedImages(images)
    if (!!rs) {
      toast.error(rs)
    } else {
      setTimeout(() => dispatch(getSettingInfo()), 1300)
      toast.success('Success')
    }
  }

  const callbackChangeOrthersImage = (isSuccess: boolean, url?: any, message?: string) => {
    if (isSuccess && !!url) {
      const listImage = [...complatedImages] as any
      listImage.push({
        uid: uuid(), url
      })
      updateListImages(listImage)
      setComplatedImages(listImage)
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const sumbitOrtherImage = (e: any) => {
    if (e.key === 'Enter') {
      const listImage = [...complatedImages] as any
      listImage.push({
        uid: uuid(),
        url: state.urlComplatedImages
      })
      updateListImages(listImage)
      setComplatedImages(listImage)
      handleChangeState('urlComplatedImages', '')
    }
  }

  const onAddCompletedImages = () => {
    const listImage = [...complatedImages] as any
    listImage.push({
      uid: uuid(),
      url: state.urlComplatedImages
    })
    updateListImages(listImage)
    setComplatedImages(listImage)
    handleChangeState('urlComplatedImages', '')
  }

  const handleChangeListOrthers = (file: any) => {
    setComplatedImages(file.fileList)
  };

  const handlePreview = async (file: any) => {
    setState(prevData => ({
      ...prevData,
      isOpenPreview: true,
      previewImage: file.url
    }))
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const showLoading: boolean = requestManagement.loading

  return (
    <div className="user-management">
      {showLoading && <CustomLoading className="loading-modal" />}
      <div className="header">
        <h2 className="title">
          Setting
        </h2>
      </div>
      <div className='group-button'>
        <Button
          variant="contained"
          className="button w-100p justify-content-space-around mr-10"
          color="primary"
          onClick={() => dispatch(getSettingInfo())}
        >
          <p className="font-roboto text-white">
            Refresh data
          </p>
        </Button>
      </div>
      <div className="change-coins mt-30" >
        <CustomTextField
          label="Customize coins"
          value={state.customizeCoins}
          onChanged={handleChangeState}
          fieldName="customizeCoins"
          className="text-field w-50p mr-10"
        />
        <Button
          variant="contained"
          className="button w-20p justify-content-space-around mr-10"
          color="primary"
          onClick={() => handleUpdateCustomizeCoins()}
        >
          <p className="font-roboto text-white">
            Change customize coins
          </p>
        </Button>
      </div>
      <div className="change-coins mt-30" >
        <CustomTextField
          label="Expried date"
          value={state.expriedDate}
          onChanged={handleChangeState}
          fieldName="expriedDate"
          className="text-field w-50p mr-10"
        />
        <Button
          variant="contained"
          className="button w-20p justify-content-space-around mr-10"
          color="primary"
          onClick={() => handleUpdateExpriedDate()}
        >
          <p className="font-roboto text-white">
            Change expried date
          </p>
        </Button>
      </div>
      <div className="change-coins mt-30" >
        <CustomTextField
          onChanged={() => { }}
          label="Version"
          value={state.version}
          fieldName="version"
          className="text-field w-50p mr-10"
          disabled
        />
        <Button
          variant="contained"
          className="button justify-content-space-around ml-10"
          color="secondary"
          onClick={() => handleUpdateVersion()}
        >
          <p className="font-roboto text-pink">
            Update version
            </p>
        </Button>
      </div>
      <div className="change-coins mt-30" style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <h4 className="Original image">Completed Images</h4>
        <Upload
          key="orthers"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={complatedImages}
          beforeUpload={(data) => {
            handleChangeState('loading', true)
            uploadImage(data, callbackChangeOrthersImage)
          }}
          onPreview={handlePreview}
          onChange={handleChangeListOrthers}
        >
          {uploadButton}
        </Upload>
        <CustomTextField
          label="Completed images"
          value={state.urlComplatedImages}
          onChanged={handleChangeState}
          onKeyDown={(e) => sumbitOrtherImage(e)}
          fieldName="urlComplatedImages"
          className="text-field mt-30 "
        />
        <Button
          variant="contained"
          className="button justify-content-space-around ml-10 mt-30"
          color="primary"
          disabled={state.urlComplatedImages.trim() === ''}
          onClick={() => onAddCompletedImages()}
        >
          <p className="font-roboto text-white">
            Update completed images
            </p>
        </Button>
      </div>
      <Modal
        visible={state.isOpenPreview}
        footer={null}
        onCancel={() => handleChangeState('isOpenPreview', false)}
      >
        <img alt="example" style={{ width: '100%' }} src={state.previewImage} />
      </Modal>
      <ToastContainer />
    </div>
  )
}