import { withStyles, Theme, createStyles, FormControl } from '@material-ui/core'

export const CustomMUIFormControl = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '& label.Mui-focused': {
        color: `${theme.colors.MainGrey} !important`,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${theme.colors.LightGrey2} !important`,
      },
      '& .Mui-error': {
        '& fieldset': {
          borderColor: `${theme.colors.Red} !important`,
        },
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${theme.colors.LightGrey2} !important`,
          border: '2px solid !important',
          borderRadius: '5px !important',
        },
        '&:hover fieldset': {
          borderColor: `${theme.colors.LightGrey2} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${theme.colors.MainPink} !important`,
        },
      },
    },
  }),
)(FormControl)
