import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'
import { routes } from './config/routes'

export const Routes: React.FC = (): ReactElement => {
  return (
    <Switch >
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Switch>
  )
}
