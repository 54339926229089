import { createSlice } from '@reduxjs/toolkit'

export interface LoginInitialState {
  loading: boolean
  error: any
}

const initialState: LoginInitialState = {
  loading: false,
  error: {} as any,
}

const LoginSlice = createSlice({
  name: 'triggerLogin',
  initialState,
  reducers: {
    startLogin: (state) => {
      state.loading = true
      state.error = {}
    },
    successLogin: (state, { payload }) => {
      state.loading = false
    },
    failRequestApiFromLgoinSlice: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clearErrorLogin: (state) => {
      state.error = {}
    }
  },
})

const { actions, reducer } = LoginSlice
export const { startLogin, successLogin, failRequestApiFromLgoinSlice } = actions

export default reducer
