import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = '19%'

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    height: '100vh',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderColor: 'transparent',
    background: theme.colors.White,
    [theme.breakpoints.down('xl')]: {
      width: '17%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '22%',
    },
    [theme.breakpoints.down('md')]: {
      width: '29%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(12) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(12) + 1,
    },
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderColor: 'transparent',
    background: theme.colors.White,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    transition: 'all 0.2s ease-in-out',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  large: {
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
  },
  content: {
    flexGrow: 1,
  },
  setting: {
    flexGrow: 1,
  },
  badge: {
    position: 'absolute',
    left: 40,
    top: 116,
    minWidth: 0,
    width: 13,
    height: 13,
    fontSize: 10,
  },
}))
