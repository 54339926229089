import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ColumnItem,
  SelectedFilterEntity,
} from '../../interfaces/common/table';
import { CustomSearchComponent } from '../../components/elements/CustomSearch';
import { CustomTableContainer } from '../../components/resources/CustomTableContainer';
import {
  getListRequests,
  deleteRequest,
  updateRequest,
  updateCustomizeCoins,
  updateExpriedDate,
  sendEmail,
} from '../../services/rest/request.services';
import { Button } from '@material-ui/core';
import { CustomMenu } from '../../components/resources/CustomMenu';
import history from '../../utils/history';
import { removeCurrentUser, removeToken } from '../../utils/helper/common';
import { ConfirmFormModal } from '../../components/resources/ConfirmFormModal';
import { toast, ToastContainer } from 'react-toastify';
import { OptionSelect } from '../../interfaces/common/menu';
import { RequestInitialState, clearErrorRequest } from './slice';
import { Request, RequestRow } from '../../interfaces/request';
import { TemplateInitialState } from '../Template/slice';
import { getListUsers } from '../../services/rest/user.services';
import { UserInitialState } from '../Users/slice';
import { getListTemplates } from '../../services/rest/template.services';
import '../Template/index.scss';
import moment from 'moment';
import { UpdateRequestModal } from '../../components/resources/UpdateRequestModal';
import { ExportModal } from '../../components/resources/ExportModal';
import { createInbox } from '../../services/rest/notification.services';

const columns: ColumnItem[] = [
  { id: 'id', align: 'left', isBlack: true, label: 'Id' },
  { id: 'createDate', align: 'left', isBlack: true, label: 'Create at' },
  { id: 'username', align: 'left', isBlack: true, label: 'User name' },
  { id: 'custume', align: 'left', isBlack: true, label: 'Template' },
  { id: 'petname', align: 'left', isBlack: true, label: 'Pet name' },
  { id: 'status', align: 'left', isBlack: true, label: 'Status' },
];

interface RequestContainerState {
  listRequestsTable: RequestRow[];
  isOpenedUpdateRequestModal: boolean;
  isCreated: boolean;
  orderBy: string;
  typeOrder: string;
  loading: boolean;
  requestDetail: Request;
  searchKeyword: string;
  isOpenFormConfirm: boolean;
  listOptionsRequests: OptionSelect[];
  listDataSelectedFilters: SelectedFilterEntity[];
  userIdFilter: string;
  templateIdFilter: string;
  customizeCoins: string;
  expriedDate: string;
  id: string;
  totalPages: number;
  page: number;
}

export const RequestContainer = () => {
  const dispatch = useDispatch();
  const userManagement: UserInitialState = useSelector(
    (state: any) => state.userManagement
  );
  const requestManagement: RequestInitialState = useSelector(
    (state: any) => state.requestManagement
  );
  const templateManagement: TemplateInitialState = useSelector(
    (state: any) => state.templateManagement
  );
  const { listUsers } = userManagement;
  const { templates } = templateManagement;
  const { requests, error, page, totalPages } = requestManagement;
  const [state, setState] = useState({
    isOpenedUpdateRequestModal: false,
    listRequestsTable: [] as RequestRow[],
    isCreated: false,
    orderBy: 'createDate',
    typeOrder: 'ASC',
    loading: true,
    searchKeyword: '',
    requestDetail: {} as Request,
    isOpenFormConfirm: false,
    listDataSelectedFilters: [] as SelectedFilterEntity[],
    userIdFilter: '',
    templateIdFilter: '',
    listOptionsRequests: [] as OptionSelect[],
    customizeCoins: '',
    expriedDate: '',
    id: '',
    totalPages: 0,
    page: 1,
  });

  const [anchorElementCreateUser, setAnchorElementCreateUser] =
    useState<null | HTMLElement>(null);
  const [showExportFilter, setShowExportFilter] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getListTemplates());
    dispatch(getListUsers());
  }, []);

  useEffect(() => {
    if (requests) {
      const listRequestsTable = requests.map((item) => {
        const userDetail = listUsers.filter(
          (user) => user.id === item.userId
        )[0];
        const templateDetail = templates.filter(
          (template) => template.id === item.templateId
        )[0];
        return {
          id: item.id,
          createDate: moment(item.createDate).format('DD/MM/YYYY').toString(),
          username: userDetail?.username || '',
          custume: templateDetail?.title || '',
          petname: item.petname,
          status: item.status,
        };
      });
      setState((prevState: RequestContainerState) => ({
        ...prevState,
        listRequestsTable,
        page: page + 1,
        totalPages,
      }));
    }
  }, [requests, listUsers, templates]);

  useEffect(() => {
    const usersFilter: OptionSelect[] = [];
    const templatesFilter: OptionSelect[] = [];
    listUsers.forEach((item: any) => {
      const optionCategoryEntity: OptionSelect = {
        value: item.id,
        content: item.username,
      };
      usersFilter.push(optionCategoryEntity);
    });
    templates.forEach((item: any) => {
      const optionCategoryEntity: OptionSelect = {
        value: item.id,
        content: item.title,
      };
      templatesFilter.push(optionCategoryEntity);
    });
    const listDataSelectedFilters: SelectedFilterEntity[] = [
      {
        label: 'User',
        data: usersFilter,
        fieldName: 'userIdFilter',
      },
      {
        label: 'Template',
        data: templatesFilter,
        fieldName: 'templateIdFilter',
      },
    ];
    handleChangeState('listDataSelectedFilters', listDataSelectedFilters);
  }, [listUsers, templates]);

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      handleCheckErrorMessage(error?.message);
    }
  }, [error]);

  useEffect(() => {
    dispatch(
      getListRequests({
        page: 0,
        userIdFilter: state.userIdFilter,
        templateIdFilter: state.templateIdFilter,
        searchKeyword: state.searchKeyword,
        orderBy: state.orderBy,
        typeOrder: state.typeOrder.toUpperCase(),
      })
    );
  }, [
    state.searchKeyword,
    state.userIdFilter,
    state.templateIdFilter,
    state.orderBy,
    state.typeOrder,
  ]);

  const handleCheckErrorMessage = (message: string): void => {
    if (message?.includes('401')) {
      removeCurrentUser();
      removeToken();
      dispatch(clearErrorRequest());
      toast.error('Expried token!');
      history.push('/login');
    }
  };

  const findLastElementOfString = (value: string, typeSplit = '.'): string => {
    const arrElements: string[] = value.split(typeSplit);
    const index: number = arrElements.length - 1;
    return arrElements[index];
  };

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: RequestContainerState) => ({
      ...prevState,
      [key]: value as Pick<RequestContainerState, keyof RequestContainerState>,
    }));
  };

  const handleOpenModalFromTableRow = (id: string) => {
    const requestDetail = requests.filter((item) => {
      return item.id === id;
    })[0];
    setState((prevState: RequestContainerState) => ({
      ...prevState,
      isCreated: false,
      isOpenedUpdateRequestModal: true,
      loading: true,
      requestDetail,
    }));
  };

  const handleOrderListUsers = (orderBy: string, typeOrder: string) => {
    setState((prevState: RequestContainerState) => ({
      ...prevState,
      orderBy,
      typeOrder,
    }));
  };

  const handleCheckColorText = (value: string): boolean => {
    if (
      ['false'].includes(findLastElementOfString(value ? value.toString() : ''))
    )
      return true;
    return false;
  };

  const callbackDeleteTemplate = (
    isSuccess: boolean,
    message?: string
  ): void => {
    if (isSuccess) {
      toast.success('Success');
      dispatch(
        getListRequests({
          page: state.page - 1,
          userIdFilter: state.userIdFilter,
          templateIdFilter: state.templateIdFilter,
          searchKeyword: state.searchKeyword,
          orderBy: state.orderBy,
          typeOrder: state.typeOrder.toUpperCase(),
        })
      );
    } else {
      handleCheckErrorMessage(message as string);
      toast.error('Have an issue when delete template. Try again!');
    }
  };

  const handleDelete = (): void => {
    handleChangeState('isOpenFormConfirm', false);
    deleteRequest(state.id, callbackDeleteTemplate);
  };

  const callback = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      dispatch(
        getListRequests({
          page: state.page - 1,
          userIdFilter: state.userIdFilter,
          templateIdFilter: state.templateIdFilter,
          searchKeyword: state.searchKeyword,
          orderBy: state.orderBy,
          typeOrder: state.typeOrder.toUpperCase(),
        })
      );
      toast.success('Success!');
    } else {
      handleCheckErrorMessage(message as string);
      toast.error(message);
    }
  };

  const handleUpdateRequest = (data: unknown) => {
    handleChangeState('isOpenedUpdateRequestModal', false);
    updateRequest(data, callback);
  };

  const handleSendEmail = async (data: any) => {
    toast.warning('Please wait, while we send email !', {
      autoClose: false,
    });
    const rs = await sendEmail(data);
    toast.dismiss();
    if (!!rs) {
      toast.error(rs);
    } else {
      toast.success('Success!');
    }
  };

  const onChangedPage = (value: number): void => {
    dispatch(
      getListRequests({
        page: value - 1,
        userIdFilter: state.userIdFilter,
        templateIdFilter: state.templateIdFilter,
        searchKeyword: state.searchKeyword,
        orderBy: state.orderBy,
        typeOrder: state.typeOrder.toUpperCase(),
      })
    );
  };

  const handleSearch = (value: string) => {
    handleChangeState('page', 1);
    handleChangeState('searchKeyword', value);
  };

  const sendInbox = async (userId: string, data: any) => {
    const rs = await createInbox(userId, data);
    if (!!rs) {
      toast.error(rs);
    } else {
      toast.success('Success!');
    }
  };

  const getPopupTitle = () => {
    const userId =
      requests?.filter((item) => item.id === state.id)[0]?.userId || '';
    return `${
      listUsers.filter((user) => user.id === userId)[0]?.username || ''
    }'s request'`;
  };

  const showLoading: boolean =
    requestManagement.loading || userManagement.loading;
  return (
    <div className="user-management">
      <CustomSearchComponent
        handleSearch={handleSearch}
        name="Search with email and user name"
      />
      <div className="header">
        <h2 className="title">Requests management</h2>
        <div className="group-button">
          <Button
            variant="contained"
            className="button w-100p justify-content-space-around mr-10"
            color="primary"
            onClick={() =>
              dispatch(
                getListRequests({
                  page: state.page - 1,
                  userIdFilter: state.userIdFilter,
                  templateIdFilter: state.templateIdFilter,
                  searchKeyword: state.searchKeyword,
                  orderBy: state.orderBy,
                  typeOrder: state.typeOrder.toUpperCase(),
                })
              )
            }
          >
            <p className="font-roboto text-white">Refresh data</p>
          </Button>
          <Button
            variant="contained"
            className="button justify-content-space-around mr-10"
            color="primary"
            onClick={() => setShowExportFilter(true)}
          >
            <p className="font-roboto text-white">Export requests</p>
          </Button>
        </div>
      </div>
      <CustomTableContainer
        showLoading={showLoading}
        onClickTableRow={handleOpenModalFromTableRow}
        columns={columns}
        dataTable={state.listRequestsTable}
        handleCheckColorText={handleCheckColorText}
        isHaveAvatar={false}
        isHaveFilter
        isShowPagination
        page={state.page}
        totalPages={state.totalPages}
        onChangedFilter={handleChangeState}
        handleOrderTable={handleOrderListUsers}
        handleShowMoreActions={(anchorElement: HTMLElement, id: string) => {
          setAnchorElementCreateUser(anchorElement);
          handleChangeState('id', id);
        }}
        isShowMoreActions={true}
        onChangedPage={onChangedPage}
        listDataSelectedFilters={state.listDataSelectedFilters}
        valueFilters={[state.userIdFilter, state.templateIdFilter]}
      />
      <UpdateRequestModal
        isOpen={state.isOpenedUpdateRequestModal}
        handleCloseModal={() => {
          handleChangeState('requestDetail', {});
          handleChangeState('isOpenedUpdateRequestModal', false);
        }}
        handleSave={handleUpdateRequest}
        onSendEmail={handleSendEmail}
        requestDetail={state.requestDetail}
        sendInbox={sendInbox}
      />
      <ExportModal
        isOpen={showExportFilter}
        handleCloseModal={() => setShowExportFilter(false)}
      />
      <CustomMenu
        anchorElement={anchorElementCreateUser}
        vertical={'center'}
        horizontal={'left'}
        listMenuItems={[
          {
            name: 'Delete',
          },
        ]}
        handleCloseMenu={() => setAnchorElementCreateUser(null)}
        handleSubmit={() => {
          handleChangeState('isOpenFormConfirm', true);
          setAnchorElementCreateUser(null);
        }}
      />
      <ToastContainer />
      <ConfirmFormModal
        onCancel={() => handleChangeState('isOpenFormConfirm', false)}
        onYes={handleDelete}
        name={getPopupTitle()}
        isOpen={state.isOpenFormConfirm}
      />
    </div>
  );
};
