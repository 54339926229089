import { CallApi, uploadImageApi } from '../api'
import {
  startGetTemplates,
  successGetTemplates,
  failRequestApiFromTemplateSlice,
} from '../../containers/Template/slice'
import { checkValue } from '../../utils/helper/common'

export const getListTemplates = (input?: {
  page: number,
  searchKeyword?: string,
  filter?: string,
  orderBy?: string,
  typeOrder?: string,
}): ((dispatch: any) => Promise<void>) => {
  return async (dispatch) => {
    dispatch(startGetTemplates())
    let params: Record<string, unknown> = {}
    if (input) {
      const { page, searchKeyword, filter, orderBy, typeOrder } = input
      const data: Record<string, unknown> = {
        page,
        searchKeyword,
        filter,
        orderBy,
        typeOrder,
      }
      params = checkValue(params, data)
    }
    try {
      const res = await CallApi({
        endpoint: 'template',
        method: 'GET',
        params
      })
      dispatch(successGetTemplates(res))
    } catch (err) {
      dispatch(failRequestApiFromTemplateSlice(err))
    }
  }
}

export const createTemplate = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: 'template',
      method: 'POST',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const updateTemplate = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `template/${(data as Record<string, unknown>).id}`,
      method: 'PUT',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const deleteTemplate = async (id: string, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `template/${id}`,
      method: 'DELETE',
    })
    callback(true)
  } catch (err) {
    callback(false, err.message)
  }
}

export const uploadImage = async (formData: unknown, callback: (isSuccess: boolean, data?: unknown, message?: string) => void): Promise<void> => {
  try {
    const rs = await uploadImageApi('upload', formData)
    callback(true, rs.data)
  } catch (err) {
    callback(false, undefined, err.message)
  }
}
