import { createSlice } from '@reduxjs/toolkit'
import { Notification } from '../../interfaces/notification'

export interface NotificationInitialState {
  loading: boolean
  notifications: Notification[]
  error: Error
}

const initialState: NotificationInitialState = {
  loading: false,
  notifications: [] as Notification[],
  error: {} as Error,
}

const NotificationSlice = createSlice({
  name: 'triggerNotification',
  initialState,
  reducers: {
    startGetNotifications: (state) => {
      state.loading = true
      state.notifications = []
      state.error = {} as Error
    },
    successGetNotifications: (state, { payload }) => {
      state.loading = false
      state.notifications = payload.data
    },
    failRequestApiFromNotificationSlice: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clearErrorNotification: (state) => {
      state.error = {} as Error
    }
  },
})

const { actions, reducer } = NotificationSlice
export const { startGetNotifications, successGetNotifications, failRequestApiFromNotificationSlice, clearErrorNotification } = actions

export default reducer
