import { createSlice } from '@reduxjs/toolkit'
import { Category } from '../../interfaces/category'

export interface CategoryInitialState {
  loading: boolean
  categories: Category[],
  error: any
}

const initialState: CategoryInitialState = {
  loading: false,
  categories: [] as Category[],
  error: {} as any,
}

const categorySlice = createSlice({
  name: 'triggerCategory',
  initialState,
  reducers: {
    startGetCategories: (state) => {
      state.loading = true
    },
    successGetCategories: (state, { payload }) => {
      state.loading = false
      state.categories = payload.data.categories
    },
    failRequestApiFromCategoriesSlice: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clearErrorCategory: (state) => {
      state.error = {}
    }
  },
})

const { actions, reducer } = categorySlice
export const { startGetCategories, successGetCategories, failRequestApiFromCategoriesSlice, clearErrorCategory } = actions

export default reducer
