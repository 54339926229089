// @ts-nocheck
import React, { ReactElement, useState, useEffect, ChangeEvent, Fragment } from 'react'
import { Grid, Button, Dialog, AppBar, Tabs, Tab } from '@material-ui/core'
import { CustomTextField } from '../../elements/CustomTextField'
import { CustomLoading } from '../../elements/CustomLoading'
import { OptionSelect } from '../../../interfaces/common/menu'
import 'antd/dist/antd.css';
import '../UpdateTemplateModal/index.scss'
import { toast, ToastContainer } from 'react-toastify'
import { Request } from '../../../interfaces/request'
import './index.scss'
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { uploadImage } from '../../../services/rest/template.services'
import { v4 as uuid } from 'uuid';
import { UploadFile } from 'antd/lib/upload/interface'
import { useSelector } from 'react-redux'
import { UserInitialState } from '../../../containers/Users/slice'
import { TemplateInitialState } from '../../../containers/Template/slice'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CustomMUITextField } from '../../elements/CustomMUI/CustomMUITextField'
import { UserData } from '../../../interfaces/user'
import { Template } from '../../../interfaces/template'
import { CSVLink } from "react-csv";
import moment from 'moment'
import { TabPanel, a11yProps } from '../../elements/TablePanel'
import { Productions } from './Products'
import clsx from 'clsx'

interface UpdateRequestModalProps {
  handleCloseModal: () => void
  isOpen: boolean
  requestDetail: Request
  handleSave: (data: unknown) => void
  onSendEmail: (data: any) => void
  sendInbox: (userId: string, data: any) => void
}

const statusOptions = ['ACCEPTED', 'PENDING', 'INPROCESS', 'COMPLETED']

export const UpdateRequestModal: React.FC<UpdateRequestModalProps> = (props: UpdateRequestModalProps): ReactElement => {
  const {
    isOpen,
    handleCloseModal,
    handleSave,
    onSendEmail,
    requestDetail,
    sendInbox,
  } = props

  const userManagement: UserInitialState = useSelector((state: any) => state.userManagement)
  const templateManagement: TemplateInitialState = useSelector((state: any) => state.templateManagement)
  const { listUsers } = userManagement
  const { templates } = templateManagement

  const [state, setState] = useState({
    loading: false,
    optionCategories: [] as OptionSelect[],
    isOpenPreview: false,
    previewImage: '',
    listUsersInfo: [],
    usersInfo: {} as UserData,
    templateInfo: {} as Template,
    thumbnail: '',
    image: '',
  })

  const [value, setValue] = React.useState(0);
  const [requestData, setRequestData] = useState({
    note: '',
    status: '',
    petname: '',
  })
  const [dataExcel, setDataExcel] = useState<string[][]>([])
  const [template, setTemplate] = useState<UploadFile<any>[]>([] as UploadFile<any>[])
  const [userImages, setUserImages] = useState<UploadFile<any>[]>([] as UploadFile<any>[])
  const [custumeImages, setCustumeImages] = useState<UploadFile<any>[]>([] as UploadFile<any>[])
  const [resultImage, setResultImage] = useState<UploadFile<any>[]>([] as UploadFile<any>[])
  const [complatedImage, setComplatedImage] = useState<UploadFile<any>[]>([] as UploadFile<any>[])
  const [dataImage, setDataImage] = useState<UploadFile<any>>({} as UploadFile<any>)

  // Notification state
  const [notificationData, setNotificationData] = useState({
    title: '',
    body: '',
    deeplink: '',
    action: 'notify',
    userId: ''
  })
  const [image, setImage] = useState([])
  const [thumbnail, setThumbnail] = useState([])

  useEffect(() => {
    if (isOpen) {
      setDataImage({})
      setComplatedImage([])
      setNotificationData({
        title: '',
        body: '',
        deeplink: '',
        action: 'notify',
        userId: ''
      })
      setImage([])
      setThumbnail([])
    }
  }, [isOpen])

  useEffect(() => {
    if (requestDetail) {
      const listUsersInfo = listUsers.map(user => { return { id: user.id, email: user.email, username: user.username, vip: user.vip } })
      const usersInfo = listUsers.filter(user => { return user.id === requestDetail.userId })[0]
      const templateInfo = templates.filter(template => { return template.id === requestDetail.templateId })[0]
      const imagesFromRequestDetail = requestDetail?.images?.map(item => {
        return {
          uid: uuid(),
          url: item
        }
      }) || []
      const imagesCustume = requestDetail?.custumeImages?.map(item => {
        return {
          uid: uuid(),
          url: item
        }
      }) || []
      const request = { ...requestDetail }
      const createDate = moment(request?.createDate).format('DD/MM/YYYY').toString()
      const updateDate = moment(request?.updateDate).format('DD/MM/YYYY').toString()
      const excelData = [[
        'Request Id',
        'User name',
        'User email',
        'Template title',
        'Template image',
        'User images',
        'Custume Images',
        'Status',
        'Sign',
        'Product image',
        'Created at',
        'Updated at'
      ]].concat(
        [[
          request?.id,
          usersInfo?.username,
          usersInfo?.email,
          templateInfo?.title,
          templateInfo?.custumeImage,
          request?.images?.join(' , ') || '',
          request?.custumeImages?.join(' , ') || '',
          request?.status,
          request?.petname,
          request?.productImage,
          createDate,
          updateDate
        ]]) as string[][]
      setDataExcel(excelData)
      setState(prevData => ({
        ...prevData,
        listUsersInfo,
        usersInfo,
        templateInfo,
      }))
      setRequestData(prevData => ({
        ...prevData,
        note: requestDetail.note,
        status: requestDetail.status,
        petname: requestDetail.petname
      }))
      setUserImages(imagesFromRequestDetail)
      setCustumeImages(imagesCustume)
      setResultImage(requestDetail?.productImage ? [{
        uid: uuid(),
        url: requestDetail?.productImage
      }] : [])
    }
  }, [listUsers, templates, requestDetail])

  useEffect(() => {
    if (Object.keys(state.templateInfo ? state.templateInfo : {})?.length > 0) {
      setTemplate([{
        uid: uuid(),
        url: state.templateInfo?.custumeImage
      }])
    } else {
      setTemplate([])
    }
  }, [state.templateInfo])

  const handleChangeRequestData = (key: string, value: unknown): void => {
    setRequestData(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeState = (key: string, value: unknown): void => {
    setState(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const callbackChangeResultImage = (isSuccess: boolean, url?: any, message?: string) => {
    if (isSuccess && !!url) {
      setResultImage([
        {
          uid: uuid(),
          url
        }
      ] as UploadFile<any>[])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const callbackChangeUserImage = (isSuccess: boolean, url?: any, message?: string) => {
    if (isSuccess && !!url) {
      const listImage = [...userImages]
      listImage.push({
        uid: uuid(), url
      })
      setUserImages(listImage)
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }
  const callbackChangeCustumeImage = (isSuccess: boolean, url?: any, message?: string) => {
    if (isSuccess && !!url) {
      const listImage = [...custumeImages]
      listImage.push({
        uid: uuid(), url
      })
      setCustumeImages(listImage)
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const resetData = () => {
    setState({
      loading: false,
      optionCategories: [] as OptionSelect[],
      isOpenPreview: false,
      previewImage: '',
      listUsersInfo: [],
      usersInfo: {} as UserData,
      templateInfo: {} as Template
    })
    setValue(0)
    setRequestData({
      note: '',
      status: '',
      petname: '',
    })
    setCustumeImages([])
    setResultImage([])
    setTemplate([])
    setUserImages([])
    setNotificationData({
      title: '',
      coins: 0,
      faces: 0,
      priority: 0,
    })
    setImage([])
    setThumbnail([])
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handlePreview = async (file: { url: any }) => {
    setState(prevData => ({
      ...prevData,
      isOpenPreview: true,
      previewImage: file.url
    }))
  };

  const handleChangeUserInfo = (value) => {
    handleChangeState('usersInfo', value)
  }

  const handleSaveModal = (): void => {
    console.log({state }, state.usersInfo?.id)
    let request = {
      ...requestDetail,
      ...requestData,
      templateId: state.templateInfo?.id,
      userId: state.usersInfo?.id,
      images: userImages.map(item => { return item.url }),
      custumeImages: custumeImages.map(item => { return item.url }),
      isCustume: custumeImages.length > 0 ? true : false,
      productImage: resultImage.map(item => item.url)[0]
    }
    handleSave(request)
    handleCloseModal()
  }

  const handleChangeListProducts = (file) => {
    setThumbnail(file.fileList)
  };

  const callbackChangeOriginalImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setImage([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const callbackChangeProductsImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setThumbnail([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const createInbox = (): void => {
    let data = {
      ...notificationData,
      request: requestDetail,
      image: state.image !== '' ? state.image : (image[0]?.url || ''),
      thumbnail: state.thumbnail !== '' ? state.thumbnail : (thumbnail[0]?.url || ''),
    }
    sendInbox(state.usersInfo?.id, data)
    resetData()
    handleCloseModal()
  }

  const handleChangeNotificationData = (key: string, value: string): void => {
    setNotificationData(prevData => ({
      ...prevData,
      [key]: value
    }))
  }
  const renderContent = (): ReactElement => {
    return (
      <div className='header-tabs'>
        <AppBar position="static" className='appBar'>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className='tabs'>
            <Tab label="User info" {...a11yProps(0)} />
            <Tab label="Request info" {...a11yProps(1)} />
            <Tab label="Result" {...a11yProps(2)} />
            <Tab label="Send email" {...a11yProps(3)} />
            <Tab label="Create inbox" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container item lg={6} md={6} sm={6}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <h4 className="title mb-10">User information</h4>
              <Autocomplete
                id="focusmeEntityName"
                className="text-field mb-50"
                options={listUsers}
                filterOptions={(options) => options}
                getOptionLabel={(option) => option.username || ''}
                value={state.usersInfo}
                disabled
                onChange={(event: ChangeEvent<Record<string, unknown>>, newValue) => handleChangeUserInfo(newValue)}
                renderOption={(option) => <Fragment>{option.username}</Fragment>}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <CustomMUITextField
                    {...params}
                    label="Username"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <Autocomplete
                id="focusmeEntityName"
                className="text-field mb-50"
                options={listUsers}
                filterOptions={(options) => options}
                getOptionLabel={(option) => option.email || ''}
                value={state.usersInfo}
                disabled
                onChange={(event: ChangeEvent<Record<string, unknown>>, newValue) => handleChangeUserInfo(newValue)}
                renderOption={(option) => <Fragment>{option.email}</Fragment>}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <CustomMUITextField
                    {...params}
                    label="Email"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <p className="text-field mb-50 ">VIP: {state.usersInfo?.vip ? 'True' : 'False'}</p>
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <p className="text-field mb-50 ">Expired: {!!state.usersInfo?.expiredDate ? state.usersInfo?.expiredDate : '------'}</p>
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <p className="text-field mb-50 ">Language: {!!state.usersInfo?.language ? state.usersInfo?.language : '------'}</p>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container item lg={6} md={6} sm={6}>
            <Grid item lg={12} md={12} sm={6} xs={12} className={clsx({ none: requestDetail.isCustume })}>
              <h4 className="title mb-10">Template information</h4>
              <Autocomplete
                id="templates"
                className="text-field mb-50"
                options={templates}
                filterOptions={(options) => options}
                getOptionLabel={(option) => option.title || ''}
                value={state.templateInfo}
                disabled
                onChange={(event: ChangeEvent<Record<string, unknown>>, newValue) => handleChangeState('templateInfo', newValue)}
                renderOption={(option) => <Fragment>{option.title}</Fragment>}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <CustomMUITextField
                    {...params}
                    label="Title"
                    variant="outlined"
                  />
                )}
              />
              <Upload
                key="template"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={requestDetail.isCustume ? custumeImages : template}
                showUploadList={{
                  showRemoveIcon: false
                }}
                beforeUpload={requestDetail.isCustume ? (data) => {
                  handleChangeState('loading', true)
                  uploadImage(data, callbackChangeCustumeImage)
                } : null}
                onRemove={() => toast.error('Can not removed')}
                onPreview={handlePreview}
                className='text-field mb-50'
              >
              </Upload>
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <h4 className="title mb-10">User images</h4>
              <Upload
                key="user"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={userImages}
                beforeUpload={(data) => {
                  handleChangeState('loading', true)
                  uploadImage(data, callbackChangeUserImage)
                }}
                onPreview={handlePreview}
                className='text-field mb-50'
                onChange={(file) => setUserImages(file.fileList)}
              >
                {uploadButton}
              </Upload>
              <CustomTextField
                label="Note"
                value={requestData.note}
                onChanged={handleChangeRequestData}
                fieldName="note"
                className="text-field mb-50 "
              />
              <CustomTextField
                label="Sign"
                value={requestData.petname}
                onChanged={handleChangeRequestData}
                fieldName="petname"
                className="text-field mb-50 "
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container item lg={6} md={6} sm={6}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <h4 className="title mb-10">Result image</h4>
              <Upload
                key="rs"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={resultImage}
                beforeUpload={(data) => {
                  handleChangeState('loading', true)
                  uploadImage(data, callbackChangeResultImage)
                }}
                showUploadList={{
                  showRemoveIcon: false
                }}
                onRemove={() => toast.error('Can not removed')}
                onPreview={handlePreview}
                className='text-field mb-50'
                onChange={(file) => setResultImage(file.fileList)}
              >
                {uploadButton}
              </Upload>
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <Autocomplete
                id="status"
                className="text-field mb-50"
                options={statusOptions}
                filterOptions={(options) => options}
                getOptionLabel={(option) => option || ''}
                value={requestDetail.status}
                disableClearable
                onChange={(event: ChangeEvent<Record<string, unknown>>, newValue) => handleChangeRequestData('status', newValue)}
                renderOption={(option) => <Fragment>{option}</Fragment>}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <CustomMUITextField
                    {...params}
                    label="Status"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Productions requestId={requestDetail.id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container item lg={12} md={12} sm={12}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <h4 className="title mb-10">High quality image</h4>
              <Upload
                key="rs"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={complatedImage}
                beforeUpload={(data) => {
                  setDataImage(data)
                }}
                showUploadList={{
                  showRemoveIcon: false
                }}
                onRemove={() => toast.error('Can not removed')}
                onPreview={handlePreview}
                className='text-field mb-50'
                onChange={(file) => {
                  const oldValue = [...complatedImage]
                  setComplatedImage(file.fileList.filter(item => item.uid !== oldValue[0]?.uid))
                }}
              >
                {uploadButton}
              </Upload>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12}>
            <Button
              variant="contained"
              className="button"
              color="primary"
              onClick={() => {
                onSendEmail({
                  complatedImage: dataImage, requestData: {
                    ...requestDetail,
                    ...requestData,
                    templateId: state.templateInfo?.id,
                    userId: state.usersInfo?.id,
                    images: userImages.map(item => { return item.url }),
                    custumeImages: custumeImages.map(item => { return item.url }),
                    isCustume: custumeImages.length > 0 ? true : false,
                    productImage: resultImage.map(item => item.url)[0]
                  }
                })
                handleCloseModal()
              }}
            >
              <p className="text-white text-transform-normal">
                Send email
                </p>
            </Button>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Grid container spacing={0} direction="column">
            <div className="content">
              <Grid container className="row" spacing={4}>
                <Grid item lg={4} className="row">
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <h4 className="title mb-10">Notification information</h4>
                    <CustomTextField
                      label="Title"
                      value={notificationData.title}
                      onChanged={handleChangeNotificationData}
                      fieldName="title"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Body"
                      value={notificationData.body}
                      onChanged={handleChangeNotificationData}
                      fieldName="body"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Deeplink"
                      value={notificationData.deeplink}
                      onChanged={handleChangeNotificationData}
                      fieldName="deeplink"
                      className="text-field mb-50 "
                    />
                  </Grid>
                </Grid>
                <Grid container item lg={8} className="row">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h4 className="Original image">Image</h4>
                    <Upload
                      key="original"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={image}
                      beforeUpload={(data) => {
                        handleChangeState('loading', true)
                        uploadImage(data, callbackChangeOriginalImage)
                      }}
                      onPreview={handlePreview}
                      onChange={(file) => setImage(file.fileList)}
                    >
                      {uploadButton}
                    </Upload>
                    <CustomTextField
                      label="Image"
                      value={state.image}
                      onChanged={handleChangeState}
                      fieldName="image"
                      className="text-field mb-50 "
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const listImage = [{
                            uid: uuid(),
                            url: state.image
                          }] as any
                          setImage(listImage)
                          handleChangeState('image', '')
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h4 className="Original image">Thumbnail</h4>
                    <Upload
                      key="productions"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={thumbnail}
                      beforeUpload={(data) => {
                        handleChangeState('loading', true)
                        uploadImage(data, callbackChangeProductsImage)
                      }}
                      onPreview={handlePreview}
                      onChange={handleChangeListProducts}
                    >
                      {uploadButton}
                    </Upload>
                    <CustomTextField
                      label="Thumbnail"
                      value={state.thumbnail}
                      onChanged={handleChangeState}
                      fieldName="thumbnail"
                      className="text-field mb-50 "
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const listImage = [{
                            uid: uuid(),
                            url: state.thumbnail
                          }] as any
                          setThumbnail(listImage)
                          handleChangeState('thumbnail', '')
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2} className="footer">
              <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className="button"
                    color="primary"
                    onClick={() => createInbox()}
                  >
                    <p className="text-white text-transform-normal">
                      Create inbox
                </p>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    )
  }

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleCloseModal}
      className="update-user-modal"
    >
      {state.loading && <CustomLoading className="loading-modal" />}
      {renderContent()}
      <Grid container spacing={2} className="footer">
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Button variant="outlined" className="button" color="secondary" onClick={() => {
            resetData()
            handleCloseModal()
          }}>
            <p className="text-pink text-transform-normal">
              Back
              </p>
          </Button>
        </Grid>
        <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
          <Grid item xs={6}>
            <CSVLink data={dataExcel as string[][]} filename='Request_Detail'>
              <Button
                variant="outlined"
                className="button mr-10"
                color="secondary"
              >
                <p className="text-pink text-transform-normal">
                  Export request
                </p>
              </Button>
            </CSVLink>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              className="button"
              color="primary"
              onClick={() => handleSaveModal()}
            >
              <p className="text-white text-transform-normal">
                Save
                </p>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ToastContainer />
    </Dialog>
  )
}
