import React, { useState, useEffect, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core'
import { DefaultMenus } from './constants'
import { GroupMenu } from '../../elements/GroupMenu'
import { Images } from '../../../constants/images'
import { useStyles } from './styles'
import './index.scss'
import { signOut } from '../../../services/rest/login.services'
import history from '../../../utils/history'
import { ToastContainer } from 'react-toastify';
import { removeToken, removeCurrentUser } from '../../../utils/helper/common'

const { Logout, Path, LeftArrow } = Images

interface SideBarState {
  isOpen: boolean
  itemActive: string
}

export const SideBar: React.FC = (): ReactElement => {
  const classes = useStyles()
  const [state, setState] = useState({
    isOpen: false,
    itemActive: '',
  })

  useEffect(() => {
    const url = window.location.href
    const itemActive = url.split('/')[3]
    handleChangeState('itemActive', itemActive)
  }, [])

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: SideBarState) => ({
      ...prevState,
      [key]: value as Pick<SideBarState, keyof SideBarState>,
    }))
  }

  const handleChangeItemActive = (value: string) => {
    handleChangeState('itemActive', value)
  }

  const handleClickSignout = (): void => {
    handleChangeState('itemActive', 'logout')
    signOut()
    removeToken()
    removeCurrentUser()
    history.push('/login')
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: state.isOpen,
        [classes.drawerClose]: !state.isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: state.isOpen,
          [classes.drawerClose]: !state.isOpen,
        }),
      }}
    >
      {state.isOpen && (
        <div className="path">
          <img src={Path} alt="Path" />
        </div>
      )}
      <div
        className={clsx(classes.toolbar, {
          'justify-content-center': !state.isOpen,
        })}
      >
        <IconButton onClick={() => handleChangeState('isOpen', !state.isOpen)}>
          <img src={LeftArrow} alt="Icon Arrow" className={clsx('arrow', { 'deg-180': !state.isOpen })} />
        </IconButton>
      </div>
      <List className='mb-70'>
        <ListItem button className="item">
          <ListItemIcon
            className={clsx('item-icon', {
              'item-icon-close': !state.isOpen,
            })}
          >
            <div className="item-icon-avatar ">
              <NavLink to="/admin/profile" onClick={() => handleChangeState('itemActive', 'profile')}>
                <Avatar
                  alt="Remy Sharp"
                  // src={state.currentUser?.avatar ? state.currentUser.avatar : undefined}
                  className={clsx(classes.large, {
                    'have-notification': true,
                  })}
                />
              </NavLink>
            </div>
          </ListItemIcon>
          <div className="item-text">
            <p
              className={clsx('font-carmen', {
                'item-text-open': state.isOpen,
                'item-text-close': !state.isOpen,
              })}
            >
              <NavLink
                to="/admin/profile"
                className="item-text-name"
                onClick={() => handleChangeState('itemActive', 'profile')}
              >
                Hi, Admin
              </NavLink>
            </p>
          </div>
        </ListItem>
        <GroupMenu
          menus={DefaultMenus}
          isOpen={state.isOpen}
          itemActive={state.itemActive}
          handleChangeItemActive={handleChangeItemActive}
        />
      </List>
      <List className="item-logout" onClick={() => handleClickSignout()}>
        <ListItem button className="item">
          <ListItemIcon
            className={clsx('item-icon', {
              'item-icon-close': !state.isOpen,
            })}
          >
            <div className="contain-img">
              <img src={Logout} alt="Icon Menu" className="img" />
            </div>
          </ListItemIcon>
          <ListItemText
            primary={<p className="font-roboto item-text-logout">Logout</p>}
            className={clsx('item-text', {
              'item-text-open': state.isOpen,
              'item-text-close': !state.isOpen,
            })}
          />
        </ListItem>
      </List>
      <ToastContainer />
    </Drawer>
  )
}
