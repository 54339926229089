import React, { ReactElement, useState, Fragment, useEffect } from 'react'
import { CustomLoading } from '../../components/elements/CustomLoading'
import history from '../../utils/history'
import { CssBaseline } from '@material-ui/core'
import { useStyles } from '../../components/resources/SideBar/styles'
import { SideBar } from '../../../app/components/resources/SideBar'
import clsx from 'clsx'
import { Routes } from '../../Routes'
import './index.scss'
import { getToken, getCurrentUser } from '../../utils/helper/common'

interface AppLayoutState {
  // currentUser: CurrentUserEntity
  isLoading: boolean
  updatedMyProfile: boolean
  updatedSettings: boolean
}

const initialAppLayoutState: AppLayoutState = {
  isLoading: true,
  updatedMyProfile: false,
  updatedSettings: false,
}

const endpointLanding = '/landing' as string

export const AppLayout: React.FC = (): ReactElement => {
  const classes = useStyles()
  const [state, setState] = useState(initialAppLayoutState)

  useEffect(() => {
    if (!getToken() && !getCurrentUser()) {
      return history.push('/login')
    }
    const currentUser = JSON.parse((getCurrentUser() as string))
    if (currentUser.email !== process.env.REACT_APP_ACCOUNT_ADMIN) {
      return history.push('/login')
    }
    handleChangeState('isLoading', false)
  }, [])

  const showSideBar = (): boolean => {
    const { pathname } = history.location
    if (pathname === endpointLanding) return false
    return true
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: AppLayoutState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const renderMain = (): ReactElement => {
    if (state.isLoading) {
      return <CustomLoading className="app-layout-loading" />
    } else {
      return (
        <div className="app-layout">
          <CssBaseline />
          {showSideBar() && <SideBar />}
          <main
            className={clsx({
              [classes.content]: showSideBar(),
              [classes.setting]: !showSideBar(),
            })}
          >
            <Routes />
          </main>
        </div>
      )
    }
  }

  return (
    <Fragment>
      {renderMain()}
    </Fragment>
  )
}
