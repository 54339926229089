import IconEvent from '../../assets/images/IconEvent.svg'
import IconEventActive from '../../assets/images/IconEventActive.svg'
import IconMe from '../../assets/images/IconMe.svg'
import IconMeActive from '../../assets/images/IconMeActive.svg'
import IconSetting from '../../assets/images/IconSetting.svg'
import IconSettingActive from '../../assets/images/IconSettingActive.svg'
import IconTopicManagement from '../../assets/images/IconTopicManagement.svg'
import IconTopicManagementActive from '../../assets/images/IconTopicManagementActive.svg'
import IconUser from '../../assets/images/IconUser.svg'
import IconUserActive from '../../assets/images/IconUserActive.svg'
import IconUserManagement from '../../assets/images/IconUserManagement.svg'
import IconUserManagementActive from '../../assets/images/IconUserManagementActive.svg'
import IconSearch from '../../assets/images/IconSearch.svg'
import IconChevronDown from '../../assets/images/IconChevronDown.svg'
import IconPlus from '../../assets/images/IconPlus.svg'
import IconArrowDropDown from '../../assets/images/IconArrowDropDown.svg'
import IconPencil from '../../assets/images/IconPencil.svg'
import IconWoman from '../../assets/images/IconWoman.svg'
import IconClose from '../../assets/images/IconClose.svg'
import IconReject from '../../assets/images/IconReject.svg'
import IconError from '../../assets/images/IconError.svg'
import IconArrowLeft from '../../assets/images/IconArrowLeft.svg'
import IconTwoPeople from '../../assets/images/IconTwoPeople.svg'
import IconPlusActive from '../../assets/images/IconPlusActive.svg'
import IconTemplates from '../../assets/images/IconTemplates.svg'
import IconTemplatesActive from '../../assets/images/IconTemplatesActive.svg'
import IconNurse from '../../assets/images/IconNurse.svg'
import PetTraitIcon from '../../assets/images/PetTraitIcon.svg'
import IconAngleDown from '../../assets/images/IconAngleDown.svg'
import IconSearchLarge from '../../assets/images/IconSearchLarge.svg'
import IconUserCircle from '../../assets/images/IconUserCircle.svg'
import IconShoppingCart from '../../assets/images/IconShoppingCart.svg'
import IconChooseCustume from '../../assets/images/IconChooseCustume.svg'
import IconGreen from '../../assets/images/IconGreen.svg'
import IconYellow from '../../assets/images/IconYellow.svg'
import AppIcon from '../../assets/images/AppIcon.svg'
import Rectangle from '../../assets/images/Rectangle.svg'
import ThemeScreen from '../../assets/images/ThemeScreen.svg'
import StartScreen from '../../assets/images/StartScreen.svg'
import GoldenStar from '../../assets/images/GoldenStar.svg'
import BallWithDots from '../../assets/images/BallWithDots.svg'
import CreamBall from '../../assets/images/BallCream.svg'
import YellowBall from '../../assets/images/BallYellow.svg'
import PinkBall from '../../assets/images/PinkBall.svg'
import BlueBall from '../../assets/images/BlueBall.svg'
import LightPinkBall from '../../assets/images/LightPinkBall.svg'
import Squares from '../../assets/images/Squares.svg'
import Slide1 from '../../assets/images/Slide1.svg'
import Slide2 from '../../assets/images/Slide2.svg'
import Slide3 from '../../assets/images/Slide3.svg'
import EndAdorment from '../../assets/images/EndAdorment.svg'
import Notification from '../../assets/images/Notification.svg'
import NotificationActive from '../../assets/images/NotificationActive.svg'
import Logout from '../../assets/images/Logout.svg'
import Path from '../../assets/images/Path.svg'
import PathModal from '../../assets/images/PathModal.svg'
import LeftArrow from '../../assets/images/LeftArrow.svg'
import ThreeDots from '../../assets/images/ThreeDots.svg'
import LogoRoche from '../../assets/images/LogoRoche.svg'
import LogoMe from '../../assets/images/LogoMe.svg'
import Finance from '../../assets/images/Finance.svg'
import FisnishRegistration from '../../assets/images/FisnishRegistration.svg'
import HeartBeat from '../../assets/images/HeartBeat.svg'
import Back from '../../assets/images/Back.svg'
import Notice from '../../assets/images/Notice.svg'
import Camera from '../../assets/images/Camera.svg'
import ChatGroup from '../../assets/images/ChatGroup.svg'
import Plus from '../../assets/images/Plus.svg'
import PlaceholderImage from '../../assets/images/PlaceholderImage.svg'
import ThreeDotsActive from '../../assets/images/ThreeDotsActive.svg'
import AddConversation from '../../assets/images/AddConversation.svg'

export const Images = {
  IconMe,
  IconMeActive,
  IconEvent,
  IconEventActive,
  IconUser,
  IconUserActive,
  IconSetting,
  IconSettingActive,
  IconUserManagement,
  IconUserManagementActive,
  IconTopicManagement,
  IconTopicManagementActive,
  IconSearch,
  IconChevronDown,
  IconPlus,
  IconPencil,
  IconWoman,
  IconClose,
  IconArrowDropDown,
  IconReject,
  IconError,
  IconArrowLeft,
  IconTwoPeople,
  IconPlusActive,
  IconNurse,
  IconTemplates,
  IconTemplatesActive,
  IconAngleDown,
  IconChooseCustume,
  IconGreen,
  IconYellow,
  PetTraitIcon,
  IconSearchLarge,
  IconUserCircle,
  IconShoppingCart,
  Rectangle,
  ThemeScreen,
  StartScreen,
  GoldenStar,
  BallWithDots,
  CreamBall,
  YellowBall,
  PinkBall,
  LightPinkBall,
  BlueBall,
  Squares,
  AppIcon,
  Slide1,
  Slide2,
  Slide3,
  EndAdorment,
  Notification,
  NotificationActive,
  PathModal,
  Logout,
  Path,
  LeftArrow,
  ThreeDots,
  LogoRoche,
  LogoMe,
  Finance,
  FisnishRegistration,
  HeartBeat,
  Back,
  Notice,
  Camera,
  ChatGroup,
  Plus,
  PlaceholderImage,
  ThreeDotsActive,
  AddConversation,
}
