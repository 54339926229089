import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { SideBarItem } from '../../../interfaces/common/menu'

interface GroupMenuProps {
  menus: SideBarItem[]
  handleChangeItemActive: (value: string) => void
  isOpen: boolean
  itemActive: string
}

export const GroupMenu: React.FC<GroupMenuProps> = (props: GroupMenuProps): ReactElement => {
  const { menus, handleChangeItemActive, isOpen, itemActive } = props

  return (
    <>
      {menus.map((menu: SideBarItem, index: number) => {
        return (
          <NavLink key={index} exact={true} to={menu.path} onClick={() => handleChangeItemActive(menu.activePath)}>
            <ListItem button className="item">
              {menu.icon && (
                <ListItemIcon className={clsx('item-icon', { 'item-icon-close': !isOpen })}>
                  <div className="contain-img">
                    <img
                      src={itemActive === menu.activePath ? menu?.icon?.active : menu?.icon?.inActive}
                      alt="Icon Menu"
                      className="img"
                    />
                  </div>
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <p
                    className={clsx('fontRoboto', {
                      'item-active': itemActive === menu.activePath,
                    })}
                  >
                    {menu.name}
                  </p>
                }
                className={clsx('item-text', {
                  'item-text-open': isOpen,
                  'item-text-close': !isOpen,
                })}
              />
            </ListItem>
          </NavLink>
        )
      })}
    </>
  )
}
