// @ts-nocheck
import React, { Fragment, ReactElement, useState, useEffect, ChangeEvent } from 'react'
import { Grid, Button, Dialog, AppBar, Tabs, Tab } from '@material-ui/core'
import { CustomTextField } from '../../elements/CustomTextField'
import { CustomMUITextField } from '../../elements/CustomMUI/CustomMUITextField'
import { CustomLoading } from '../../elements/CustomLoading'
import { OptionSelect } from '../../../interfaces/common/menu'
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { uploadImage } from '../../../services/rest/template.services'
import 'antd/dist/antd.css';
import './index.scss'
import { toast, ToastContainer } from 'react-toastify'
import { Template } from '../../../interfaces/template'
import { v4 as uuid } from 'uuid';
import { TabPanel, a11yProps } from '../../elements/TablePanel'
import { FakeReviews } from './FakeReviews'
import { SearchableSelect } from '../../elements/SearchableSelect'

interface UpdateTemplateModalProps {
  handleCloseModal: () => void
  isOpen: boolean
  isLoading: boolean
  listOptionsCategories: OptionSelect[]
  isCreated: boolean
  templateDetail: Template
  handleSave: (data) => void
}

const landscapeOptions = [
  {
    value: true,
    content: 'True'
  },
  {
    value: false,
    content: 'False'
  },
]

export const UpdateTemplateModal: React.FC<UpdateTemplateModalProps> = (props: UpdateTemplateModalProps): ReactElement => {
  const { isOpen, isLoading, handleCloseModal, listOptionsCategories, isCreated, handleSave, templateDetail } = props

  const [state, setState] = useState({
    loading: false,
    optionCategories: [] as OptionSelect[],
    isOpenPreview: false,
    previewImage: '',
    urlOrtherImage: ''
  })
  const [templateData, setTemplateData] = useState({
    title: '',
    coins: 0,
    faces: 0,
    categories: [],
    description: '',
    priority: 0,
    fakeNumberOfLove: 0,
    isLandscape: false,
  })
  const [fileListOriginal, setFileListOriginal] = useState([])
  const [fileListProducts, setFileListProducts] = useState([])
  const [fileListOrthers, setFileListOrthers] = useState([])
  const [value, setValue] = React.useState(0);

  const resetData = () => {
    setState({
      loading: false,
      optionCategories: [] as OptionSelect[],
      isOpenPreview: false,
      previewImage: '',
      urlOrtherImage: ''
    })
    setTemplateData({
      title: '',
      coins: 0,
      faces: 0,
      description: '',
      categories: [],
      priority: 0,
      isLandscape: false,
    })
    setFileListOriginal([])
    setFileListProducts([])
    setFileListOrthers([])
  }

  useEffect(() => {
    if (!isCreated && Object.keys(templateDetail).length > 0) {
      const listCategoriesIds = templateDetail.categories.map(item => { return item.id })
      const optionCategories = listOptionsCategories.filter(item => {
        return listCategoriesIds.includes(item.value)
      })
      if (!!templateDetail.originalImage) {
        const fileListProducts = {
          uid: uuid(),
          url: templateDetail.originalImage
        }
        setFileListOriginal([fileListProducts])
      }

      if (!!templateDetail.custumeImage) {
        const fileListProducts = {
          uid: uuid(),
          url: templateDetail.custumeImage
        }
        setFileListProducts([fileListProducts])
      }
      if (templateDetail.mockupImages) {
        const fileListOrthers = templateDetail.mockupImages.filter(item => !!item).map((item, index) => {
          return {
            uid: index,
            url: item
          }
        })
        setFileListOrthers(fileListOrthers)
      }

      setTemplateData({
        title: templateDetail.title,
        coins: templateDetail.coins || 0,
        faces: templateDetail.faces || 0,
        description: templateDetail.description,
        categories: listCategoriesIds,
        fakeNumberOfLove: templateDetail.fakeNumberOfLove,
        priority: templateDetail.priority,
        isLandscape: (templateDetail.isLandscape == undefined || templateDetail.isLandscape == null) ? false : templateDetail.isLandscape,
      })
      handleChangeState('optionCategories', optionCategories)
    } else {
      resetData()
    }
  }, [isCreated, templateDetail])

  const handleChangeListProducts = (file) => {
    setFileListProducts(file.fileList)
  };

  const handleChangeListOrthers = (file) => {
    setFileListOrthers(file.fileList)
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handlePreview = async file => {
    setState(prevData => ({
      ...prevData,
      isOpenPreview: true,
      previewImage: file.url
    }))
  };

  const callbackChangeOriginalImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setFileListOriginal([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const callbackChangeProductsImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setFileListProducts([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }
  const callbackChangeOrthersImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      const listImage = [...fileListOrthers]
      listImage.push({
        uid: uuid(), url
      })
      setFileListOrthers(listImage)
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }


  const handleChangeTemplateData = (key: string, value: string): void => {
    setTemplateData(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const handleChangeCategories = (listOptionsSelected: OptionSelect[]): void => {
    const categories: string[] = [] as string[]
    listOptionsSelected.forEach((item: OptionSelect) => categories.push(item.value as string))
    handleChangeTemplateData('categories', categories)
    handleChangeState('optionCategories', listOptionsSelected)
  }

  const handleSaveModal = (): void => {
    let data = {
      ...templateData,
      originalImage: fileListOriginal[0]?.url || '',
      custumeImage: fileListProducts[0]?.url || '',
      mockupImages: fileListOrthers.map(item => {
        if (item === null) return ''
        return item.url
      })
    }
    if (!isCreated) {
      data = {
        ...templateDetail,
        ...data,
      }
    }
    handleSave(data)
    resetData()
    handleCloseModal()
  }

  const sumbitOrtherImage = (e) => {
    if (e.key === 'Enter') {
      const listImage = [...fileListOrthers]
      listImage.push({
        uid: uuid(),
        url: state.urlOrtherImage
      })
      setFileListOrthers(listImage)
      handleChangeState('urlOrtherImage', '')
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderContent = (): ReactElement => {
    return (
      <div className='header-tabs'>
        <AppBar position="static" className='appBar'>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className='tabs'>
            <Tab label="Template" {...a11yProps(0)} />
            <Tab label="Reviews" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={0} direction="column">
            <Grid item lg={12} className="header mb-70">
              <h4 className="title" style={{ textAlign: 'center' }}>Template</h4>
            </Grid>
            <div className="content">
              <Grid container className="row" spacing={4}>
                <Grid item lg={4} className="row">
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <h4 className="title mb-10">Template information</h4>
                    <CustomTextField
                      label="Title"
                      value={templateData.title}
                      onChanged={handleChangeTemplateData}
                      fieldName="title"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Description"
                      value={templateData.description}
                      onChanged={handleChangeTemplateData}
                      fieldName="description"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Faces"
                      value={templateData.faces}
                      onChanged={handleChangeTemplateData}
                      fieldName="faces"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Coins"
                      value={templateData.coins}
                      onChanged={handleChangeTemplateData}
                      fieldName="coins"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Priority"
                      value={templateData.priority}
                      onChanged={handleChangeTemplateData}
                      fieldName="priority"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={6} xs={12}>
                    <CustomTextField
                      label="Fake number of love"
                      value={templateData.fakeNumberOfLove}
                      onChanged={handleChangeTemplateData}
                      fieldName="fakeNumberOfLove"
                      className="text-field mb-50 "
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={6}
                    xs={12}
                  >
                    <Autocomplete
                      id="focusmeEntityName"
                      className="text-field"
                      multiple
                      options={listOptionsCategories}
                      filterOptions={(options: OptionSelect[]) => options}
                      getOptionLabel={(option: OptionSelect) => option.content || ''}
                      value={state.optionCategories}
                      onChange={(event: ChangeEvent<Record<string, unknown>>, newValue) => handleChangeCategories(newValue)}
                      renderOption={(option: OptionSelect) => <Fragment>{option.content}</Fragment>}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <CustomMUITextField
                          {...params}
                          label="Tags"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item lg={8} className="row">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h4 className="Original image">Original image</h4>
                    <Upload
                      key="original"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileListOriginal}
                      beforeUpload={(data) => {
                        handleChangeState('loading', true)
                        uploadImage(data, callbackChangeOriginalImage)
                      }}
                      onPreview={handlePreview}
                      onChange={(file) => setFileListOriginal(file.fileList)}
                    >
                      {uploadButton}
                    </Upload>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h4 className="Original image">Custume image</h4>
                    <Upload
                      key="productions"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileListProducts}
                      beforeUpload={(data) => {
                        handleChangeState('loading', true)
                        uploadImage(data, callbackChangeProductsImage)
                      }}
                      onPreview={handlePreview}
                      onChange={handleChangeListProducts}
                    >
                      {uploadButton}
                    </Upload>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h4 className="Original image">Productions</h4>
                    <Upload
                      key="orthers"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileListOrthers}
                      beforeUpload={(data) => {
                        handleChangeState('loading', true)
                        uploadImage(data, callbackChangeOrthersImage)
                      }}
                      onPreview={handlePreview}
                      onChange={handleChangeListOrthers}
                    >
                      {uploadButton}
                    </Upload>
                    <CustomTextField
                      label="Productions"
                      value={state.urlOrtherImage}
                      onChanged={handleChangeState}
                      onKeyDown={(e) => sumbitOrtherImage(e)}
                      fieldName="urlOrtherImage"
                      className="text-field mt-30 "
                    />
                    <SearchableSelect
                      key="isLandscape"
                      id="filter-isLandscape"
                      className="text-field mt-30 filter-select"
                      disableClearable={!value}
                      label="Is landscape"
                      fieldName="isLandscape"
                      value={templateData.isLandscape}
                      options={landscapeOptions}
                      onChange={handleChangeTemplateData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2} className="footer">
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Button variant="outlined" className="button" color="secondary" onClick={() => {
                  resetData()
                  handleCloseModal()
                }}>
                  <p className="text-pink text-transform-normal">
                    Back
              </p>
                </Button>
              </Grid>
              <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className="button"
                    color="primary"
                    onClick={() => handleSaveModal()}
                  >
                    <p className="text-white text-transform-normal">
                      Save
                </p>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FakeReviews templateId={templateDetail.id} />
        </TabPanel>
      </div>
    )
  }

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleCloseModal}
      className="update-template-modal"
    >
      {state.loading && <CustomLoading className="loading-modal" />}
      {renderContent()}
      <Modal
        visible={state.isOpenPreview}
        footer={null}
        onCancel={() => handleChangeState('isOpenPreview', false)}
      >
        <img alt="example" style={{ width: '100%' }} src={state.previewImage} />
      </Modal>
      <ToastContainer />
    </Dialog>
  )
}
