// @ts-nocheck
import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react'
import { Grid, Button, Dialog } from '@material-ui/core'
import { CustomTextField } from '../../elements/CustomTextField'
import { CustomLoading } from '../../elements/CustomLoading'
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { uploadImage } from '../../../services/rest/template.services'
import 'antd/dist/antd.css';
import '../UpdateTemplateModal/index.scss'
import { toast, ToastContainer } from 'react-toastify'
import { Product } from '../../../interfaces/product'
import { v4 as uuid } from 'uuid';
import '../UpdateReviewModal/index.scss'

interface UpdateProductModalProps {
  handleCloseModal: () => void
  isOpen: boolean
  isLoading: boolean
  isCreated: boolean
  productDetail: Product
  handleSave: (data) => void
}

export const UpdateProductModal: React.FC<UpdateProductModalProps> = (props: UpdateProductModalProps): ReactElement => {
  const { isOpen, isLoading, handleCloseModal, isCreated, handleSave, productDetail } = props
  const [state, setState] = useState({
    loading: false,
    isOpenPproduct: false,
    productImage: '',
    urlOrtherImage: ''
  })
  const [productData, setProductData] = useState({
    title: '',
    link: '',
    price: '',
    type: '',
  })
  const [fileImage, setFileImage] = useState([])

  const resetData = () => {
    setState({
      loading: false,
      isOpenPproduct: false,
      productImage: '',
      urlOrtherImage: '',
    })
    setProductData({
      title: '',
      link: '',
      price: '',
      type: '',
    })
    setFileImage([])
  }

  useEffect(() => {
    if (!isCreated && Object.keys(productDetail).length > 0) {
      if (productDetail.image) {
        const fileImage = {
          uid: uuid(),
          url: productDetail.image
        }
        setFileImage([fileImage])
      }

      setProductData({
        title: productDetail.title,
        link: productDetail.link,
        price: productDetail.price,
        type: productDetail.type,
      })
    } else {
      resetData()
    }
  }, [isCreated, productDetail])

  const handleChangeListProducts = (file) => {
    setFileImage(file.fileList)
  };

  const handlePproduct = async file => {
    setState(prevData => ({
      ...prevData,
      isOpenPproduct: true,
      productImage: file.url
    }))
  };

  const callbackChangeImage = (isSuccess: boolean, url?, message?: string) => {
    if (isSuccess && !!url) {
      setFileImage([
        {
          uid: uuid(),
          url
        }
      ])
    } else {
      toast.error(message)
    }
    handleChangeState('loading', false)
  }

  const handleChangeProductData = (key: string, value: string): void => {
    setProductData(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState(prevData => ({
      ...prevData,
      [key]: value
    }))
  }

  const handleSaveModal = (): void => {
    let data = {
      ...productData,
      image: fileImage[0]?.url || '',
    }
    if (!isCreated) {
      data = {
        ...productDetail,
        ...productData,
      }
    }
    handleSave(data)
    resetData()
    handleCloseModal()
  }

  const sumbitOrtherImage = (e) => {
    if (e.key === 'Enter') {
      const listImage = [{
        uid: uuid(),
        url: state.urlOrtherImage
      }]
      setFileImage(listImage)
      handleChangeState('urlOrtherImage', '')
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderContent = (): ReactElement => {
    return (
      <div className='header-tabs' style={{ padding: 30 }}>
        <Grid container item lg={12} spacing={0} direction="column">
          <Grid item lg={12} className="header mb-70">
            <h4 className="title" style={{ textAlign: 'center' }}>Product</h4>
          </Grid>
          <div className="content">
            <Grid container className="row" spacing={4}>
              <Grid item lg={6} md={6} sm={6} xs={6} className="row">
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  <CustomTextField
                    label="Title"
                    value={productData.title}
                    onChanged={handleChangeProductData}
                    fieldName="title"
                    className="text-field mb-50 "
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  <CustomTextField
                    label="Link"
                    value={productData.link}
                    onChanged={handleChangeProductData}
                    fieldName="link"
                    className="text-field mb-50 "
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  <CustomTextField
                    label="Price"
                    value={productData.price}
                    onChanged={handleChangeProductData}
                    fieldName="price"
                    className="text-field mb-50 "
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  <CustomTextField
                    label="Type"
                    value={productData.type}
                    onChanged={handleChangeProductData}
                    fieldName="type"
                    className="text-field mb-50 "
                  />
                </Grid>
              </Grid>
              <Grid container item lg={6} md={6} sm={6} xs={6} className="row">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <h4 className="Original image">Image</h4>
                  <Upload
                    key="productions"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileImage}
                    beforeUpload={(data) => {
                      handleChangeState('loading', true)
                      uploadImage(data, callbackChangeImage)
                    }}
                    onPproduct={handlePproduct}
                    onChange={handleChangeListProducts}
                  >
                    {uploadButton}
                  </Upload>
                  <CustomTextField
                    label="Image"
                    value={state.urlOrtherImage}
                    onChanged={handleChangeState}
                    onKeyDown={(e) => sumbitOrtherImage(e)}
                    fieldName="urlOrtherImage"
                    className="text-field mt-30 "
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2} className="footer">
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Button variant="outlined" className="button" color="secondary" onClick={() => {
                resetData()
                handleCloseModal()
              }}>
                <p className="text-pink text-transform-normal">
                  Back
              </p>
              </Button>
            </Grid>
            <Grid container item spacing={4} lg={6} md={6} sm={8} xs={9} justify='flex-end'>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  className="button"
                  color="primary"
                  onClick={() => handleSaveModal()}
                >
                  <p className="text-white text-transform-normal">
                    Save
                </p>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={isOpen}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleCloseModal}
      className="update-product-modal"
    >
      {state.loading && <CustomLoading className="loading-modal" />}
      {renderContent()}
      <Modal
        visible={state.isOpenPproduct}
        footer={null}
        onCancel={() => handleChangeState('isOpenPproduct', false)}
      >
        <img alt="example" style={{ width: '100%' }} src={state.productImage} />
      </Modal>
      <ToastContainer />
    </Dialog>
  )
}
