import React, { ReactElement } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { ColumnItem } from '../../../../interfaces/common/table'
import { TableRow, TableCell } from '@material-ui/core'
import './index.scss'

interface SkeletonTableRowProps {
  columns: ColumnItem[]
  isHaveAvatar?: boolean
  isShowMoreActions: boolean
}

export const SkeletonTableRow: React.FC<SkeletonTableRowProps> = (props: SkeletonTableRowProps): ReactElement => {
  const { isHaveAvatar, columns, isShowMoreActions } = props

  return (
    <TableRow className="skeleton-table-row">
      {isHaveAvatar && (
        <TableCell className="skeleton-avatar" width="50">
          <Skeleton variant="circle" className="circle" />
        </TableCell>
      )}
      {columns.map((item: ColumnItem, index: number) => {
        return (
          <TableCell key={`${item.id}-${index}`}>
            <Skeleton animation="wave" />
          </TableCell>
        )
      })}
      {isShowMoreActions && (
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
      )}
    </TableRow>
  )
}
