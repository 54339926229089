import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { CustomTableContainer } from '../../CustomTableContainer';
import { CustomMenu } from '../../CustomMenu';
import { ConfirmFormModal } from '../../ConfirmFormModal';
import { toast, ToastContainer } from 'react-toastify';
import '../../../../containers/Users/index.scss';
import { Review, ReviewRow } from '../../../../interfaces/review';
import { ColumnItem } from '../../../../interfaces/common/table';
import { ReviewInitialState, clearErrorReview } from './slice';
import {
  getReviews,
  createReview,
  deleteReview,
  updateReview,
} from '../../../../services/rest/review.services';
import { getListUsers } from '../../../../services/rest/user.services';
import { UserInitialState } from '../../../../containers/Users/slice';
import {
  removeCurrentUser,
  removeToken,
} from '../../../../utils/helper/common';
import { UpdateReviewModal } from '../../UpdateReviewModal';
import history from '../../../../utils/history';

const columns: ColumnItem[] = [
  { id: 'id', align: 'left', isBlack: true, label: 'Id' },
  { id: 'username', align: 'left', isBlack: true, label: 'User name' },
  { id: 'review', align: 'left', isBlack: true, label: 'Review' },
  { id: 'image', align: 'left', isBlack: true, label: 'Image' },
];

interface FakeReviewsProps {
  templateId: string;
}

interface FakeReviewsState {
  isOpenedUpdateReviewModal: boolean;
  listReviewsTable: ReviewRow[];
  isCreated: boolean;
  orderBy: string;
  typeOrder: string;
  loading: boolean;
  searchKeyword: string;
  reviewDetail: Review;
  isOpenFormConfirm: boolean;
  id: string;
}

export const FakeReviews = (props: FakeReviewsProps) => {
  const dispatch = useDispatch();
  const reviewManagement: ReviewInitialState = useSelector(
    (state: any) => state.reviewManagement
  );
  const userManagement: UserInitialState = useSelector(
    (state: any) => state.userManagement
  );
  const { listUsers } = userManagement;
  const { reviews } = reviewManagement;
  const { templateId } = props;
  const [state, setState] = useState({
    isOpenedUpdateReviewModal: false,
    listReviewsTable: [] as ReviewRow[],
    isCreated: false,
    orderBy: 'id',
    typeOrder: 'ASC',
    loading: true,
    searchKeyword: '',
    reviewDetail: {} as Review,
    isOpenFormConfirm: false,
    id: '',
  });

  const [anchorElementCreateUser, setAnchorElementCreateUser] =
    useState<null | HTMLElement>(null);

  useEffect(() => {
    dispatch(getListUsers());
    dispatch(getReviews(templateId));
  }, []);

  useEffect(() => {
    if (reviews) {
      const listReviewsTable = reviews.map((review) => {
        const user = listUsers.filter(
          (user) => user.id === (review.userId || '')
        )[0];
        return {
          id: review?.id,
          avatar:
            (review as unknown as Record<string, string>)?.avatar ||
            user?.avatar,
          username:
            (review as unknown as Record<string, string>)?.username ||
            user?.username,
          review: review?.note,
          image: review?.image,
        };
      });
      handleChangeState('listReviewsTable', listReviewsTable);
    }
  }, [reviews]);

  useEffect(() => {
    if (reviewManagement.error) {
      handleCheckErrorMessage(reviewManagement.error?.message);
    }
  }, [reviewManagement.error]);

  const handleCheckErrorMessage = (message: string): void => {
    if (message?.includes('401')) {
      removeCurrentUser();
      removeToken();
      dispatch(clearErrorReview());
      toast.error('Expried token!');
      history.push('/login');
    }
  };

  const findLastElementOfString = (value: string, typeSplit = '.'): string => {
    const arrElements: string[] = value.split(typeSplit);
    const index: number = arrElements.length - 1;
    return arrElements[index];
  };

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: FakeReviewsState) => ({
      ...prevState,
      [key]: value as Pick<FakeReviewsState, keyof FakeReviewsState>,
    }));
  };

  const handleOpenModalFromUserRow = (id: string) => {
    const reviewDetail = reviews.filter((item) => item.id === id)[0];
    setState((prevState: FakeReviewsState) => ({
      ...prevState,
      isCreated: false,
      isOpenedUpdateReviewModal: true,
      loading: true,
      reviewDetail,
    }));
  };

  const handleOrderListUsers = (orderBy: string, typeOrder: string) => {
    setState((prevState: FakeReviewsState) => ({
      ...prevState,
      orderBy,
      typeOrder,
    }));
  };

  const handleCheckColorText = (value: string): boolean => {
    if (
      ['false'].includes(findLastElementOfString(value ? value.toString() : ''))
    )
      return true;
    return false;
  };

  const handleSearch = (value: string) => {
    // if (value.length > 2 || validateNumber.test(value)) {
    //   handleChangeState('searchKeyword', value)
    // }
    // if (value.length === 0 && !!state.searchKeyword) {
    //   handleChangeState('searchKeyword', value)
    // }
  };

  const callbackDeleteReview = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      toast.success('Success');
      dispatch(getReviews(templateId));
    } else {
      handleCheckErrorMessage(message as string);
      toast.error('Have an issue when delete review. Try again!');
    }
  };

  const handleDelete = (): void => {
    handleChangeState('isOpenFormConfirm', false);
    deleteReview(state.id, callbackDeleteReview);
  };

  const callback = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      dispatch(getReviews(templateId));
      toast.success('Success!');
    } else {
      handleCheckErrorMessage(message as string);
      toast.error(message);
    }
  };

  const handleUpdateReview = (data: unknown) => {
    handleChangeState('isOpenedUpdateReviewModal', false);
    if (state.isCreated) {
      createReview(
        { ...(data as unknown as Record<string, unknown>), templateId },
        callback
      );
    } else {
      updateReview(data, callback);
    }
  };

  const showLoading: boolean =
    userManagement.loading || reviewManagement.loading;

  return (
    <div className="user-management">
      <div className="group-button">
        <Button
          variant="contained"
          className="button justify-content-space-around mr-10"
          color="primary"
          onClick={() => dispatch(getReviews(templateId))}
        >
          <p className="font-roboto text-white">Refresh data</p>
        </Button>
        <Button
          variant="contained"
          className="button justify-content-space-around"
          color="primary"
          onClick={() => {
            setState((prevState: FakeReviewsState) => ({
              ...prevState,
              isOpenedUpdateReviewModal: true,
              isCreated: true,
            }));
          }}
        >
          <p className="font-roboto text-white">Create review</p>
        </Button>
      </div>
      <CustomTableContainer
        showLoading={showLoading}
        onClickTableRow={handleOpenModalFromUserRow}
        columns={columns}
        dataTable={state.listReviewsTable}
        handleCheckColorText={handleCheckColorText}
        isHaveAvatar={true}
        onChangedFilter={handleChangeState}
        handleOrderTable={handleOrderListUsers}
        handleShowMoreActions={(anchorElement: HTMLElement, id: string) => {
          setAnchorElementCreateUser(anchorElement);
          handleChangeState('id', id);
        }}
        isShowMoreActions={true}
      />
      <UpdateReviewModal
        isOpen={state.isOpenedUpdateReviewModal}
        isCreated={state.isCreated}
        handleCloseModal={() => {
          handleChangeState('isOpenedUpdateReviewModal', false);
          handleChangeState('reviewDetail', {});
        }}
        isLoading={showLoading}
        handleSave={handleUpdateReview}
        reviewDetail={state.reviewDetail}
        listUsers={listUsers}
      />
      <CustomMenu
        anchorElement={anchorElementCreateUser}
        vertical={'center'}
        horizontal={'left'}
        listMenuItems={[
          {
            name: 'Delete',
          },
        ]}
        handleCloseMenu={() => setAnchorElementCreateUser(null)}
        handleSubmit={() => {
          handleChangeState('isOpenFormConfirm', true);
          setAnchorElementCreateUser(null);
        }}
      />
      <ConfirmFormModal
        onCancel={() => handleChangeState('isOpenFormConfirm', false)}
        onYes={handleDelete}
        name={
          listUsers.filter((item) => item.id === state.id)[0]?.username || ''
        }
        isOpen={state.isOpenFormConfirm}
      />
      <ToastContainer />
    </div>
  );
};
